import React from "react";
import { Layout } from "antd";
import Boxes from "../../components/reports/workcenterperformance.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Header from "../../components/header/generalheader.component";
const { Content } = Layout;
const MTUPage = () => (
  <Layout style={{ height: "100vh" }}>
    <Header />
    <Layout style={{ flex: "1" }}>
      <SideBar />
      <Layout className="site-layout" style={{ height: "100%" }}>
        <Content>
          <div className="site-layout-background">
            <Boxes />
          </div>
        </Content>
      </Layout>
    </Layout>
    {/* <FooterComponent /> */}
  </Layout>
);

export default MTUPage;
