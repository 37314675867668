import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { connect } from "react-redux";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class ShiftCreator extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      inputTimeout: 0,
      value: "",
      shifts: [],
      disabledSecond: true,
      disabledThird: true,
      disabledFourth: true,
      disabledFifth: true,
      users: [],
      halfOrFull: null,
      halfOrFullList: [{ name: "1st Half" }, { name: "2nd Half" }, { name: "Full" }],
      attendanceCodes: [],
      isFirstShift: false,
      checked: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.setState({ shifts: this.props.shifts });
    this.callUsers();
    this.getAttendanceCodes();
    console.log(window.innerHeight, window.innerWidth);
  }

  callUsers() {
    axios.post(address + "/getUsers", { active: 1, department: this.props.department || "ALL", division: this.props.division, shift: this.props.shift === "ALL" ? null : this.props.shift, secureUserId: this.props.user.id }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ users: data });
    });
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }
  async createShift() {
    console.log(this.state.newAttendance, this.state.newDate, this.state.data);
    if (this.state.newAttendance === undefined || this.state.newDate === undefined || this.state.halfOrFull === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Must have all fields filled out", life: 3000 });
      return;
    }
    let onlyDate = this.state.newDate.map((date) => {
      return new Date(date).toISOString().slice(0, 10);
    });
    axios.post(address + "/createAdminShift", { date: JSON.stringify(onlyDate), attendance: this.state.newAttendance, userId: this.state.user.ID, division: this.props.user.division, halfOrFull: this.state.halfOrFull?.name }).then(async (res) => {
      this.props.createNewShift();
      this.setState({ newDate: [], disabledSecond: true, disabledThird: true, disabledFourth: true, disabledFifth: true });
      this.props.hideMe();
    });
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <ModalHeader title="Create Shift" />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%", height: "50vh", padding: "1px" }}>
          <div style={{ zoom: window.innerHeight / 754, alignContent: "center", border: "2pxs", backgroundColor: "black", display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto" }}>
            <Calendar style={{ float: "left" }} value={this.state.newDate} inline hourFormat="12" onChange={(e) => this.setState({ newDate: e.value, disabledSecond: false })} selectionMode="multiple" />
          </div>
          <div style={{ zoom: window.innerHeight / 754, display: "grid", rowGap: "2.5%", display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", gridTemplateRows: "8% 8% 8% 8.5% 8% 8.5% 8% 8.5% auto 10% " }}>
            <h3 style={{ width: "100%", textAlign: "center" }}>1. Select Dates</h3>
            <Calendar style={{ width: "100%", fontSize: "2em" }} value={this.state.newDate} hourFormat="12" onChange={(e) => this.setState({ newDate: e.value, disabledSecond: false })} selectionMode="multiple" />

            <h3 style={{ width: "100%", textAlign: "center" }}>2. Select Attendance Code</h3>
            <Dropdown disabled={this.state.disabledSecond} className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "1.5em" }} value={this.state.newAttendance} placeholder={"N/A"} options={this.state.attendanceCodes} onChange={(e) => this.setState({ newAttendance: e.value, disabledThird: false })} optionLabel="name" />
            <h3 style={{ width: "100%", textAlign: "center" }}>3. Half or Full day</h3>
            <Dropdown disabled={this.state.disabledThird} className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "1.5em" }} value={this.state.halfOrFull} placeholder={"N/A"} options={this.state.halfOrFullList} onChange={(e) => this.setState({ halfOrFull: e.value, disabledFourth: false })} optionLabel="name" />
            <h3 style={{ width: "100%", textAlign: "center" }}>3. Select User</h3>
            <Dropdown disabled={this.state.disabledFourth} filter className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "1.5em" }} value={this.state.user} placeholder={"N/A"} options={this.state.users} onChange={(e) => this.setState({ user: e.value, disabledFifth: false, isFirstShift: e.value.shift === 1, checked: false })} optionLabel="name" />

            <div></div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto", columnGap: "1vw", marginTop: "3vh" }}>
              <Button
                label="Back"
                className="adminPageButtons white"
                onClick={() => {
                  if (this.props.hideMe) {
                    this.props.hideMe();
                  } else {
                    console.log("missing hideMe prop");
                  }
                }}
              />
              <Button label="Create" disabled={this.state.disabledFifth} className="createShiftButton" onClick={() => this.createShift()} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps)(ShiftCreator);
