function getHighestAttendance(attendanceList, attendanceCodes) {
  if (attendanceList === null || attendanceList === undefined) {
    return null;
  }
  let attendanceArray = attendanceList.split(",");
  let attendance = attendanceArray[0];
  let highest = 0;
  for (let i = 0; i < attendanceArray.length; i++) {
    let currentAttendance = attendanceArray[i];
    let found = attendanceCodes.find((object) => object.name === currentAttendance);
    if (found !== undefined) {
      if (found.hierarchy > highest) {
        highest = found.hierarchy;
        attendance = found.name;
      }
    }
  }
  return attendance;
}

export default getHighestAttendance;
