import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import LoadingScreen from "../loading/loading.component.jsx";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      shifts: [],
      header: ["Setup", "Run Time", "Paused Time", "Total Time", "Item No.", "Order No.", "Operation No.", "Work Center", "NAV Operation No."],
    };
    this.renderHeader = (
      <ColumnGroup>
        <Row>
          <Column alignHeader="center" header="Setup" rowSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: "2em" }} />
          <Column alignHeader="center" header="Run Time" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Paused" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Total Hours" rowSpan={2} headerStyle={{ textAlign: "center", width: "6vw", padding: 0 }} />
          <Column alignHeader="center" header="Attend Code" rowSpan={2} headerStyle={{ textAlign: "center", width: "5w", padding: 0 }} />
          <Column alignHeader="center" header="Shift" rowSpan={2} headerStyle={{ textAlign: "center", width: "3vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp #" rowSpan={2} headerStyle={{ textAlign: "center", width: "4vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp Name" rowSpan={2} headerStyle={{ textAlign: "center", width: "14vw", padding: 0 }} />
          <Column alignHeader="center" header="Department" rowSpan={2} colSpan={1} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
        </Row>
        <Row>
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
        </Row>
      </ColumnGroup>
    );
  }

  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let headers = this.state.header;
    let data = this.state.shifts;
    for (let i = 0; i < headers.length; i++) {
      csv += headers[i] + ",";
    }
    csv += "\n";
    for (let i = 0; i < data.length; i++) {
      let s = data[i];
      csv += s.setup + ",";
      csv += s.run + ",";
      csv += s.paused + ",";
      csv += s.total + ",";
      csv += s.item_no + ",";
      csv += s.order_no + ",";
      csv += s.op_no + ",";
      csv += s.workcenter + ",";
      csv += s.nav_op + ",";
      csv += "\n";
    }
    csv += "\n";
    this.download(csv, "routingDataExport");
  }

  convertTimeToHours(time) {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = "am";
    if (minutes < 10) {
      minutes = 0 + JSON.stringify(minutes);
    }
    if (hours > 12) {
      hours -= 12;
      ampm = "pm";
    }
    console.log(time, hours, minutes);
    return hours + ":" + minutes + " " + ampm;
  }

  setDate(data) {
    this.setState({ date: data }, () => {
      if (data[1] !== null) {
        console.log("called :)");
        this.getReport(this.state.date[0].toISOString(), new Date(new Date(this.state.date[1]).setDate(new Date(this.state.date[1]).getDate()+1)).toISOString());
      }
    });
  }

  getReport(startDate, endDate) {
    this.setState({ loading: true });
    axios
      .post(address + "/routingAppDataExport", {
        start_time: startDate,
        end_time: endDate,
        division: this.props.user.division,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({ shifts: data }, () => this.setState({ visible: true, loading: false }));
        this.setState({ CreateShiftModal: false });
      });
  }

  render() {
    return (
      <>
        <LoadingScreen show={this.state.loading} />
        <div style={{ display: "grid", gridTemplateColumns: "20vw auto", marginLeft: "2.5vw", marginRight: "2.5vw", columnGap: "2.5vw" }}>
          <Calendar placeholder="Select Date Range" ref={(e) => (this.calendar = e)} value={this.state.date} onChange={(e) => this.setDate(e.value)} selectionMode="range" />
          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
        </div>
        <DataTable value={this.state.shifts}>
          <Column field="setup" header="Setup" />
          <Column field="run" header="Run Time" style={{ textAlign: "cente" }} />
          <Column field="paused" header="Paused Time" style={{ textAlign: "cnter" }} />
          <Column field="total" header="Total Time" style={{ textAlign: "ceter" }} />
          <Column field="item_no" header="Item #" style={{ textAlign: "cener" }} />
          <Column field="order_no" header="Order #" style={{ textAlign: "ceter" }} />
          <Column field="op_no" header="Operation #" style={{ textAlign: "ceter" }} />
          <Column field="workcenter" header="Workcenter" style={{ textAlign: "enter" }} />
          <Column field="nav_op" header="NAV Op #" style={{ textAlign: "centr" }} />
        </DataTable>
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
