import React from "react";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

class LoadingScreen extends React.Component {
  render() {
    return (
      <>
        <Dialog visible={this.props.visible || this.props.show} modal={true} showHeader={false} style={{ boxShadow: "none" }} contentStyle={{ width: "20vw", overflow: "hidden", padding: 0, height: "20vw", borderRadius: "100em", backgroundColor: "rgb(255,255,255,.4)" }}>
          <ProgressSpinner style={{ width: "100%", height: "100%" }} />
        </Dialog>
      </>
    );
  }
}

export default LoadingScreen;
