export const CONNECT_WITH_BAD_CREDENTIALS = "connect-with-bad-credentials";
export const CONNECT_WITH_SSO = "connect-with-sso";
export const DISCONNECT = "disconnect";
export const SHOW_SECRET = "show-secret";
export const TIMETRACK_LOG_IN = "timetrack-log-in";
export const TIMETRACK_LOG_OUT = "timetrack-log-out";
export const TIMETRACK_AUTH_LOGIN = "timetrack-auth-login";
export const UPDATE_VERSION = "update-version";
export const TIMETRACK_SEARCH_SETTING = "timetrack_search_setting";

export interface User {
  domain: string;
  name: string;
  displayName: string;
  groups: string[];
  sid: string;
  adUser?: any;
}

interface ConnectWithSSOAction {
  type: typeof CONNECT_WITH_SSO;
  user: User;
}
export interface TTUser {
  username: string;
  id: number;
  initials: string;
  department: string;
  division: string;
  shift: string;
  expiry: string;
  permissions: string;
  changeDivision: boolean;
  status: string;
  secureUserHierarchy: number;
}
export interface VersionControl {
  name: string;
}
export interface SearchSetting {
  department: string;
  shift: number;
  date: Array<Object>;
  user: string;
  approve: string;
  payType: string;
  active: string;
  workerType: string;
  attendanceCode: Array<string>;
}

export interface AuthForLogin {
  auth: number;
}

interface TTLOGIN {
  type: typeof TIMETRACK_LOG_IN;
  TTUser: TTUser;
}

interface TTLOGOUT {
  type: typeof TIMETRACK_LOG_OUT;
}
interface ConnectWithCredentialsAction {
  type: typeof CONNECT_WITH_BAD_CREDENTIALS;
}

interface ShowSecretAction {
  type: typeof SHOW_SECRET;
  secret: string;
}
interface UpdateVersionControl {
  type: typeof UPDATE_VERSION;
  version: VersionControl;
}
interface DisconnectAction {
  type: typeof DISCONNECT;
}
interface UpdateSearchSetting {
  type: typeof TIMETRACK_SEARCH_SETTING;
  SearchSetting: SearchSetting;
}
interface UpdateAuthForLogin {
  type: typeof TIMETRACK_AUTH_LOGIN;
  AuthForLogin: AuthForLogin;
}
export type AppAction = ConnectWithSSOAction | ConnectWithCredentialsAction | ShowSecretAction | DisconnectAction | TTLOGIN | TTLOGOUT | UpdateVersionControl | UpdateSearchSetting | UpdateAuthForLogin;

export interface UserState {
  user?: User;
}
export interface TTUserState {
  TTUser?: TTUser;
}
export interface VersionControlState {
  version?: VersionControl;
}
export interface SecretState {
  secret: string;
}

export interface ErrorState {
  error: boolean;
}

export interface SearchSettingState {
  SearchSetting?: SearchSetting;
}

export interface AuthLoginState {
  AuthForLogin?: AuthForLogin;
}

export interface AppState extends TTUserState, UserState, SecretState, ErrorState, VersionControlState, SearchSettingState, AuthLoginState {}

export interface ConnectDispatch {
  disconnect: () => void;
}
