import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { InputTextarea } from "primereact/inputtextarea";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import { Dialog } from "primereact/dialog";

class CommentSection extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      inputTimeout: 0,
      value: "",
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      console.log("updating");
      this.setState({ value: this.props.value || "" });
    }
  }

  updateComment(data) {
    console.log(this.props.commentId);
    if (this.props.commentId) {
      axios.post(address + "/assignShiftComment", { shift_id: this.props.commentId, comment: data });
    }
  }

  onCommentChangeButNotReally(data) {
    this.setState({ value: data });
    clearTimeout(this.state.inputTimeout);
    this.setState({
      inputTimeout: setTimeout(() => {
        if (!this.props.dontUpdateShift) {
          this.updateComment(data);
        }
        this.setState({ comment: data }, () => this.props.updateParent(data));
      }, 1000),
    });
    console.log(data);
  }

  render() {
    return (
      <>
        <Dialog resizable={false} draggable={false} dismissableMask visible={this.props.visible} modal={true} style={{ borderRadius: ".8em" }} onHide={this.props.onHide} contentStyle={{ width: "auto", height: "auto", padding: 0, overflow: "hidden", borderRadius: ".8em" }} showHeader={false}>
          <ModalHeader title="Comment" height="12vh" titleFontSize="6vh" />
          <InputTextarea disabled={this.props.disabled === true} resizable={false} style={{ width: "100%", height: "100%" }} rows={10} cols={60} value={this.state.value} onChange={(e) => this.onCommentChangeButNotReally(e.target.value)} />
        </Dialog>
      </>
    );
  }
}

export default CommentSection;
