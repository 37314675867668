import React from "react";

class Page extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return <>this isnt really used</>;
  }
}

export default Page;
