import React from "react";
import "./terminal.styles.css";
import axios from "axios";
import address from "../address.component";
import LoadingScreen from "../loading/loading.component.jsx";
import logo from "../../assets/icons/Haven_logo_Final_White-01.svg";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Keyboard from "./KeyboardInput.component.jsx";

import calcTotalHours from "../global_functions/calcTotalHours.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";
import processDataByUser from "../global_functions/processDataByUser.function";

class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      date: null,
      PTO: [],
      planned: 0,
      remaining: 0,
      halfOrFull: { name: "Full" },
      PTORequest: {},
      requestTypeList: [{ name: "1st Half" }, { name: "2nd Half" }, { name: "Full" }],
      currentPage: 0,
      shifts: [],
      breaks: [],
      newBreaks:[],
      commentOpen: false,
      currentRowIndex: -1,
      supervisorCommentOpen: false,
      supervisorComment: "",
    };
  }

  async componentDidMount() {
    await this.getAttendanceCodes();
    this.createTimeSheet();
  }

  async getHourReview(shifts) {
    await axios
      .post(address + "/getReviewHourRequest", {
        shifts,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        let shiftData = data.filter(element=>element.adj_end_time!==null)
        this.setState({ shifts: shiftData, loading: false });
      });
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  async createTimeSheet() {
    this.setState({ loading: true });
    let startDate = new Date(new Date().setDate(new Date(new Date().setHours(0,0,0)).getDate() - (new Date().getDay() - this.state.currentPage * 7))).toISOString();
    let endDate = new Date(new Date().setDate(new Date(new Date().setHours(0,0,0)).getDate() + ((this.state.currentPage + 1) * 7 - new Date().getDay()))).toISOString();
   
    this.setState({ startDate: startDate.slice(0, 10), endDate: new Date(new Date(endDate).setDate(new Date(endDate).getDate()-1)).toISOString().slice(0, 10) });
    await axios
      .post(address + "/createTimesheet", {
        start_time: startDate,
        end_time: endDate,
        department: null,
        shift: null,
        type: null,
        user_id: this.props.currentUser.pin || 0,
        division: this.props.currentUser.division,
      })
      .then(async (res) => {
        const data = res.data;
        console.log(data);

        let processedData = await processDataByUser(data.dataByUser, data.breaks, data.newBreakInfo, startDate, endDate, this.state.attendanceCodes);

        let dataWithinFrame = processedData.data.filter((element) => element.display !== false);
        console.log(dataWithinFrame);
        this.getHourReview(dataWithinFrame);
        this.setState({ breaks: data.breaks ,newBreaks:data.newBreakInfo});
      });
  }

  async changePage(nextPage) {
    //check it is the last week or next week
    let currentPage = this.state.currentPage;
    if (nextPage) {
      currentPage++;
    } else {
      currentPage--;
    }
    this.setState({ currentPage: currentPage }, () => this.createTimeSheet());
  }

  getShiftStatus(rowData, column) {
    if (rowData.employeeApprove === true) {
      return "Approved";
    } else {
      if (rowData.hoursRequestStatus === undefined || rowData.hoursRequestStatus === null) {
        return (
          <React.Fragment>
            <button
              onClick={() => {
                this.updateCheckbox(true, column.rowIndex, "employeeApprove");
              }}
              style={{ backgroundColor: "green", height: "60px", width: "60px", borderWidth: "0px", borderRadius: "8px" }}
            >
              <i className="pi pi-check" style={{ fontSize: "1.4vw", color: "white" }} />
            </button>
            <button onClick={() => this.setState({ commentOpen: true, currentRowIndex: column.rowIndex })} style={{ marginLeft: "2vw", backgroundColor: "red", height: "60px", width: "60px", borderWidth: "0px", fontSize: "30pt", lineHeight: "0px", borderRadius: "8px" }}>
              <i className="pi pi-times" style={{ fontSize: "1.4vw", color: "white" }} />{" "}
            </button>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {rowData.hoursRequestStatus === "Pending" ? "Pending...." : rowData.hoursRequestStatus}
            {(rowData.hoursRequestStatus !== "Pending" && rowData.supervisor_Comment!=="" && rowData.supervisor_Comment!==null && rowData.supervisor_Comment!==undefined )? <i className="pi pi-comment" style={{ fontSize: "1.4vw", display: "inline-block", marginLeft: "5%", marginTop: "5%" }} onClick={() => this.setState({ supervisorCommentOpen: true, supervisorComment: rowData.supervisor_Comment })}></i> : null}
          </React.Fragment>
        );
      }
    }
  }

  async updateCheckbox(value, rowIndex, field, comment) {
    console.log(value);
    console.log(rowIndex);
    console.log(field);
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
      //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
      return;
    }

    copy[rowIndex][field] = value;
    if (field === "employeeApprove") {
      axios
        .post(address + "/UpdateSQLTime", {
          field: field,
          rowData: copy[rowIndex],
        })
        .then((res) => {
          this.setState({ shifts: copy });
        });
    } else if (field === "hoursRequestStatus") {
      axios
        .post(address + "/createReviewHourRequest", {
          rowData: copy[rowIndex],
          comment: comment,
        })
        .then((res) => {
          if (copy[rowIndex].ID === undefined) {
            const newCopy = copy.map((data) => {
              if (data.ID === undefined && data.shift_id === copy[rowIndex].shift_id && data.id !== copy[rowIndex].id) {
                data[field] = value;
                return data;
              } else {
                return data;
              }
            });
            this.setState({ shifts: newCopy });
          } else {
            this.setState({ shifts: copy });
          }
        });
    }
  }

  closeAndSave = (input) => {
    this.setState({ commentOpen: false });
    this.updateCheckbox("Pending...", this.state.currentRowIndex, "hoursRequestStatus", input);
  };

  render() {
    return (
      <div style={{ height: "100%", overflow: "hidden", display: "grid", gridTemplateColumns: "0.2fr 1fr 0.2fr", textAlign: "center" }}>
        <div style={{ alignItems: "right", padding: "130% 50px 0px 0px" }}>
          <span onClick={() => this.changePage(false)} style={{ width: "10%", display: "inline-block", textAlign: "center" }}>
            <i class="fa fa-caret-left" style={{ fontSize: "20vw", color: this.props.div === "HAVEN" ? "gray" : "black" }}></i>
          </span>
        </div>
        <div style={{ backgroundColor: "#D1D1D1" }}>
          <LoadingScreen show={this.state.loading} />
          <Dialog visible={this.state.commentOpen} style={{ width: "80vw", height: "80vh", textAlign: "center" }} onHide={() => this.setState({ commentOpen: false })}>
            <Keyboard requestComment={this.state.requestComment} closeAndSave={this.closeAndSave} />
          </Dialog>
          <Dialog visible={this.state.supervisorCommentOpen} style={{ fontSize: "2em", whiteSpace: "pre-line", wordBreak: "break-all", wordWrap: "break-word", width: "80vw", height: "80vh", textAlign: "left" }} onHide={() => this.setState({ supervisorCommentOpen: false })}>
            <div style={{ textAlign: "center", fontSize: "2em" }}>{"Supervisor response \n"}</div>
            <div>{this.state.supervisorComment}</div>
          </Dialog>
          <div style={{ display: "inline" }}>
            <span style={{ fontSize: "2em" }}>
              {this.state.startDate} to {this.state.endDate}
            </span>
            <span style={{ width: "90%", display: "inline-block", borderStyle: "solid", borderWidth: "5px", borderColor: "red", borderRadius: "8px", boxShadow: "5px 15px 20px #B0B0B0" }}>
              <DataTable value={this.state.shifts} scrollable scrollHeight="70vh" responsiveLayout="scroll" style={{ width: "100%", fontSize: "22px" }}>
                <Column field="date" header="Date (Y/M/D)"
                body={(rowData)=>{
                 return new Date(new Date(new Date(rowData.adj_start_time).getTime() + (new Date(rowData.adj_end_time) - new Date(rowData.adj_start_time)) / 2).toLocaleString().split(",")[0]).toISOString().slice(0, 10)
                }}></Column>
                <Column field="attendance" header="Attendance Code"></Column>
                <Column
                  field="hours"
                  header="Hours"
                  body={(rowData) => {
                    return rowData.hours
                    // return calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.state.breaks, shift: this.props.currentUser.shift || 1, attendance: getHighestAttendance(rowData.attendance, this.state.attendanceCodes), approved: rowData.approved || false, shiftHour:rowData.hours, newBreaks:this.state.newBreaks,breakShift:rowData.break_shift });
                  }}
                ></Column>
                <Column
                  field="employeeApprove"
                  header="Approval Status"
                  style={{
                    textAlign: "center",
                  }}
                  body={(rowData, column) => this.getShiftStatus(rowData, column)}
                ></Column>
              </DataTable>
            </span>
          </div>
        </div>
        <div style={{ alignItems: "left", textAlign: "left", paddingTop: "130%" }}>
          <span onClick={() => this.changePage(true)} style={{ width: "10%", display: "inline-block", textAlign: "center" }}>
            <i class="fa fa-caret-right" style={{ fontSize: "20vw", color: this.props.div === "HAVEN" ? "gray" : "black" }}></i>
          </span>
        </div>
      </div>
    );
  }
}

export default Page;
