import Icon from '@ant-design/icons';

export const DoubleRightOutlined = props => <Icon component={DoubleRightSvg} {...props} />;

const DoubleRightSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 400 400" >
        <path d="M92.093 84.995 C 84.413 89.008,80.971 97.951,83.983 106.069 C 85.161 109.244,91.707 116.134,130.357 154.883 L 175.360 200.000 130.357
            245.117 C 81.092 294.508,82.186 293.206,82.980 301.466 C 84.111 313.235,97.474 320.693,107.813 315.324 C 111.383 313.470,213.470 211.383,215.324
            207.813 C 217.601 203.428,217.310 195.782,214.700 191.406 C 211.296 185.696,111.310 86.353,107.031 84.428 C 101.997 82.164,97.162 82.347,92.093
            84.995 M197.656 84.327 C 186.455 86.578,180.567 98.702,185.694 108.957 C 186.346 110.261,206.789 131.278,231.123 155.661 L 275.366 199.994 230.360
            245.114 C 181.092 294.508,182.186 293.206,182.980 301.466 C 184.110 313.234,197.468 320.690,207.813 315.327 C 209.316 314.547,233.987 290.477,262.636
            261.837 C 321.685 202.808,318.495 206.699,316.390 196.289 L 315.482 191.797 263.033 139.263 C 234.186 110.369,209.521 86.241,208.222 85.645 C 205.015
            84.173,200.948 83.666,197.656 84.327 " />
    </svg>
);