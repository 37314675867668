import React from "react";
import axios from "axios";
import address from "../address.component";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import { TIMETRACK_SEARCH_SETTING } from "../../redux2/types";
import { store } from "../../redux2/store";
import { connect } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DatePicker } from "antd";
import dayjs from "dayjs";

//Important! Have to have this or lock the dayjs version to 1.8.30 for bug
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(weekday);
dayjs.extend(localeData);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
//this is for store the report setting data into redux
function mapStateToProps(state) {
  return {
    searchSetting: state.SearchSetting,
    supervisor: state.TTUser,
  };
}

function updateSearchSetting(SearchSetting) {
  store.dispatch({
    SearchSetting,
    type: TIMETRACK_SEARCH_SETTING,
  });
}

class Page extends React.Component {
  constructor(props) {
    super();
    this.state = {
      departments: [
        { name: "IT", value: "IT" },
        { name: "OFFICE", value: "OFFICE" },
        { name: "PRODUCTION", value: "PRODUCTION" },
        { name: "TOOLING", value: "TOOLING" },
        { name: "SHIPPING", value: "SHIPPING" },
        { name: "MAINTENANCE", value: "MAINTENANCE" },
        { name: "ALL", value: "ALL" },
      ],
      shiftNumbers: [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "ALL", value: "ALL" },
      ],
      activeChoices: [
        { name: "Active", value: true },
        { name: "Inactive", value: false },
        { name: "All", value: "ALL" },
      ],
      approveTypes: [
        { name: "All", value: "both" },
        { name: "Approved", value: "approved" },
        { name: "Unapproved", value: "unapproved" },
      ],
      payTypes: [
        { name: "Salary", value: "Salary" },
        { name: "Hourly", value: "Hourly" },
        { name: "All", value: "both" },
      ],
      workerTypes: [
        { name: "Full Time", value: "FULL TIME" },
        { name: "Part Time", value: "PART TIME" },
        { name: "Intern", value: "INTERN" },
        { name: "All", value: "ALL" },
      ],
      attendanceCodes: [],
      shiftPermission: false,
      departmentPermission: false,
      active: "ALL",
      approve: "both",
      payType: "both",
      date: [],
      startDate: null,
      endDate: null,
      users: [],
      attendanceCode: [],
      user: null,
      shift: null,
      department: null,
      division: null,
      workerType: null,
    };
  }

  async componentDidMount() {
    console.log(this.props);
    let startDate = this.props.date ? dayjs(this.props.date[0].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
    let endDate = this.props.date ? dayjs(this.props.date[1].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
    console.log(startDate, endDate);
    let day = new Date(new Date(new Date().setDate(new Date().getDate())).setHours(0));
    let startDay = this.props.date?.[0] || day;
    if (this.props.searchSetting === undefined || this.props.searchSetting === null) {
      let startDate = this.props.date ? dayjs(this.props.date[0].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
      let endDate = this.props.date ? dayjs(this.props.date[1].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
      await this.setState({ shift: this.props.shift || "ALL", department: this.props.department || "ALL", date: [startDate, endDate], approve: this.props.approve || "both", payType: this.props.payType || "both", active: this.state.active, division: this.props.supervisor?.division, workerType: this.props.workerType || "ALL", attendanceCode: this.props.attendanceCode }, () => {});
    } else {
      let startDate = this.props.date ? dayjs(new Date(this.props.searchSetting?.date[0]).toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
      let endDate = this.props.date ? dayjs(new Date(this.props.searchSetting?.date[1]).toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
      await this.setState({ shift: this.props.searchSetting?.shift || "ALL", department: this.props.searchSetting?.department || "ALL", date: [startDate, endDate], approve: this.props.searchSetting?.approve || "both", payType: this.props.searchSetting?.payType || "both", active: this.props.searchSetting?.active, division: this.props.supervisor?.division, workerType: this.props.searchSetting?.workerType || "ALL", attendanceCode: this.props.searchSetting?.attendanceCode }, () => {});
    }

    this.getDepartments();
    this.getAttendanceCodes();
    await this.getAdminUserPermission();
    if (this.props.user !== undefined) {
      this.getUsers();
    }
  }

  resetDateToDefault() {
    console.log("hit");
    let startDate = this.props.defaultDate ? dayjs(this.props.defaultDate[0].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
    let endDate = this.props.defaultDate ? dayjs(this.props.defaultDate[1].toISOString().slice(0, 10), dateFormat) : dayjs(new Date().toISOString().slice(0, 10), dateFormat);
    this.setState({ date: [startDate, endDate] });
  }
  async getAdminUserPermission() {
    axios.post(address + "/getSecureUserInfo", { secureUser_id: this.props.supervisor?.id }).then((res) => {
      const data = res.data;
      let adminUser = data.length ? data[0] : {};
      console.log(adminUser);
      if (adminUser.permissions === "ALL" || adminUser.secureUserHierarchy >= 3) {
        this.setState({ shiftPermission: true, departmentPermission: true });
      } else if (adminUser.secureUserHierarchy === 2) {
        this.setState({ shiftPermission: true, department: this.props.department || "ALL" });
      } else {
        this.setState({ shift: this.props.shift || "ALL", department: this.props.department || "ALL" });
      }
    });
  }
  getUsers() {
    let getUsersData = {};
    getUsersData.shift = this.props.shift ? this.state.shift : "ALL";
    getUsersData.department = this.props.department ? this.state.department : "ALL";
    if (this.props.active) {
      getUsersData.active = this.state.active;
    }
    if (this.props.workerType) {
      getUsersData.workerType = this.state.workerType;
    }
    getUsersData.division = this.state.division;

    axios.post(address + "/getUsers", getUsersData).then((res) => {
      const data = res.data;
      this.setState({ users: data });
    });
    this.setState({ user: null });
  }
  getDepartments() {
    axios.get(address + "/getDepartments").then((res) => {
      let data = res.data;
      data.departments.push({ name: "ALL", value: "ALL" });
      this.setState({ departments: data.departments });
    });
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log(prevProps, prevState, this.props, this.state);
    if (this.props.user !== undefined) {
      if (prevState.department !== this.state.department || prevState.shift !== this.state.shift || prevState.active !== this.state.active || prevState.workerType !== this.state.workerType) {
        this.getUsers();
      }
    }
    if (prevState.department !== this.state.department || prevState.shift !== this.state.shift || prevState.date !== this.state.date || prevState.user !== this.state.user || prevState.approve !== this.state.approve || prevState.payType !== this.state.payType || prevState.active !== this.state.active || prevState.workerType !== this.state.workerType || prevState.attendanceCode !== this.state.attendanceCode) {
      updateSearchSetting({
        department: this.state.department,
        shift: this.state.shift,
        date: this.state.date,
        user: this.state.user,
        approve: this.state.approve,
        payType: this.state.payType,
        active: this.state.active,
        workerType: this.state.workerType,
        attendanceCode: this.state.attendanceCode,
      });
    }
  }

  setDate(data) {
    this.setState({ date: data }, () => {
      if (data[1] !== null || undefined) {
        this.calendar.updateInputfield(this.state.date);
      }
    });
  }
  submit() {
    let submitData = {};

    if (this.props.department && this.state.departmentPermission) {
      submitData.department = this.state.department;
    }
    if (this.props.shift && this.state.shiftPermission) {
      submitData.shift = this.state.shift;
    }
    if (this.props.date) {
      submitData.date = [new Date(this.state.date[0]), new Date(this.state.date[1])];
    }
    if (this.props.user) {
      submitData.user = this.state.user;
    }
    if (this.props.approve) {
      submitData.approve = this.state.approve;
    }
    if (this.props.payType) {
      submitData.payType = this.state.payType;
    }
    if (this.props.active) {
      submitData.active = this.state.active;
    }
    if (this.props.workerType) {
      submitData.workerType = this.state.workerType;
    }
    if (this.props.attendanceCode) {
      submitData.attendanceCode = this.state.attendanceCode;
    }

    this.props.runReport(submitData);
  }
  render() {
    return (
      <Dialog id="ReportSettings" resizable={false} draggable={false} visible={this.props.popSeen} baseZIndex={1000} dismissableMask onHide={() => this.props.closeSetting()} style={{ width: "30vw", overflow: "visible", borderRadius: ".8em", zIndex: 6000 }} contentStyle={{ overflow: "auto", padding: "0 0 2vh 0", rowGap: "0vh", maxHeight: "90vh" }} showHeader={false}>
        <ModalHeader title="Search Setting" titleFontSize="2.5vw" height="9vh" style={{ position: "sticky", top: "0", padding: "40px" }} />
        {this.props.department !== undefined && this.state.departmentPermission ? (
          <React.Fragment>
            <h1 className="search-text">Department:</h1>
            <Dropdown id="Department" value={this.state.department} className="hideMe centerDropdownText search-block" placeholder={"Select Department"} options={this.state.departments} onChange={(e) => this.setState({ department: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.shift !== undefined && this.state.shiftPermission ? (
          <React.Fragment>
            <h1 className="search-text">Shift:</h1>
            <Dropdown id="Shift" value={this.state.shift} className="hideMe centerDropdownText search-block" placeholder={"Select Shift"} options={this.state.shiftNumbers} onChange={(e) => this.setState({ shift: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.active !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">Active or Inactive:</h1>
            <Dropdown id="Active" value={this.state.active} className="hideMe centerDropdownText search-block" placeholder={"Select Status"} options={this.state.activeChoices} onChange={(e) => this.setState({ active: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.payType !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">Salary or Hourly:</h1>
            <Dropdown id="PayType" value={this.state.payType} className="hideMe centerDropdownText search-block" placeholder={"Select Status"} options={this.state.payTypes} onChange={(e) => this.setState({ payType: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.workerType !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">Worker Type:</h1>
            <Dropdown id="WorkerType" value={this.state.workerType} className="hideMe centerDropdownText search-block" placeholder={"Select Status"} options={this.state.workerTypes} onChange={(e) => this.setState({ workerType: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.date !== undefined ? (
          <React.Fragment>
            <div style={{}}>
              <h1 className="search-text" style={{ display: "inline-block" }}>
                Date:
              </h1>
              <Button label="Reset Default " className="adminPageButtons green" style={{ display: "inline-block", marginRight: "3vw", fontSize: "1vw" }} onClick={() => this.resetDateToDefault()} />
            </div>
            <RangePicker id="DateRange" popupStyle={{ zIndex: "99999" }} style={{ fontSize: "5vh", width: "80%", marginLeft: "10%" }} allowClear={false} value={this.state.date} onChange={(date) => this.setState({ date: date })} />
          </React.Fragment>
        ) : null}
        {this.props.approve !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">Approved Shifts:</h1>
            <Dropdown id="ApprovedShifts" value={this.state.approve} className="hideMe centerDropdownText search-block" placeholder={"All Shifts"} options={this.state.approveTypes} onChange={(e) => this.setState({ approve: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.attendanceCode !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">Attendance Code:</h1>
            <MultiSelect id="AttendanceCode" value={this.state.attendanceCode} className="hideMe centerDropdownText search-block" placeholder={"Select Attendance Code"} options={this.state.attendanceCodes} onChange={(e) => this.setState({ attendanceCode: e.value })} optionLabel="name" />
          </React.Fragment>
        ) : null}
        {this.props.user !== undefined ? (
          <React.Fragment>
            <h1 className="search-text">User:</h1>
            <Dropdown id="User" showClear value={this.state.user} className="hideMe centerDropdownText search-block" placeholder={"Select a User"} options={this.state.users} onChange={(e) => this.setState({ user: e.value })} optionLabel="name" filter filterBy="name,pin" />
          </React.Fragment>
        ) : null}
        <div className="btn-grid">
          <Button label="Back" style={{ bottom: "1%", position: "relative", right: "2.5%" }} className="adminPageButtons white" onClick={() => this.props.closeSetting()} />
          <Button label="Done" style={{ bottom: "1%", position: "relative", left: "2.5%" }} className="adminPageButtons red" onClick={() => this.submit()} />
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(Page);
