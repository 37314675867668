import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Schedule extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      schedule: [],
      days: {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    console.log(this.props.user);
    axios.post(address + "/getSchedule", { division: this.props.user?.division }).then(async (res) => {
      const data = res.data;
      console.log(data);
      this.setState({ schedule: data });
    });
  }

  timeEditor(rowData) {
    console.log(rowData);
    let value = rowData.value;
    let editorCallback = rowData.editorCallback;
    return <Calendar className="editCardInput" value={new Date(value)} placeholder={"N/A"} onChange={(e) => editorCallback(e.target.value)} timeOnly hourFormat="24" />;
  }

  disabledTimeEditor(value) {
    return <Calendar disabled className="editCardInput" value={new Date(value)} placeholder={"N/A"} timeOnly hourFormat="24" />;
  }

  onScheduleEditComplete = async (rowData) => {
    let newData = rowData.newData;
    console.log(rowData);
    if (this.checkDateDate(newData.Shift_Start, newData.Shift_End, newData.Break_Start, newData.Break_End)) {
      console.log(newData);
      axios.post(address + "/updateScheduleInfo", newData).then(async (res) => {
        const data = res.data;
        this.setState({ schedule: data });
      });
    } else {
      this.getData();
      this.toast.show({ severity: "error", summary: "Break Time have to be within Shift Time", life: 5000 });
    }
  };

  checkDateDate(shiftStart, shiftEnd, breakStart, breakEnd) {
    console.log(new Date(shiftStart), new Date(shiftEnd), breakStart, breakEnd);
    shiftEnd = new Date(new Date(shiftEnd).setUTCDate(new Date(shiftStart).getUTCDate()));
    breakStart = new Date(new Date(breakStart).setUTCDate(new Date(shiftStart).getUTCDate()));
    breakEnd = new Date(new Date(breakEnd).setUTCDate(new Date(shiftStart).getUTCDate()));

    if (new Date(shiftStart) > new Date(shiftEnd)) {
      //if start time is less than end time assume the end time is next date
      shiftEnd = new Date(new Date(shiftEnd).setUTCDate(new Date(shiftEnd).getUTCDate() + 1));
    }
    if (new Date(breakStart) > new Date(breakEnd)) {
      //if start time is less than end time assume the end time is next date
      breakEnd = new Date(new Date(breakEnd).setUTCDate(new Date(breakEnd).getUTCDate() + 1));
    }

    if (new Date(shiftStart) > new Date(breakStart)) {
      //if break start time is less than shift start time then, the break is in second day
      breakStart = new Date(new Date(breakStart).setUTCDate(new Date(breakStart).getUTCDate() + 1));
      breakEnd = new Date(new Date(breakEnd).setUTCDate(new Date(breakEnd).getUTCDate() + 1));
    }

    return new Date(shiftStart) <= new Date(breakStart) && new Date(breakEnd) <= new Date(shiftEnd);
  }

  copyPrevRow(rowData) {
    let firstRow = this.state.schedule.find((ele) => ele.Shift === rowData.Shift && ele.SubDivision === rowData.SubDivision && ele.Day === "0");
    firstRow.Day = rowData.Day;
    rowData.Break_End = axios.post(address + "/updateScheduleInfo", firstRow).then(async (res) => {
      const data = res.data;
      this.setState({ schedule: data });
    });
  }
  render() {
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <DataTable value={this.state.schedule} scrollable scrollHeight="88vh" editMode="row" onRowEditComplete={this.onScheduleEditComplete} responsiveLayout="scroll" style={{ height: "100%", width: "100%" }}>
          <Column header="Shift" sortable field="Shift" headerStyle={{ width: "2rem" }} exportable={false}></Column>
          <Column sortable header="Day" field="Day" body={(option) => this.state.days[option.Day]} editor={(option) => this.state.days[option.value]} headerStyle={{ width: "2rem" }} exportable={false}></Column>
          <Column header="Shift Start Time" field="Shift_Start" body={(options) => this.disabledTimeEditor(options.Shift_Start)} editor={(options) => this.timeEditor(options)} style={{ width: "20%" }}></Column>
          <Column header="Shift End Time" field="Shift_End" body={(options) => this.disabledTimeEditor(options.Shift_End)} editor={(options) => this.timeEditor(options)} style={{ width: "20%" }}></Column>
          <Column header="Break Start Time" field="Break_Start" body={(options) => this.disabledTimeEditor(options.Break_Start)} editor={(options) => this.timeEditor(options)} style={{ width: "20%" }}></Column>
          <Column header="Break End Time" field="Break_End" body={(options) => this.disabledTimeEditor(options.Break_End)} editor={(options) => this.timeEditor(options)} style={{ width: "20%" }}></Column>
          <Column rowEditor style={{ flexBasis: "4em", flexShrink: 0, flexGrow: 0 }} bodyStyle={{ textAlign: "center" }}></Column>
          <Column
            body={(rowData) => {
              if (rowData.Day === "0") {
                return null;
              } else {
                return <i className="pi pi-copy" style={{ fontSize: "2rem" }} onClick={() => this.copyPrevRow(rowData)}></i>;
              }
            }}
            style={{ flexBasis: "4em", flexShrink: 0, flexGrow: 0 }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Schedule);
