import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import IndepthPTO from "./indepthpto.component.jsx";
import OffensesHistory from "./offensesHistoyr.componenet";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import TimeOff from "./vacation.component";
import { connect } from "react-redux";
import PictureComment from "./pictureComment.component.jsx";

import isDaylightSavingTime from "../global_functions/checkDaylightSaving.function";
import { getYOS, getAccrualRate } from "../global_functions/accural";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Admin extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      user: 1,
      userDropdown: [
        { name: "Active Users", value: 1 },
        { name: "Inactive Users", value: 0 },
      ],
      positions: [],
      users: [],
      secureUsers: [],
      inputTimeout: 0,
      shifts: [],
      absences: [],
      data: { ID: 0 },
      userIndex: 0,
      newShift: [{ start_time: null, end_time: null }],
      value1: "hii",
      departments: [],
      hourlyVsSalary: [
        { name: "Hourly", value: "Hourly" },
        { name: "Salary", value: "Salary" },
      ],
      weekends: [
        { name: "Friday", value: "FRIDAY" },
        { name: "Saturday", value: "SATURDAY" },
        { name: "Sunday", value: "SUNDAY" },
        { name: "N/A", value: null },
      ],
      shiftNumbers: [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
      ],
      workerTypes: [
        { name: "Full Time", value: "FULL TIME" },
        { name: "Part Time", value: "PART TIME" },
        { name: "Intern", value: "INTERN" },
      ],
      subDivisions: [
        { name: "Normal", value: "Normal" },
        { name: "Hope Center", value: "Hope Center" },
      ],
      attendanceCodes: [
        { value: "Call In PTO", code: "CALL IN PTO" },
        { value: "Unexcused PTO", code: "UNEXCUSED PTO" },
        { value: "Unexcused without PTO", code: "UNEXCUSED W/O PTO" },
        { value: "Overtime", code: "OVERTIME" },

        { value: null, code: "DIRECT" },
      ],
      breakInfoOpen: false,
      userBreakData: [],
      createUserFN: "",
      createUserLN: "",
      createUserPIN: "",
      createUserShift: "",
      createUserPos: "",
      createUserDept: "",
      createUserOT: "",
      createUserDate: "",
      createUserWage: "",
      createUserWorkerType: "",
      createUserPayType: "",
    };
  }

  returnPositionsGrades(department, showAll) {
    if (showAll) {
      return this.state.positions;
    } else {
      return this.state.positions.filter((obj) => obj.department === department);
    }
  }

  componentDidMount() {
    this.refreshData();
    this.getAttendanceCodes();
    console.log(this.state.data.ID);
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  refreshData() {
    console.log("refresh");
    axios.get(address + "/getDepartments").then((res) => {
      const data = res.data;
      this.setState({ departments: data.departments, positions: data.positions });
    });
    axios.post(address + "/getUsers", { active: this.state.user, department: this.props.user.department, shift: this.props.user.shift, division: this.props.user.division, secureUserId: this.props.user.id }).then((res) => {
      const data = res.data;
      console.log(data);
      if (JSON.stringify(this.state.data) !== "{}") {
        let index = data.findIndex((user) => user.ID === this.state.data.ID);
        if (index !== -1) {
          this.getEmployeeCardInfo({ ID: data[index].ID });
        }
      }
      let newData = data.map((ele) => {
        ele.nameAndPin = ele.name + " " + ele.pin;
        return ele;
      });
      this.setState({ users: newData });
    });
    axios.post(address + "/getSecureUserList", { secureUser: this.props.user }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ secureUsers: data });
    });
  }

  updateShiftsValue(rowIndex, field, value) {
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    console.log(rowIndex, field, value);
    copy[rowIndex][field] = value;
    this.setState({ shifts: copy });
    axios
      .post(address + "/UpdateSQLTime", {
        field: field,
        rowData: copy[rowIndex],
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
      });
  }

  AlternateupdateShiftsValue(rowIndex, field, value) {
    let copy = JSON.parse(JSON.stringify(this.state.newShift));
    copy[rowIndex][field] = value;
    this.setState({ newShift: copy });
  }

  TimeEditor(options, timeOnly) {
    console.log(options);
    let field = options.field;
    if (options.rowData[options.field] === null) {
      field = field === "start_time" ? "planned_start_time" : "planned_end_time";
    }
    return <Calendar id="time24" timeOnly={timeOnly ? true : false} hourFormat="12" value={this.state.shifts[options.rowIndex][field]} onChange={(e) => this.updateShiftsValue(options.rowIndex, options.field, e.value)} />;
  }

  AttendanceEditor(options) {
    console.log(options.rowData);
    return <Dropdown className="primereactfix-large-font-size-dropdown" style={{ width: "60%", padding: ".12em", fontSize: "1em" }} value={options.rowData.attendance} placeholder={"N/A"} options={this.state.attendanceCodes} onChange={(e) => this.updateShiftsValue(options.rowIndex, options.field, e.value)} optionLabel="name" />;
  }

  AlternateEditor(options) {
    console.log(options);
    let date = new Date(options.rowData[options.field]);
    if (options.rowData[options.field] === null) {
      date = new Date(options.rowData[options.field === "start_time" ? "planned_start_time" : "planned_end_time"]);
      date = new Date();
    }
    return <Calendar id="time24" hourFormat="12" value={date} onChange={(e) => this.AlternateupdateShiftsValue(options.rowIndex, options.field, e.value)} showTime showSeconds />;
  }

  formatTime(time, timeOnly) {
    if (time === null) {
      return "N/A";
    }
    if (timeOnly) {
      return new Date(time).toLocaleTimeString();
    }
    return new Date(time).toLocaleDateString();
  }

  async createShift() {
    console.log(this.state.newAttendance, this.state.newDate, this.state.data);
    if (this.state.newAttendance === undefined || this.state.newDate === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Must have all fields filled out", life: 3000 });
      return;
    }
    axios.post(address + "/createAdminShift", { date: this.state.newDate, attendance: this.state.newAttendance, userId: this.state.data.ID }).then(async (res) => {
      const data = res.data;
      await this.CallShiftData(this.state.data.ID);
      this.setState({ createShiftModal: false });
      console.log(data);
    });
  }

  async CallShiftData(userId) {
    console.log(this.state.data);
    let index = this.state.users.findIndex((e) => e.ID === userId);
    console.log(index, userId, this.state.users);
    if (index !== -1) {
      this.setState({ userIndex: index });
    }
    await axios.get(address + "/getShifts", { params: { userId: userId } }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ shifts: data.shifts });
      this.setState({ absences: data.absences });
      this.setState({ CreateShiftModal: false });
    });
    return "finished";
  }

  getEmployeeCardInfo(data) {
    console.log(data);
    axios
      .post(address + "/getEmployeeCardInfo", {
        ID: data.ID,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (data === "User Not Found") {
          this.toast.show({ severity: "error", summary: data, life: 3000 });
        } else {
          this.setState({ data: data }, () => this.CallShiftData(data.ID));
        }
      });
  }

  UpdateSQL(rowData, field) {
    axios
      .post(address + "/UpdateSQLTime", {
        field: field,
        rowData: rowData,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
      });
  }

  absencesCalendar = (date) => {
    for (let i = 0; i < this.state.absences.length; i++) {
      let absence = this.state.absences[i];
      if (absence.year === date.year) {
        if (absence.month === date.month) {
          if (absence.date === date.day) {
            return <div className="dateDayMimic approved">{date.day}</div>;
          }
        }
      }
    }

    return (
      <div className="dateDayMimic" style={{}}>
        {date.day}
      </div>
    );
  };

  createUserCall() {
    let errors = [];
    if (this.state.createUserFN.length === 0) {
      errors.push("Must enter First Name");
    }
    if (this.state.createUserLN.length === 0) {
      errors.push("Must enter Last Name");
    }
    if (this.state.createUserShift.length === 0) {
      errors.push("Must select a shift");
    }
    if (this.state.createUserWorkerType.length === 0) {
      errors.push("Must select a worker type");
    }
    if (this.state.createUserPayType.length === 0) {
      errors.push("Must select a pay type");
    }
    console.log(errors);
    for (let i = 0; i < errors.length; i++) {
      console.log(errors[i]);
      let msg = errors[i];
      this.toast.show({ severity: "error", summary: msg, life: 3000 });
    }
    if (errors.length > 0) {
      return;
    }
    let div = this.props.user.division;
    //TODO ADD CONDITION FOR ALL DIVISION CODE! (SHOULD USE STATE'S DIVISON CODE)
    if (div === undefined || div === null) {
      div = "HAVEN";
    }

    let user = { division: div, first_name: this.state.createUserFN, last_name: this.state.createUserLN, pin: this.state.createUserPIN, shift: this.state.createUserShift, department: this.state.createUserDept, position: this.state.createUserPos, overtime: this.state.createUserOT, date_hired: this.state.createUserDate, wage: this.state.createUserWage, worker_type: this.state.createUserWorkerType, type: this.state.createUserPayType };
    axios.post(address + "/createNewUser", { user: user }).then((res) => {
      const data = res.data;
      this.refreshData();
      if (data.err) {
        this.toast.show({ severity: "error", summary: "Employee # already in use", detail: data.err, life: 3000 });
      } else {
        this.toast.show({ severity: "success", summary: "User Created", detail: "User created successfully", life: 3000 });
        // this.setState({ createUserFN: "", createUserLN: "", createUserPIN: "" });
      }
      this.setState({ createUserModal: false });
    });
  }

  startEditingUser() {
    axios.post(address + "/getEditableUser", { user_id: this.state.data.ID, getEditableUser: this.props.user.id }).then((res) => {
      const data = res.data;
      this.setState({ editableUserData: data, editingUser: true });
    });
  }

  async startEditingBreak() {
    axios.post(address + "/getBreakTime", { userId: this.state.data.ID }).then((res) => {
      const data = res.data;
      console.log(data);
      let dayLightSaving = isDaylightSavingTime(new Date());
      let breakResult = [];
      console.log(dayLightSaving);
      for (let i = 0; i < data.length; i++) {
        let breakTime = data[i];
        //create the break start time and end time
        let start = new Date();
        let end = new Date();
        if (dayLightSaving) {
          breakTime.break_start_hour++;
          breakTime.break_end_hour++;
        }
        start.setUTCHours(breakTime.break_start_hour);
        start.setUTCMinutes(breakTime.break_start_min);

        end.setUTCHours(breakTime.break_end_hour);
        end.setUTCMinutes(breakTime.break_end_min);
        breakResult.push({
          start: start,
          end: end,
          ID: breakTime.ID,
          editor: "24HourCalendar",
        });
      }
      this.setState({ userBreakData: breakResult, breakInfoOpen: true });
    });
  }

  disabledEditor(rowData) {
    let editor = rowData.editor;
    let value = rowData.value;
    if (editor === "text") {
      if (rowData.prop === "wage") {
        let foundIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "type");
        if (foundIndex === -1) {
          return "Error incomplete data set";
        }
        return <InputText disabled className="editCardInput" type="text" value={(value === null ? "Wage Unset " : value) + (this.state.editableUserData[foundIndex].value === "Salary" ? "/yr" : "/hr")} />;
      }
      return <InputText disabled className="editCardInput" type="text" value={value} />;
    }
    if (editor === "calendar") {
      return <Calendar disabled className="editCardInput" value={new Date(value)} placeholder={"N/A"} inputStyle={{ fontSize: "1em", opacity: 1 }} selectionMode="single" />;
    }
    if (editor === "24HourCalendar") {
      return <Calendar disabled className="editCardInput" value={new Date(value)} placeholder={"N/A"} timeOnly hourFormat="24" />;
    }
    if (editor === "dropdown") {
      if (rowData.prop === "overtime") {
        return <Dropdown dropdownIcon="hideMe" className="editCardInput" disabled value={value} placeholder={"N/A"} options={this.state.weekends} optionLabel="name" />;
      } else if (rowData.prop === "supervisor_id") {
        return <Dropdown value={value} className="editCardInput" placeholder={"N/A"} options={this.state.secureUsers} disabled optionLabel="username" optionValue="id" />;
      } else if (rowData.prop === "shift") {
        return <Dropdown dropdownIcon="hideMe" className="editCardInput" disabled value={value} placeholder={"N/A"} options={this.state.shiftNumbers} optionLabel="name" />;
      } else if (rowData.prop === "department") {
        return <Dropdown dropdownIcon="hideMe" className="editCardInput" disabled value={value} placeholder={"N/A"} options={this.state.departments} optionLabel="name" />;
      } else if (rowData.prop === "type") {
        return <Dropdown dropdownIcon="hideMe" className="editCardInput" value={value} placeholder={"N/A"} options={this.state.hourlyVsSalary} disabled optionLabel="name" />;
      } else if (rowData.prop === "position") {
        let foundIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "department");
        if (foundIndex === -1) {
          return "Error incomplete data set";
        }
        return <Dropdown dropdownIcon="hideMe" className="editCardInput" disabled value={value} placeholder={value} options={() => this.returnPositionsGrades(this.state.editableUserData[foundIndex].value, true)} optionLabel="name" />;
      }
    } else if (editor === "boolean") {
      if (rowData.prop === "title") {
        return (
          <Dropdown
            disabled
            className="editCardInput"
            dropdownIcon="hideMe"
            value={value}
            placeholder={"N/A"}
            options={[
              { name: "True", value: "supervisor" },
              { name: "False", value: "" },
            ]}
            optionLabel="name"
          />
        );
      }
      return (
        <Dropdown
          disabled
          className="editCardInput"
          dropdownIcon="hideMe"
          value={value}
          placeholder={"N/A"}
          options={[
            { name: "True", value: 1 },
            { name: "False", value: 0 },
          ]}
          optionLabel="name"
        />
      );
    }
  }

  changeValue(rowData, value) {
    //console.log(rowData);
    let copy = JSON.parse(JSON.stringify(this.state.editableUserData));
    let index = copy.findIndex((obj) => obj.prop === rowData.prop);
    //for multiple selection
    if (rowData.prop === "worker_type") {
      if (value.length === 0) {
        //worker type can not be empty
        return;
      } else {
        value = value.toString();
      }
    }
    copy[index].value = value;
    this.setState({ editableUserData: copy });
  }

  textEditor(rowData) {
    let editor = rowData.editor;
    let value = rowData.value;
    let editorCallback = rowData.editorCallback;
    if (editor === "text") {
      if (rowData.prop === "wage") {
        let pinIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "pin");
        if (this.state.editableUserData[pinIndex].value === this.props.user.pin) {
          let foundIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "type");
          if (foundIndex === -1) {
            return "Error incomplete data set";
          }
          return <InputText disabled className="editCardInput" type="text" value={(value === null ? "Wage Unset " : value) + (this.state.editableUserData[foundIndex].value === "Salary" ? "/yr" : "/hr")} />;
        } else {
          let foundIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "type");
          if (foundIndex === -1) {
            return "Error incomplete data set";
          }
          return <InputNumber value={value} onChange={(e) => this.changeValue(rowData, e.value)} mode="decimal" minFractionDigits={2} suffix={this.state.editableUserData[foundIndex].value === "Salary" ? "/yr" : "/hr"} />;
        }
      }
      return <InputText type="text" keyfilter={rowData.prop === "pin" || rowData.prop === "phone" ? "int" : ""} value={value} onChange={(e) => this.changeValue(rowData, e.target.value)} />;
    }
    if (editor === "number") {
      return <InputNumber value={value} onChange={(e) => this.changeValue(rowData, e.value)} mode="decimal" minFractionDigits={2} maxFractionDigits={10} />;
    }
    if (editor === "calendar") {
      return <Calendar value={new Date(value)} monthNavigator yearNavigator yearRange={"1970:" + new Date().getFullYear()} placeholder={"N/A"} onChange={(e) => this.changeValue(rowData, e.value)} inputStyle={{ fontSize: "1em" }} selectionMode="single" />;
    }
    if (rowData.rowData !== null && rowData.rowData !== undefined) {
      if (rowData.rowData.editor === "24HourCalendar") {
        return <Calendar className="editCardInput" value={new Date(value)} placeholder={"N/A"} onChange={(e) => editorCallback(e.target.value)} timeOnly hourFormat="24" />;
      }
    }
    if (editor === "dropdown") {
      if (rowData.prop === "overtime") {
        return <Dropdown value={value} placeholder={"N/A"} options={this.state.weekends} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "supervisor_id") {
        return <Dropdown value={value} placeholder={"N/A"} filter options={this.state.secureUsers} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="username" optionValue="id" showClear />;
      } else if (rowData.prop === "shift") {
        return <Dropdown value={value} placeholder={"N/A"} options={this.state.shiftNumbers} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "department") {
        return <Dropdown value={value} placeholder={"N/A"} options={this.state.departments} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "type") {
        return <Dropdown value={value} placeholder={"N/A"} options={this.state.hourlyVsSalary} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "position") {
        let foundIndex = this.state.editableUserData.findIndex((obj) => obj.prop === "department");
        if (foundIndex === -1) {
          return "Error incomplete data set";
        }
        return <Dropdown value={value} placeholder={"N/A"} options={this.returnPositionsGrades(this.state.editableUserData[foundIndex].value, false)} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "worker_type") {
        return <MultiSelect value={value === null ? null : value.split(",")[0] === "" ? null : value.split(",")} placeholder={"N/A"} options={this.state.workerTypes} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      } else if (rowData.prop === "subDivision") {
        return <Dropdown value={value} placeholder={"N/A"} options={this.state.subDivisions} onChange={(e) => this.changeValue(rowData, e.value)} optionLabel="name" />;
      }
    } else if (editor === "boolean") {
      if (rowData.prop === "title") {
        return (
          <Dropdown
            value={value}
            placeholder={"N/A"}
            options={[
              { name: "True", value: "supervisor" },
              { name: "False", value: "" },
            ]}
            onChange={(e) => this.changeValue(rowData, e.value)}
            optionLabel="name"
          />
        );
      }
      return (
        <Dropdown
          value={value}
          placeholder={"N/A"}
          options={[
            { name: "True", value: 1 },
            { name: "False", value: 0 },
          ]}
          onChange={(e) => this.changeValue(rowData, e.value)}
          optionLabel="name"
        />
      );
    }
    return "Error invalid editor type";
  }

  onRowEditComplete = ({ data, newData, index }) => {
    if (data.value === newData.value) {
      console.log("matching.");
    }
    if (newData.prop === "pin") {
      let userId = newData.value;
      let userIdLength = userId.length;
      for (let z = 0; z < 4 - userIdLength; z++) {
        userId = "0" + userId;
      }
      newData.value = userId;
    }
    let copy = JSON.parse(JSON.stringify(this.state.editableUserData));
    copy[index] = newData;

    this.setState({ editableUserData: copy });
    axios.post(address + "/updateEditableUser", { prop: newData.prop, value: newData.value, user_id: this.state.data.ID }).then((res) => {
      const data = res.data;
      console.log(data);
      this.refreshData();
    });
    console.log(data, newData, index);
  };

  onBreakEditComplete = async (rowData) => {
    let newData = rowData.newData;
    axios.post(address + "/updateBreakTime", { userId: this.state.data.ID, breakID: newData.ID, start: newData.start, end: newData.end }).then(async (res) => {
      const data = res.data;
      console.log(data);
      await this.startEditingBreak();
    });
  };

  async addBreak() {
    axios.post(address + "/insertBreakTime", { userId: this.state.data.ID }).then(async (res) => {
      const data = res.data;
      console.log(data);
      await this.startEditingBreak();
    });
  }

  async deleteBreak() {
    console.log(this.state.selectedProducts);
    axios.post(address + "/deleteBreakTime", { userId: this.state.data.ID, breakID: this.state.selectedProducts.ID }).then(async (res) => {
      const data = res.data;
      console.log(data);
      await this.startEditingBreak();
      this.setState({ selectedProducts: null });
    });
  }

  saveUserInfo() {
    let updateData = {};
    let editableUserData = this.state.editableUserData;
    for (let i = 0; i < editableUserData.length; i++) {
      //for clear function, if that is clear it will show undefined
      if (editableUserData[i].value === undefined) {
        editableUserData[i].value = null;
      }
      updateData[editableUserData[i].prop] = editableUserData[i].value;
    }
    axios.post(address + "/saveUserInfo", { user_id: this.state.data.ID, updateData: updateData }).then((res) => {
      const data = res.data;
      console.log(data);
      if (data === "Duplicate Pin!") {
        this.toast.show({ severity: "error", summary: "Employee # already in use", detail: data.err, life: 3000 });
        return;
      }
      this.getEmployeeCardInfo(this.state.data);
      this.setState({ editingUser: false });
    });
  }

  yos(data) {
    //NOTE This just calculates Year of Service!
    let newDate = new Date(data.date_hired === null ? data.intern_hire_date : data.date_hired);
    newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
    let yos = 0;
    while (new Date() >= newDate) {
      yos += 1;
      newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
    }
    return yos;
  }

  accrualRate(data) {
    //calculate the accruals rate, it will calculated the 1st day of the following year
    let newDate = new Date(data.date_hired === null ? data.intern_hire_date : data.date_hired);
    newDate = new Date(newDate).setMonth(11);
    newDate = new Date(new Date(newDate).setDate(31));
    let yos = this.yos({ date_hired: newDate });
    console.log(yos);
    let rate;
    if (yos < 2) {
      rate = 10 / 12;
    } else if (yos < 5) {
      rate = 15 / 12;
    } else if (yos < 20) {
      rate = 20 / 12;
    } else if (yos >= 20) {
      rate = 25 / 12;
    } else {
      rate = 0;
    }
    return rate.toFixed(3);
  }
  async callCreateShift() {
    //todo rename function, this is called when you open create user.
    let x = await axios.post(address + "/getHighestPIN", { division: this.props.user.division }).then((res) => {
      const data = res.data;
      return data;
    });
    this.setState({ createUserModal: true, createUserFN: "", createUserLN: "", createUserPIN: x, createUserShift: "", createUserPos: "", createUserDept: "", createUserOT: "", createUserDate: "", createUserWage: "", createUserWorkerType: "", createUserPayType: "" });
  }
  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />

        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.vacationModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ vacationModal: false }, () => this.refreshData())} contentStyle={{ width: "auto", height: "auto", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <TimeOff data={this.state.data} onHide={() => this.setState({ vacationModal: false })} />
        </Dialog>

        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.indepthPTOModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ indepthPTOModal: false }, () => this.refreshData())} contentStyle={{ width: "auto", height: "auto", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <IndepthPTO user={this.props.user} data={this.state.data} />
        </Dialog>

        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.offenseModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ offenseModal: false }, () => this.refreshData())} contentStyle={{ width: "auto", height: "auto", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <OffensesHistory user={this.props.user} data={this.state.data} />
        </Dialog>

        <Dialog resizable={false} draggable={false} visible={this.state.createUserModal} baseZIndex={1000} dismissableMask onHide={() => this.setState({ createUserModal: false }, () => this.refreshData())} style={{ width: "50vw", minHeight: "30vh", padding: 0, overflow: "hidden", borderRadius: ".8em", zIndex: 6000 }} contentStyle={{ overflow: "hidden", display: "grid", padding: 0 }} showHeader={false}>
          <ModalHeader title="Create User" height="12vh" />
          <div style={{ display: "grid", padding: "1em", gridTemplateColumns: "17% 17% 17% 17% 17% 15%", fontSize: "1.5em", height: "25vh" }}>
            <div className="field" style={{ display: "grid" }}>
              <label>First Name</label>
              <InputText value={this.state.createUserFN} placeholder={"N/A"} onChange={(e) => this.setState({ createUserFN: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Last Name</label>
              <InputText value={this.state.createUserLN} placeholder={"N/A"} onChange={(e) => this.setState({ createUserLN: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Shift</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserShift} placeholder={"N/A"} options={this.state.shiftNumbers} onChange={(e) => this.setState({ createUserShift: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Employee #</label>
              <InputText value={this.state.createUserPIN} placeholder={"N/A"} onChange={(e) => this.setState({ createUserPIN: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Department</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserDept} placeholder={"N/A"} options={this.state.departments} onChange={(e) => this.setState({ createUserDept: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Position/Grade</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserPos} placeholder={"N/A"} options={this.returnPositionsGrades(this.state.createUserDept, false)} onChange={(e) => this.setState({ createUserPos: e.value })} optionLabel="name" />
            </div>{" "}
            <div className="field" style={{ display: "grid" }}>
              <label>OT Day</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserOT} placeholder={"N/A"} options={this.state.weekends} onChange={(e) => this.setState({ createUserOT: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Date Hired</label>
              <Calendar className="createUserInput" value={this.state.createUserDate} placeholder={"N/A"} onChange={(e) => this.setState({ createUserDate: e.value })} inputStyle={{ fontSize: "1em", opacity: 1 }} selectionMode="single" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Worker Type</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserWorkerType} placeholder={"N/A"} options={this.state.workerTypes} onChange={(e) => this.setState({ createUserWorkerType: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Hourly/Salary</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createUserPayType} placeholder={"N/A"} options={this.state.hourlyVsSalary} onChange={(e) => this.setState({ createUserPayType: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Wage</label>
              <InputNumber value={this.state.createUserWage} onChange={(e) => this.setState({ createUserWage: e.value })} mode="decimal" minFractionDigits={2} suffix={this.state.createUserPayType === "Salary" ? "/yr" : "/hr"} />
              {/*<InputText value={this.state.createUserWage} placeholder={"N/A"} onChange={(e) => this.setState({ createUserWage: e.target.value })} className="p-d-block createUserInput" />*/}
            </div>
          </div>
          <div>
            <Button label="Back" className="menuButton" style={{ height: "5vh", fontSize: "1.5em", width: "5vw" }} onClick={() => this.setState({ createUserModal: false })} />
            <Button label="Create" className="menuButton" style={{ height: "5vh", fontSize: "1.5em", width: "5vw", float: "right" }} onClick={() => this.createUserCall()} />
          </div>
        </Dialog>

        <Dialog resizable={false} draggable={false} visible={this.state.plannedAbsencesModal} baseZIndex={1000} dismissableMask onHide={() => this.setState({ plannedAbsencesModal: false })} style={{ width: "20vw", minHeight: "30vh", overflow: "hidden", borderRadius: ".8em", zIndex: 6000 }} contentStyle={{ overflow: "hidden", padding: 0, display: "grid" }} showHeader={false}>
          <ModalHeader title="Planned Absences" titleFontSize="1.5vw" />
          <Calendar style={{ float: "left" }} dateTemplate={this.absencesCalendar} inline />
        </Dialog>

        {/* SPACER */}

        <Dialog baseZIndex={2} resizable={false} draggable={false} dismissableMask visible={this.state.editingUser} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ editingUser: false }, () => this.refreshData())} contentStyle={{ width: "45vw", height: "70vh", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <ModalHeader title="User Information" titleFontSize="3vw" hide={() => this.setState({ editingUser: !this.state.editingUser })} />
          <div style={{ width: "28vw", height: "58vh", float: "left" }}>
            <DataTable value={this.state.editableUserData} footer scrollable scrollHeight="flex" editMode="row" editingRows={this.state.editingRows} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onRowEditComplete} responsiveLayout="scroll" style={{ height: "100%", width: "100%" }}>
              <Column header="Property" field="header" style={{ width: "20%" }}></Column>
              <Column header="Value" field="value" body={(options) => this.textEditor(options)} style={{ width: "20%" }}></Column>
              {/*<Column rowEditor style={{ flexBasis: "4em", flexShrink: 0, flexGrow: 0 }} bodyStyle={{ textAlign: "center" }}></Column>*/}
            </DataTable>

            <Button label="Check Break" className="adminPageButtons" style={{ color: "white", backgroundColor: "red", height: "3vh", width: "20%", bottom: "0.1%", position: "absolute", left: "20%" }} onClick={() => this.startEditingBreak()} />
          </div>
          <div style={{ width: "35%", marginRight: "1%", height: " 47vh", float: "right", marginTop: "2%", marginBottom: "10%" }}>
            <PictureComment userId={this.state.data.ID} />
          </div>
          <Button label="Save" style={{ bottom: "5%", position: "absolute", right: "2.5%" }} className="adminPageButtons green" onClick={() => this.saveUserInfo()} />
        </Dialog>
        <Dialog baseZIndex={3} resizable={false} draggable={false} dismissableMask visible={this.state.breakInfoOpen} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ breakInfoOpen: false }, () => this.refreshData())} contentStyle={{ width: "45vw", height: "70vh", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <ModalHeader title="Break Information" titleFontSize="3vw" hide={() => this.setState({ editingUser: !this.state.editingUser })} />
          <div style={{ width: "100%", height: "55vh" }}>
            <DataTable value={this.state.userBreakData} emptyMessage="Will use default break time!" footer scrollable scrollHeight="flex" editMode="row" editingRows={this.state.editingRows} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onBreakEditComplete} selection={this.state.selectedProducts} onSelectionChange={(e) => this.setState({ selectedProducts: e.value })} responsiveLayout="scroll" style={{ height: "100%", width: "100%" }}>
              <Column selectionMode="single" headerStyle={{ width: "3rem" }} exportable={false}></Column>
              <Column header="Start Time" field="start" body={(options) => this.disabledEditor({ value: options.start, editor: "24HourCalendar" })} editor={(options) => this.textEditor(options)} style={{ width: "20%" }}></Column>
              <Column header="End Time" field="end" body={(options) => this.disabledEditor({ value: options.end, editor: "24HourCalendar" })} editor={(options) => this.textEditor(options)} style={{ width: "20%" }}></Column>
              <Column rowEditor style={{ flexBasis: "4em", flexShrink: 0, flexGrow: 0 }} bodyStyle={{ textAlign: "center" }}></Column>
            </DataTable>
          </div>
          <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" style={{ bottom: "1%", position: "absolute", width: "6vw", fontSize: "1em", left: "2.5%" }} onClick={() => this.addBreak()} />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger" style={{ bottom: "1%", position: "absolute", width: "6vw", fontSize: "1em", left: "20.5%" }} onClick={() => this.deleteBreak()} disabled={!this.state.selectedProducts} />
          </React.Fragment>
          <Button label="Back" style={{ bottom: "1%", position: "absolute", right: "2.5%" }} className="adminPageButtons white" onClick={() => this.setState({ breakInfoOpen: false })} />
        </Dialog>

        <div id="ANGRYGRANDPARENT" style={{ display: "flex", width: "100%", height: "100%" }}>
          {this.state.data.first_name ? (
            <div style={{ float: "left", width: "80%" }}>
              <div style={{ fontSize: "3vh", height: "auto", padding: ".5em", margin: ".5em", backgroundColor: "#efefef", borderRadius: ".5em" }}>
                <h1 style={{ fontSize: "8vh" }}>{(this.state.data.first_name + (this.state.data.first_name ? " " : null) + this.state.data.last_name + " " + (this.state.data.inactive ? "(inactive)" : "") || "none selected") + " #" + this.state.data.pin}</h1>
                <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", gridRowGap: "1.5vh" }}>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Shift</label>
                    <Dropdown dropdownIcon="hideMe" disabled className="primereactfix-large-font-size-dropdown employeeCardInput" value={this.state.data.shift} placeholder={"N/A"} options={this.state.shiftNumbers} onChange={(e) => this.updateData("shift", e.value)} optionLabel="name" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>OT Day</label>
                    <Dropdown dropdownIcon="hideMe" disabled className="primereactfix-large-font-size-dropdown employeeCardInput" value={this.state.data.overtime} placeholder={"N/A"} options={this.state.weekends} onChange={(e) => this.updateData("overtime", e.value)} optionLabel="name" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Position</label>
                    <Dropdown dropdownIcon="hideMe" disabled className="primereactfix-large-font-size-dropdown employeeCardInput" value={this.state.data.position} placeholder={"N/A"} options={this.returnPositionsGrades(this.state.data.department, true)} onChange={(e) => this.updateData("position", e.value)} optionLabel="name" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Department</label>
                    <Dropdown dropdownIcon="hideMe" disabled className="primereactfix-large-font-size-dropdown employeeCardInput" value={this.state.data.department} placeholder={"N/A"} options={this.state.departments} onChange={(e) => this.updateData("department", e.value)} optionLabel="name" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Paid Time Off</label>
                    <InputText disabled keyfilter="num" value={(Math.round(this.state.data.vacation_hours * 100) / 100).toFixed(2)} placeholder={"N/A"} onChange={(e) => this.updateData("vacation_hours", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Bonus PTO</label>
                    <InputText disabled keyfilter="num" value={(Math.round(this.state.data.wellness_hours * 100) / 100).toFixed(2)} placeholder={"N/A"} onChange={(e) => this.updateData("wellness_hours", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Years of Service</label>
                    <InputText disabled keyfilter="num" value={getYOS(this.state.data.date_hired, this.state.data.intern_hire_date)} placeholder={"N/A"} onChange={(e) => this.updateData("points", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Date Hired</label>
                    <Calendar disabled value={new Date(this.state.data.date_hired === null ? this.state.data.intern_hire_date : this.state.data.date_hired)} placeholder={"N/A"} onChange={(e) => this.updateData("date_hired", e.value)} inputStyle={{ fontSize: "1em", opacity: 1 }} selectionMode="single" className="employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Total Offenses</label>
                    <InputText disabled keyfilter="num" value={this.state.data.points} placeholder={"N/A"} onChange={(e) => this.updateData("points", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Phone Number</label>
                    <InputText disabled keyfilter="num" value={this.state.data.phone || ""} placeholder={"N/A"} onChange={(e) => this.updateData("phone", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Address</label>
                    <InputText disabled keyfilter="num" value={this.state.data.address || ""} placeholder={"N/A"} onChange={(e) => this.updateData("address", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                  <div className="field" style={{ display: "grid" }}>
                    <label>Accrual Rate</label>
                    <InputText disabled keyfilter="num" value={getAccrualRate(this.state.data.date_hired, this.state.data.intern_hire_date, this.state.data.accrual_rate)||''} placeholder={"N/A"} onChange={(e) => this.updateData("address", e.target.value)} className="p-d-block employeeCardInput" />
                  </div>
                </div>
              </div>
              <div style={{ margin: ".5em", padding: ".5em", height: "10vh", width: "100%", display: "grid", gridTemplateColumns: "15% 15% 15% 15% 15%", columnGap: "3%" }}>
                <Button onClick={() => this.setState({ vacationModal: true })} className="menuButton">
                  Planned Absences
                </Button>
                {this.props.user.permissions === "ALL" ? (
                  <Button onClick={() => this.startEditingUser()} className="menuButton">
                    User Information
                  </Button>
                ) : null}
                  <Button onClick={() => this.setState({ offenseModal: true })} className="menuButton">
                    Offenses History
                  </Button>            
                <Button onClick={() => this.setState({ indepthPTOModal: true })} style={{ display: "block" }} className="menuButton">
                  In-depth PTO
                </Button>
              </div>

              <div style={{ float: "left", width: "63vw" }}></div>
            </div>
          ) : (
            <>
              <div style={{ float: "left", width: "80%" }}>
                <div style={{ fontSize: "3vh", height: "auto", padding: ".5em", margin: ".5em", backgroundColor: "#efefef", borderRadius: ".5em" }}>
                  <h1 style={{ fontSize: "8vh" }}>{"Select a User"}</h1>
                </div>
              </div>
            </>
          )}
          <DataTable ref={(e) => (this.dt = e)} sortField="first_name" sortOrder={1} filters={{ nameAndPin: { value: null, matchMode: FilterMatchMode.CONTAINS } }} filterDisplay="row" scrollable scrollHeight="flex" style={{ width: "20%", float: "left" }} selection={this.state.data} value={this.state.users} editMode="cell" selectionMode="single" onSelectionChange={(e) => this.getEmployeeCardInfo(e.value)}>
            <Column
              filter
              filterMatchMode="custom"
              filterField="nameAndPin"
              footer={
                this.props.user.permissions === "ALL" ? (
                  <div id="ColumnFooter" style={{ backgroundColor: "#e6e6e6", textAlign: "center", fontSize: "2vw" }} onClick={() => this.callCreateShift()}>
                    Create User
                  </div>
                ) : (
                  <></>
                )
              }
              showFilterMenu={false}
              filterHeaderStyle={{ textAlign: "center", padding: ".5em" }}
              className="primereactfix-center-column-header primereactfix-squished-table"
              header={<Dropdown className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "1em" }} value={this.state.user} placeholder={"N/A"} options={this.state.userDropdown} onChange={(e) => this.setState({ user: e.value }, () => this.refreshData())} optionLabel="name" />}
              headerStyle={{ fontSize: "2vw", textAlign: "center", padding: ".12em", backgroundColor: "#e6e6e6" }}
              style={{ fontSize: "1.5vw", textAlign: "left", display: "block", backgroundColor: "#f2f2f2" }}
              body={(rowData) => {
                return <span style={{ width: "100%" }}>{rowData.first_name + " " + rowData.last_name}</span>;
              }}
            />
          </DataTable>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(Admin);
