import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { getRemainingAccrue, getAccrualRate } from "../global_functions/accural";
class offenseModal extends React.Component {
  //key pad code
  constructor(props) {
    super(props);
    this.state = {
      insertOffensesModal: false,
      totalPoints: 0,
      offensesData: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async insertOffenses() {
    console.log(this.state.date, this.state.amount, 
      //this.state.comment
      );
    if (this.state.date === null || this.state.date === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Please select the date", life: 3000 });
      return;
    }
    if (this.state.amount === null || this.state.amount === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Please enter the amount", life: 3000 });
      return;
    }
    if (isNaN(this.state.amount) && Number(this.state.amount) !== 0) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "The amount is not a correct number", life: 3000 });
      return;
    }
    axios.post(address + "/insertOffenses", { admin_id: this.props.user.id, user_id: this.props.data.ID, date: this.state.date, amount: this.state.amount, 
      //comment: this.state.comment || null 
    }).then(async (res) => {
      console.log(res.data);
      this.getData();
      this.setState({ insertOffensesModal: false, date: null, amount: null, 
        //comment: null 
      });
    });
  }

  getData() {
    axios
      .post(address + "/AttendanceReport", {
        userId: this.props.data.ID,
        start_time: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)).toISOString(),
        end_time: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0)).toISOString(),
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({ totalPoints: data.total, offensesData: data.offensesData });
      });
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Dialog draggable={false} dismissableMask visible={this.state.insertOffensesModal} onHide={() => this.setState({ insertOffensesModal: false })} modal={true} style={{ borderRadius: "1em", width: "30vw" }} contentStyle={{ padding: "0em", overflow: "hidden", borderRadius: "1em", justifyContent: "center" }} showHeader={false}>
          <ModalHeader title="Insert Offenses" />
          <div style={{ display: "grid", gridTemplateColumns: "12.5vw 12.5vw ", columnGap: "1vw", marginLeft: "2vw" }}>
            <h3 style={{ width: "100%", textAlign: "center" }}>1. Date</h3>
            <h3 style={{ width: "100%", textAlign: "center" }}>2. Amount</h3>
            <Calendar
              placeholder={"Required"}
              value={this.state.date}
              onChange={(e) => {
                this.setState({ date: e.value });
              }}
            />
            <InputText keyfilter="num" value={this.state.amount} style={{ fontSize: "2em", width: "95%" }} placeholder={"Required"} onChange={(e) => this.setState({ amount: e.target.value })} className="p-d-block" />
          </div>
          {/*<h3 style={{ width: "100%", textAlign: "center" }}>3.Comment</h3>
          <InputText value={this.state.comment} style={{ fontSize: "2em", width: "80%", marginLeft: "10%" }} placeholder={"Optional"} onChange={(e) => this.setState({ comment: e.target.value })} className="p-d-block" />*/}
            <div style={{ display: "grid", gridTemplateColumns: "auto auto", columnGap: "1vw", marginTop: "3vh" }}>
            <Button label="Back" className="createShiftButton" onClick={() => this.setState({ insertOffensesModal: false })} />
            <Button label="Create" className="createShiftButton" onClick={() => this.insertOffenses()} />
            </div>
        </Dialog>

        {/*<Dialog resizable={false} draggable={false} dismissableMask visible={this.state.commentVisible} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ commentVisible: false, showComment: null })} contentStyle={{ width: "auto", height: "auto", padding: 0, overflow: "hidden", borderRadius: ".8em" }} showHeader={false}>
          <ModalHeader title="Comment" height="12vh" titleFontSize="6vh" />
          <InputTextarea disable resizable={false} style={{ width: "100%", height: "100%" }} rows={10} cols={60} value={this.state.showComment} />
            </Dialog>*/}

        <ModalHeader title="Offenses History" />
        <div style={{ float: "left" }}>
          <div className="ColumnHeaderFooter ColumnHeader">{"Total Offenses: " + this.state.totalPoints}</div>
          <div style={{ height: "60vh", backgroundColor: "white" }}>
            <DataTable editMode="row" scrollable scrollHeight="60vh" style={{ width: "55vw", height: "60vh", overflow: "hidden", overflowY: "scroll" }} editingRows={this.state.editingRows} value={this.state.offensesData} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onRowEditComplete}>
              <Column field="date_applied" sortable bodyStyle={{ textAlign: "center" }} header="Date Applied" alignHeader="center" body={(rowData) => (rowData.date_applied ? new Date(rowData.date_applied).toLocaleDateString() : "")} />
              <Column field="attendance" sortable bodyStyle={{ textAlign: "center" }} header="Attendance Code" alignHeader="center" />
              <Column field="amount" bodyStyle={{ textAlign: "center" }} header="Amount" alignHeader="center" body={(rowData) => Number.parseFloat(rowData.amount).toFixed(2)} />
              <Column field="username" bodyStyle={{ textAlign: "center" }} header="Granted By" alignHeader="center" body={(rowData) => (rowData.username === null || rowData.username === undefined ? "System" : rowData.username)} />
              {/*<Column
                header="Comment"
                align="center"
                style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0 }}
                body={(rowData, b) => {
                  return rowData.comment === null || rowData.comment === undefined || rowData.comment === "" ? null : <i className="pi pi-comment p-text-secondary p-overlay-badge" style={{ fontSize: "1.5vw" }} onClick={() => this.setState({ commentVisible: true, showComment: rowData.comment })}></i>;
                }}
              ></Column>*/}
            </DataTable>
          </div>
          {this.props.user.secureUserHierarchy >= 4 ? (
            <div className="ColumnHeaderFooter ColumnFooter" onClick={() => this.setState({ insertOffensesModal: true })}>
              Insert Offenses
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default offenseModal;
