import React from "react";
import "./terminal.styles.css";
import axios from "axios";
import address from "../address.component";
import WifiIcon from "../wifi_icon/wifi_icon.component";
import logo from "../../assets/icons/Haven_logo_Final_White-01.svg";
import PTORequests from "./PTOrequests.component.jsx";
import Hour from "./Hours.component.jsx";
import beep from "../../assets/beep.mp3";
import { getAccrualRate } from "../global_functions/accural";
import { TIMETRACK_AUTH_LOGIN } from "../../redux2/types";
import waringBeep from "../../assets/beep-warning-6387.mp3";
import { Dialog } from "primereact/dialog";
import { store } from "../../redux2/store";
import { connect } from "react-redux";
let audio = new Audio(beep);
let waringAudio = new Audio(waringBeep);

function mapStateToProps(state) {
  return {
    authPin: state.AuthForLogin,
  };
}
function updateAuth(pin) {
  store.dispatch({
    AuthForLogin: pin,
    type: TIMETRACK_AUTH_LOGIN,
  });
}
class KeyPad extends React.Component {
  //key pad code
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      previousSetupID: 0,
      time: new Date(),
      errmsg: "",
      login: false,
      CurrentUser: {},
      online: navigator.onLine,
      requestPage: "loginPage",
      attendanceWaringModal: false,
      authDisplay: "Enter the PIN to access login",
      //hardcode for now, maybe get from database future
      dbPin: 5743298,
      scheduleInfo: [],
    };
  }

  addDigit(digit) {
    // 20 is the max characters with the current CSS
    // literally adds string to string

    let string = this.state.input;
    if (string.length < 10) {
      string += digit;
      this.playSound();
    }
    this.setState({ input: string });
  }

  playWaringSound() {
    waringAudio.play();
  }

  playSound() {
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  removeDigit() {
    // literally just slices last character in a string off
    let string = this.state.input.slice(0, this.state.input.length - 1);
    this.playSound();

    this.setState({ input: string });
  }

  generateString() {
    // displays the string, replaces all but the last character with "*"
    let string = "";
    for (let i = 0; i < this.state.input.length; i++) {
      if (i === this.state.input.length - 1) {
        string += this.state.input[i];
      } else {
        string += "*";
      }
    }
    return string;
  }

  submitString() {
    clearTimeout(this.timeout);
    //todo please rewrite this function! it looks bad, and can be handled pretty easily!
    this.playSound();
    if (this.props.authPin === this.state.dbPin) {
      // call authentication api
      axios
        .post(address + "/PINSubmit", {
          PIN: this.state.input,
          division: this.props.div,
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data.errmsg) {
            // failure!
            this.setState({ errmsg: data.errmsg });
            this.timeout = setTimeout(() => this.setState({ errmsg: "" }), 5000);
            this.setState({ input: "" });
          } else {
            // victory!
            this.setState({
              errmsg: data.user.first_name + " " + data.user.last_name,
              userId: data.user.ID,
            });
            this.setState({ input: "" });
            this.setState({ login: true });
            this.setState({ CurrentUser: data.user });
          }
        })
        .catch((err) => {
          let matchingUser = this.state.shortUsers.findIndex((obj) => obj.pin === this.state.input);
          console.log(matchingUser);
          if (matchingUser === -1) {
            //todo error message invalid pin
            return;
          }
          let user = JSON.parse(JSON.stringify(this.state.shortUsers[matchingUser]));
          console.log(this.state.shortUsers);
          user.start_time = user.start_time;
          user.end_time = user.end_time;
          if (user.midshift === 0) {
            user.start_time = new Date().toISOString();
            user.end_time = null;
            user.create = "create";
          } else {
            //* start_time is passed in through the API, not consistently obviously. only when midshift === 1
            user.end_time = new Date().toISOString();
          }
          console.log(user, matchingUser);
          this.setState({
            errmsg: user.first_name + " " + user.last_name,
          });
          this.setState({ input: "" });
          this.setState({ login: true });
          this.setState({ CurrentUser: user });
        });
    } else {
      if (Number(this.state.input) === this.state.dbPin) {
        this.setState({ authDisplay: "Access Successful" });
        setTimeout(() => updateAuth(this.state.dbPin), 2000);
      } else {
        this.setState({ authDisplay: "Wrong Access Code" });
        setTimeout(() => this.setState({ authDisplay: "Enter the PIN to access login" }), 2000);
      }
      this.setState({ input: "" });
    }
  }

  yos(data) {
    //NOTE This just calculates Year of Service!
    let newDate = new Date(data.date_hired === null ? data.intern_hire_date : data.date_hired);
    newDate = newDate.setFullYear(newDate.getFullYear() + 1);
    newDate = new Date(newDate).setMonth(11);
    newDate = new Date(new Date(newDate).setDate(31));
    let yos = 0;
    while (new Date() > newDate) {
      yos += 1;
      newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
    }
    return yos;
  }

  getAccrualRate() {
    if (this.state.CurrentUser.date_hired === undefined) {
      //off line data don't have date_hired
      return null;
    }
    let yos = this.yos(this.state.CurrentUser);
    console.log(yos);
    let rate;
    if (yos < 2) {
      rate = 10 / 12;
    } else if (yos < 5) {
      rate = 15 / 12;
    } else if (yos < 20) {
      rate = 20 / 12;
    } else if (yos >= 20) {
      rate = 25 / 12;
    } else {
      rate = 0;
    }
    console.log(rate);
    return this.state.CurrentUser.accrual_rate || rate.toFixed(3);
  }
  getScheduleInfo() {
    axios
      .post(address + "/getSchedule", {
        division: this.props.div,
      })
      .then((res) => {
        const data = res.data;
        let scheduleInfo = data.filter((ele) => ele.SubDivision === "Normal");
        console.log(scheduleInfo);
        this.setState({ scheduleInfo: scheduleInfo });
        localStorage.setItem("scheduleInfo", JSON.stringify(scheduleInfo));
      })
      .catch((err) => {
        //offLine
        this.setState({
          scheduleInfo: JSON.parse(localStorage.getItem("scheduleInfo") || "[]"),
        });
      });
  }
  getShortUsersToCache() {
    console.log(navigator.onLine);
    axios
      .get(address + "/getShortUsers")
      .then((res) => {
        const data = res.data;
        this.setState({ shortUsers: data });
        localStorage.setItem("shortUsers", JSON.stringify(data));
      })
      .catch((err) => {
        // navigator lied to me!!!!!!!!
        this.setState({
          shortUsers: JSON.parse(localStorage.getItem("shortUsers")),
        });
      });
  }

  async updateShortUser(user_id, field, value) {
    let shortUsers = JSON.parse(localStorage.getItem("shortUsers"));
    let userIndex = shortUsers.findIndex((obj) => obj.ID === user_id);
    if (userIndex === -1) {
      console.log("no user was found in short users");
      return "error could not find user";
    }
    if (field !== "ALL") {
      shortUsers[userIndex][field] = value;
    } else {
      // just here incase if I get lazy and just copy the whole thing, but it should just be field by field.
      shortUsers[userIndex] = value;
    }
    console.log(shortUsers, "ha shorty");
    localStorage.setItem("shortUsers", JSON.stringify(shortUsers));
    this.setState({ shortUsers: shortUsers });
  }

  formatTime() {
    let month = this.state.time.getMonth();
    let date = this.state.time.getDate();
    let hours = this.state.time.getHours() > 12 ? this.state.time.getHours() - 12 : this.state.time.getHours();
    let minutes = this.state.time.getMinutes();
    let day = this.state.time.getDay();
    switch (day) {
      case 0:
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        break;
      case 2:
        day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
    }
    date = date < 10 ? "0" + date : date;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let ampm = this.state.time.getHours() >= 12 ? "PM" : "AM";
    switch (month) {
      case 0:
        month = "Jan";
        break;
      case 1:
        month = "Feb";
        break;
      case 2:
        month = "March";
        break;
      case 3:
        month = "April";
        break;
      case 4:
        month = "May";
        break;
      case 5:
        month = "June";
        break;
      case 6:
        month = "July";
        break;
      case 7:
        month = "Aug";
        break;
      case 8:
        month = "Sep";
        break;
      case 9:
        month = "Oct";
        break;
      case 10:
        month = "Nov";
        break;
      case 11:
        month = "Dec";
        break;
    }

    return day + ", " + month + "-" + date + " " + hours + ":" + minutes + " " + ampm;
  }

  componentDidMount() {
    console.log("hello?");
    this.getShortUsersToCache();
    this.timer = setInterval(() => this.setState({ time: new Date() }), 1000);
    this.getScheduleInfo();
  }

  async emptyOfflineCalls() {
    if (navigator.onLine === true) {
      let CallsToMake = JSON.parse(localStorage.getItem("ThingsToCall"));
      if (CallsToMake !== undefined && CallsToMake !== null) {
        console.log(CallsToMake);
        for (let i = 0; i < CallsToMake.length; i++) {
          await this.createOfflineShift(CallsToMake[i], JSON.stringify(i));
        }
        // todo promise all.
        //TODO CHANGE THE SYSTEM TO ACCOUNT FOR IF SOME REASON YOU ARE ONLINE BUT API DOESNT WORK IT DOESNT JUST VOID ALL OF THE CALLS!
        localStorage.setItem("ThingsToCall", JSON.stringify([]));
      }
    }
  }

  async createOfflineShift(user, index) {
    // just a fancy verison that doesnt take any output, just sends it out.
    return await axios
      .post(address + "/ConfirmLogin", {
        user: user,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
      })
      .catch((err) => {
        return index;
      });
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.state.online !== navigator.onLine) {
      console.log("can tell its changed", this.state.online, navigator.onLine, prevProps);
      this.setState({ online: navigator.onLine }, () => this.emptyOfflineCalls());
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  confirmLogin() {
    this.playSound();
    // todo this can also be cleaned up!
    clearTimeout(this.timeout);
    axios
      .post(address + "/ConfirmLogin", {
        user: this.state.CurrentUser,
      })
      .then(async (res) => {
        const data = res.data;
        console.log(this.state.CurrentUser);
        if (data.msg) {
          this.setState({
            errmsg: this.state.CurrentUser.first_name + " " + this.state.CurrentUser.last_name + (this.state.CurrentUser.midshift === 0 ? " signed in" : " signed out"),
            login: false,
          });
          let user = JSON.parse(JSON.stringify(this.state.CurrentUser));
          if (user.midshift === 1) {
            await this.checkAttendance();
          }
          user.midshift = user.midshift === 0 ? 1 : 0;
          this.updateShortUser(user.ID, "ALL", user);
          this.timeout = setTimeout(() => this.setState({ errmsg: "" }), 2000);
        } else if (data.errmsg) {
          this.setState({ errmsg: data.errmsg });
        }
      })
      .catch((err) => {
        console.log(this.state.CurrentUser);
        let user = JSON.parse(JSON.stringify(this.state.CurrentUser));
        //add the this to help debug the offLine mode error
        let userToPush = this.state.CurrentUser;
        userToPush.offlineMode = "hi, I am from OfflineMode, it will show in log";
        let CurrentDataToSend = JSON.parse(localStorage.getItem("ThingsToCall")) || [];
        console.log(CurrentDataToSend);
        if (user.midshift === 1) {
          let index = CurrentDataToSend.reverse().findIndex((obj) => obj.midshift === 0 && obj.end_time === null && obj.pin === user.pin);
          console.log(index);
          if (index !== -1) {
            CurrentDataToSend[CurrentDataToSend.length - 1 - index].end_time = user.end_time;
          } else {
            CurrentDataToSend.push(userToPush);
          }
        } else {
          CurrentDataToSend.push(userToPush);
        }

        localStorage.setItem("ThingsToCall", JSON.stringify(CurrentDataToSend));
        console.log(CurrentDataToSend);
        user.midshift = user.midshift === 0 ? 1 : 0;
        this.updateShortUser(user.ID, "ALL", user);

        this.setState({
          errmsg: this.state.CurrentUser.first_name + " " + this.state.CurrentUser.last_name + (this.state.CurrentUser.midshift === 0 ? " signed in" : " signed out"),
          login: false,
        });
        this.timeout = setTimeout(() => this.setState({ errmsg: "" }), 2000);
      });
  }
  async checkAttendance() {
    if (this.state.CurrentUser?.department !== "TOOLING" && this.state.CurrentUser?.department !== undefined) {
      //not the tooling department and offline mode
      axios
        .post(address + "/AttendanceReport", {
          userId: this.state.CurrentUser.ID,
          start_time: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString(),
          end_time: new Date().toISOString(),
        }) //it should check the day before yesterday to today
        .then((res) => {
          const data = res.data;

          if (data.total >= 1) {
            axios
              .post(address + "/AttendanceReport", {
                userId: this.state.CurrentUser.ID,
                start_time: new Date(new Date(new Date().setDate(new Date().getDate() - 365)).setHours(0, 0, 0, 0)).toISOString(),
                end_time: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0)).toISOString(),
              })
              .then((res) => {
                const data = res.data;

                if (data.total >= 5) {
                  this.attendanceWaring();
                }
              });
          }
        });
    }
  }

  attendanceWaring() {
    this.setState({ attendanceWaringModal: true }, () => {
      this.playWaringSound();
      setTimeout(() => this.setState({ attendanceWaringModal: false }), 5000);
    });
  }

  ShiftNumCalculator() {
    let shift = 0;
    if (this.state.scheduleInfo.length > 0) {
      let scheduleToday = this.state.scheduleInfo.filter((ele) => Number(ele.Day) === new Date().getDay());
      shift = scheduleToday.reduce(function (prev, current) {
        return Math.min(Math.abs(new Date(prev.Shift_Start) - new Date()), Math.abs(new Date(new Date(prev.Shift_Start).setDate(new Date(prev.Shift_Start).getDate() + 1)) - new Date())) < Math.min(Math.abs(new Date(current.Shift_Start) - new Date()), Math.abs(new Date(new Date(current.Shift_Start).setDate(new Date(current.Shift_Start).getDate() + 1)) - new Date())) ? prev : current;
      }).Shift;
    } else {
      if (this.state.time.getUTCHours() < 12 && this.state.time.getUTCHours() > 6) {
        shift = 3;
      } else if (this.state.time.getUTCHours() >= 12 && this.state.time.getUTCHours() < 20) {
        shift = 1;
      }
    }
    return shift;
  }

  roundTime(dateString) {
    let date = new Date(dateString);
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes() + date.getUTCSeconds() / 60;
    if (minutes <= 7.5) {
      minutes = 0;
    } else if (minutes > 7.5 && minutes <= 22.5) {
      minutes = 15;
    } else if (minutes > 22.5 && minutes <= 37.5) {
      minutes = 30;
    } else if (minutes > 37.5 && minutes <= 52.5) {
      minutes = 45;
    } else if (minutes > 52.5 && minutes <= 60) {
      minutes = 0;
      hours += 1;
    }
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setSeconds(0);
    return date;
  }

  shiftLength() {
    let start = this.roundTime(this.state.CurrentUser.start_time);
    let end = this.roundTime(this.state.CurrentUser.end_time);
    console.log(start, end, this.state.CurrentUser);
    let differenceInMS = end.getTime() - start.getTime();
    let hours = Math.floor(differenceInMS / 1000 / 60 / 60);
    let minutes = (differenceInMS - hours * 1000 * 60 * 60) / 1000 / 60;
    if (minutes <= 7.5) {
      minutes = 0;
    } else if (minutes > 7.5 && minutes <= 22.5) {
      minutes = 15;
    } else if (minutes > 22.5 && minutes <= 37.5) {
      minutes = 30;
    } else if (minutes > 37.5 && minutes <= 52.5) {
      minutes = 45;
    } else if (minutes > 52.5 && minutes <= 60) {
      minutes = 0;
      hours += 1;
    }
    let string = "";
    if (hours !== 0) {
      string += hours + " Hour";
      if (hours > 1) {
        string += "s ";
      } else {
        string += " ";
      }
    }
    string += minutes + " Minute";
    if (minutes > 1 || minutes === 0) {
      string += "s ";
    } else {
      string += " ";
    }
    return "You have worked " + string;
  }

  changePage() {
    if (this.state.requestPage === "mainPage") {
      this.setState({ requestPage: "loginPage" });
    } else if (this.state.requestPage === "ptoPage" || this.state.requestPage === "hourPage") {
      this.setState({ requestPage: "mainPage" });
    }
  }
  render() {
    return (
      <>
        {" "}
        <WifiIcon />
        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.attendanceWaringModal} modal={true} style={{ borderRadius: ".8em", width: "80%", height: "22%" }} onHide={() => this.setState({ visible: false })} contentStyle={{ width: "auto", height: "auto", fontSize: "3vw", fontWeight: "bold", color: "red", paddingTop: "auto", paddingLeft: "10%", paddingRight: "10%", overflow: "hidden", borderRadius: ".8em" }} showHeader={false}>
          WARNING YOU ARE OVER THE TOLERATED AMOUNT OF ATTENDANCE OCCURRENCES!!
        </Dialog>
        {this.state.requestPage !== "loginPage" ? (
          <div style={{ height: "100%", width: "100%" }}>
            <div
              style={{
                backgroundColor: this.props.div === "HAVEN" ? "gray" : "black",
                padding: "0vh",
                height: "auto",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                class="fa fa-arrow-left"
                aria-hidden="true"
                style={{
                  fontSize: "4vw",
                  color: "white",
                  position: "absolute",
                  left: "2vw",
                }}
                onClick={() => this.changePage()}
              ></i>
              <img
                alt=""
                src={logo}
                height="auto"
                width="30%"
                className="d-inline-block align-top"
                style={{
                  backgroundColor: this.props.div === "HAVEN" ? "gray" : "black",
                  padding: "0vh",
                }}
              />
            </div>
            <div style={{ backgroundColor: "red", width: "100%", height: "0.6vh" }} />

            {this.state.requestPage === "mainPage" ? (
              <div
                id="YesNoGrid"
                style={{
                  marginLeft: "27vw",
                  marginTop: "15vh",
                  rowGap: "15vh",
                }}
              >
                <div className={"KeyPadBlock"} onClick={() => this.setState({ requestPage: "ptoPage" })}>
                  PTO
                </div>
                <div className={"KeyPadBlock"} onClick={() => this.setState({ requestPage: "hourPage" })}>
                  HOUR
                </div>
              </div>
            ) : this.state.requestPage === "ptoPage" ? (
              <PTORequests keypadCallback={this.callback} userId={this.state.userId} accrual_rate={getAccrualRate(this.state.CurrentUser.date_hired, this.state.CurrentUser.intern_hire_date, this.state.CurrentUser.accrual_rate)} userHireDate={this.state.CurrentUser.date_hired} operator={true} />
            ) : this.state.requestPage === "hourPage" ? (
              <Hour keypadCallback={this.callback} currentUser={this.state.CurrentUser} div={this.props.div} />
            ) : null}
          </div>
        ) : (
          <React.Fragment>
            <div id="KeypadLeft">
              <div
                style={{
                  backgroundColor: "gray",
                  padding: "0vh",
                  height: "120px",
                  color: "white",
                }}
              >
                <img
                  alt=""
                  src={logo}
                  height="120"
                  width="100%"
                  className="d-inline-block align-top"
                  style={{
                    backgroundColor: this.props.div === "HAVEN" ? "gray" : "black",
                    padding: "0vh",
                  }}
                />
              </div>

              {this.props.authPin === this.state.dbPin ? (
                <React.Fragment>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      columnGap: "2vw",
                      clear: "right",
                    }}
                  >
                    <h1>Shift {this.ShiftNumCalculator()} </h1>
                    <h1>{this.formatTime()}</h1>
                  </div>
                  <h1
                    style={{
                      fontSize: !this.state.login ? "3.5vw" : "3vw",
                      justifyContent: "center",
                    }}
                  >
                    {!this.state.login ? this.generateString() : this.state.CurrentUser.midshift === 0 ? "\xA0" : this.shiftLength()}
                  </h1>

                  <h1 style={{ fontSize: "3.5vw" }}>
                    {this.props.errmsg}
                    {this.state.errmsg}
                  </h1>
                  <div id="YesNoGrid" style={{ display: this.state.login ? "" : "none" }}>
                    <div className="KeyPadBlock" onClick={() => this.confirmLogin()}>
                      {this.state.CurrentUser.first_name !== null ? (this.state.CurrentUser.midshift === 0 ? "Start Shift" : "End Shift") : "\xA0"}
                    </div>
                    {navigator.onLine === false ? null : (
                      <div className={"KeyPadBlock"} onClick={() => this.setState({ requestPage: "mainPage" })}>
                        Requests
                      </div>
                    )}
                    <div
                      className="KeyPadBlock"
                      onClick={() =>
                        this.setState({ login: false, errmsg: "" }, () => {
                          this.playSound();
                        })
                      }
                    >
                      Cancel
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={"KeyPadBlock"}>{this.state.authDisplay}</div>
                  <h1
                    style={{
                      fontSize: !this.state.login ? "3.5vw" : "3vw",
                      justifyContent: "center",
                    }}
                  >
                    {!this.state.login ? this.generateString() : this.state.CurrentUser.midshift === 0 ? "\xA0" : this.shiftLength()}
                  </h1>
                </React.Fragment>
              )}
            </div>

            <div id="KeypadGrid">
              <div className="KeyPadBlock" onClick={() => this.addDigit(1)}>
                1
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(2)}>
                2
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(3)}>
                3
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(4)}>
                4
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(5)}>
                5
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(6)}>
                6
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(7)}>
                7
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(8)}>
                8
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(9)}>
                9
              </div>
              <div className="KeyPadBlock" onClick={() => this.removeDigit()}>
                🡨
              </div>
              <div className="KeyPadBlock" onClick={() => this.addDigit(0)}>
                0
              </div>
              <div className="KeyPadBlock" onClick={() => this.submitString()}>
                ✓
              </div>
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}

class Boxes extends React.Component {
  // top class,

  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      keypad: true,
      userId: 0,
    };
  }

  componentDidMount() {}

  callback = (userId) => {
    console.log(this, userId);
    if (userId !== undefined) {
      this.setState({ userId: userId, keypad: false });
    } else {
      this.setState({ keypad: true });
    }
  };

  render() {
    if (this.state.keypad === true) {
      return (
        <>
          <KeyPad keypadCallback={this.callback} div={this.props.div} />
        </>
      );
    } else {
      return (
        <>
          <PTORequests keypadCallback={this.callback} userId={this.state.userId} />
        </>
      );
    }
  }
}

export default connect(mapStateToProps)(KeyPad);
