import React from "react";
import axios from "axios";
import address from "../address.component";
import CommentSection from "./input.component.jsx";
import { FileUpload } from "primereact/fileupload";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";

class PictureComment extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      viewComment: false,
      comment: "",
      file: null,
    };
  }

  closeComment() {
    this.setState({ viewComment: false });
  }

  componentDidMount() {
    this.getPictureComment();
  }

  getPictureComment() {
    axios.get(address + "/PictureComment", { params: { userId: this.props.userId } }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ comment: data.comment, file: data.picture });
    });
  }

  updatePictureComment() {
    axios.post(address + "/PictureComment", { userId: this.props.userId, comment: this.state.comment, picture: this.state.file }).then(async (res) => {});
  }

  headerTemplate(options) {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center", width: "auto", height: "100%" }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
      </div>
    );
  }

  itemTemplate = (file, props) => {
    return <img alt={file.name} role="presentation" src={this.state.file} />;
  };

  emptyTemplate() {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and Drop Image Here
        </span>
      </div>
    );
  }

  customBase64Uploader = async (e) => {
    console.log(e.files[0]);
    const file = e.files[0];
    // const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    this.getBase64(blob).then((result) => {
      console.log(result);
      this.setState({
        file: result,
      });
      this.updatePictureComment();
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };

  render() {
    return (
      <div className="col-12 md:col-4">
        <CommentSection visible={this.state.viewComment} onHide={() => this.setState({ viewComment: false })} value={this.state.comment} updateParent={(data) => this.setState({ comment: data }, this.updatePictureComment)} />
        <div style={{ float: "center", width: "100%", height: "40vh", marginBottom: "2%", border: "1px solid #000000" }}>
          <FileUpload ref={(e) => (this.uploadCopy = e)} style={{ display: "none" }} mode="basic" name="demo[]" accept="image/*" maxFileSize={200000000} onSelect={this.customBase64Uploader} auto chooseLabel="Browse" />

          {this.state.file === null ? (
            <div
              className="flex align-items-center flex-column"
              onClick={() => {
                this.uploadCopy.onSimpleUploaderClick();
              }}
              style={{ height: "100%" }}
            >
              <div style={{ height: "10%" }}></div>
              <i className="pi pi-image mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
              <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
                No picture found, click to add picture
              </span>
            </div>
          ) : (
            <img
              src={this.state.file}
              alt={"img not loaded"}
              style={{ width: "100%", height: "100%" }}
              onClick={() => {
                this.uploadCopy.onSimpleUploaderClick();
              }}
            />
          )}
        </div>
        <div style={{ float: "center", width: "100%", height: "5vh", marginBottom: "10%" }}>
          <span>
            <p style={{ fontSize: "1.5vw", display: "inline-block", marginLeft: "15%" }}>Comment:</p>
            <i className="pi pi-comment" style={{ fontSize: "1.4vw", display: "inline-block", marginLeft: "5%", marginTop: "5%" }} onClick={() => this.setState({ viewComment: true })}></i>
          </span>
        </div>
      </div>
    );
  }
}

export default PictureComment;
