import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import PTORequests from "../boxes/PTOrequests.component";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import CommentSection from "./input.component.jsx";
import { getAccrualRate } from "../global_functions/accural";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Admin extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      CreateShiftModal: false, // rename to plan day off
      absences: [],
      halffull: [
        { name: "Full Day", value: 8 },
        { name: "First Half", value: -4 },
        { name: "Second Half", value: 4 },
      ],
      WellnessVacation: [
        { name: "Maternity Leave", value: "FMLA" },
        { name: "Jury Duty", value: "JD" },
        { name: "Bereavement Leave", value: "FL" },
      ],
      BonusOrNormalPTO: [
        { name: "Normal PTO", value: "N" },
        { name: "Bonus PTO", value: "B" },
      ],
      newDate: [],
      disabledFirstDropdown: true,
      disabledSecondDropdown: true,
      disabledThird: true,
      WellnessOrVacation: null,
      HalfOrFull: null,
    };
    console.log(props);
  }

  componentDidMount() {
    this.getData();
  }

  async createPTO() {
    axios.post(address + "/insertPTO", { admin_id: this.props.user.id, user_id: this.props.data.ID, type: this.state.BorNPTO, amount: this.state.amount }).then(async (res) => {
      this.getData();
    });
  }

  async createShift() {
    console.log(this.state.WellnessVacation, this.state.newDate, this.props.data, this.state.HalfOrFull);
    axios.post(address + "/createVacation", { date: this.state.newDate, attendance: this.state.WellnessOrVacation, HalfOrFull: this.state.HalfOrFull, userId: this.props.data.ID, comment: this.state.comment, division: this.props.user.division }).then(async (res) => {
      const data = res.data;
      this.props.onHide();
      this.setState({ createShiftModal: false });
      console.log(data);
    });
  }

  yos(data) {
    //NOTE This just calculates Year of Service!
    let newDate = new Date(data.date_hired === null ? data.intern_hire_date : data.date_hired);
    newDate = newDate.setFullYear(newDate.getFullYear() + 1);
    newDate = new Date(newDate).setMonth(11);
    newDate = new Date(new Date(newDate).setDate(31));
    let yos = 0;
    while (new Date() > newDate) {
      yos += 1;
      newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
    }
    return yos;
  }

  getAccrualRate() {
    let yos = this.yos(this.props.data);
    console.log(yos);
    let rate;
    if (yos < 2) {
      rate = 10 / 12;
    } else if (yos < 5) {
      rate = 15 / 12;
    } else if (yos < 20) {
      rate = 20 / 12;
    } else if (yos >= 20) {
      rate = 25 / 12;
    } else {
      rate = 0;
    }
    return this.props?.data?.accrual_rate || rate.toFixed(3);
  }

  getData() {
    axios.post(address + "/getApprovedTimeOff", { user_id: this.props.data.ID || 0 }).then((res) => {
      const data = res.data;
      this.setState({ absences: data });
    });
    axios.get(address + "/getPTOReasons").then((res) => {
      const data = res.data;
      this.setState({ WellnessVacation: data });
    });
    axios.post(address + "/getIndepthPTO", { user_id: this.props.data.ID || 0 }).then((res) => {
      const data = res.data;
      this.setState({ timeoff: data.total, planned: data.planned });
    });
  }

  absencesCalendar = (date) => {
    for (let i = 0; i < this.state.absences.length; i++) {
      let absence = this.state.absences[i];
      if (absence.year === date.year) {
        if (absence.month === date.month) {
          if (absence.date === date.day) {
            //todo find index
            let index = this.state.newDate.findIndex((obj) => {
              let date = new Date(obj).getDate();
              let month = new Date(obj).getMonth();
              let year = new Date(obj).getFullYear();
              return date === absence.date && month === absence.month && year === absence.year;
            });
            let className = "dateDayMimic approved";
            if (index !== -1) {
              className += " outlineMimic";
            }
            return <div className={className}>{date.day}</div>;
          }
        }
      }
    }

    return (
      <div className="dateDayMimic" style={{}}>
        {date.day}
      </div>
    );
  };

  render() {
    return (
      <>
        <CommentSection visible={this.state.viewComment} onHide={() => this.setState({ viewComment: false })} dontUpdateShift={true} commentId={this.state.commentToUpdate} value={this.state.comment} updateParent={(data) => this.setState({ comment: data })} />

        <Toast ref={(el) => (this.toast = el)} />
        <ModalHeader title="Planned Absences" />
        <div style={{ overflow: "auto", width: "70vw", height: "65vh" }}>
          <PTORequests userId={this.props.data.ID} accrual_rate={getAccrualRate(this.props.data.date_hired, this.props.data.intern_hire_date, this.props.data.accrual_rate)} userHireDate={this.props.data.date_hired} operator={false} />
        </div>
        <Dialog draggable={false} dismissableMask visible={this.state.insertPTOModal} onHide={() => this.setState({ insertPTOModal: false })} modal={true} style={{ borderRadius: "1em", width: "30vw" }} contentStyle={{ padding: "0em", overflow: "hidden", borderRadius: "1em", justifyContent: "center" }} showHeader={false}>
          <ModalHeader title="Insert PTO" />
          <div style={{ display: "grid", gridTemplateColumns: "12.5vw 12.5vw", columnGap: "1vw", marginLeft: "2vw" }}>
            <h3 style={{ width: "100%", textAlign: "center" }}>1. Type of PTO?</h3>
            <h3 style={{ width: "100%", textAlign: "center" }}>2. Amount</h3>
            <Dropdown className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "2em" }} value={this.state.BorNPTO} placeholder={"Required"} options={this.state.BonusOrNormalPTO} onChange={(e) => this.setState({ BorNPTO: e.value, disabledFirstDropdown: false }, () => console.log(e.value))} optionLabel="name" />
            <InputText disabled={this.state.disabledFirstDropdown} keyfilter="num" value={this.state.amount} style={{ fontSize: "2em", width: "95%" }} placeholder={"Required"} onChange={(e) => this.setState({ amount: e.target.value, disabledThird: false })} className="p-d-block" />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto", columnGap: "1vw", marginTop: "3vh" }}>
            <Button label="Back" className="createShiftButton" onClick={() => this.setState({ CreateShiftModal: false })} />
            <Button disabled={this.state.disabledThird} label="Create" className="createShiftButton" onClick={() => this.createPTO()} />
          </div>
        </Dialog>
        <Dialog>
          <ModalHeader title="PTO Management" />
          <div style={{ float: "left" }}>
            <div className="ColumnHeaderFooter ColumnHeader" onClick={() => this.setState({})}>
              Current PTO
            </div>

            <div style={{ height: "40vh", backgroundColor: "white" }}>
              <DataTable editMode="row" scrollHeight="flex" style={{ width: "30vw", height: "40vh", overflow: "hidden", overflowY: "scroll" }} editingRows={this.state.editingRows} value={this.state.timeoff} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onRowEditComplete}>
                <Column field="type" bodyStyle={{ textAlign: "center" }} header="Type" alignHeader="center" />
                <Column field="remaining" bodyStyle={{ textAlign: "center" }} header="Remaining" alignHeader="center" />
                <Column field="earned" bodyStyle={{ textAlign: "center" }} header="Date Earned" alignHeader="center" body={(rowData) => rowData.earned.slice(0, 10)} />
                <Column field="expires" bodyStyle={{ textAlign: "center" }} header="Expiry Date" alignHeader="center" body={(rowData) => rowData.expires.slice(0, 10)} />
                <Column field="username" bodyStyle={{ textAlign: "center" }} header="Granted By" alignHeader="center" body={(rowData) => (rowData.username === null ? "System" : rowData.username)} />
                <Column rowEditor className="PrimeReactFixTableScrollable" headerStyle={{ minWidth: "6rem" }} bodyStyle={{ textAlign: "center" }} />
              </DataTable>
            </div>

            <div className="ColumnHeaderFooter ColumnFooter" onClick={() => this.setState({ insertPTOModal: true })}>
              Insert Vacation/Wellness Time
            </div>
          </div>

          <div style={{ float: "left" }}>
            <div className="ColumnHeaderFooter ColumnHeader">Planned Time Off</div>
            <div style={{ height: "40vh", backgroundColor: "white" }}>
              <DataTable editMode="row" scrollHeight="flex" style={{ width: "20vw", height: "40vh", overflow: "hidden", overflowY: "scroll" }} editingRows={this.state.editingRows} value={this.state.planned} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onRowEditComplete}>
                <Column field="start_time" bodyStyle={{ textAlign: "center" }} header="Date" alignHeader="center" body={(rowData) => rowData.start_time.slice(0, 10)} />
                <Column field="attendance" bodyStyle={{ textAlign: "center" }} header="Type" alignHeader="center" />
              </DataTable>
            </div>

            <div className="ColumnHeaderFooter ColumnFooter" onClick={() => this.setState({ CreateShiftModal: true, disabledFirstDropdown: true, disabledSecondDropdown: true })}>
              Plan Time Off
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default connect(mapStateToProps)(Admin);
