import React from "react";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import LoadingScreen from "../loading/loading.component.jsx";
import calcTotalHours from "../global_functions/calcTotalHours.function";
import processDataByUser from "../global_functions/processDataByUser.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";
import ReportSetting from "../reportSetting/reportSetting.component";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      shifts: [],
      defaultDate: [new Date(), new Date()],
      date: [new Date(), new Date()],
      header: ["Date", "Act. In", "Adj. In", "Act. Out", "Adj. Out", "Hours", "Attendance", "Shift", "Emp Number", "Emp Name", "Department", "Worker Type"],
      breaks: [],
      newBreaks: [],
      payType: "both",
      workerType: "ALL",
    };
    this.renderHeader = (
      <ColumnGroup>
        <Row>
          <Column alignHeader="center" header="Date" rowSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: "2em" }} />
          <Column alignHeader="center" header="Clock In" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Clock Out" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Hours" rowSpan={2} headerStyle={{ textAlign: "center", width: "6vw", padding: 0 }} />
          <Column alignHeader="center" header="Attend Code" rowSpan={2} headerStyle={{ textAlign: "center", width: "5w", padding: 0 }} />
          <Column alignHeader="center" header="Shift" rowSpan={2} headerStyle={{ textAlign: "center", width: "3vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp #" rowSpan={2} headerStyle={{ textAlign: "center", width: "4vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp Name" rowSpan={2} headerStyle={{ textAlign: "center", width: "14vw", padding: 0 }} />
          <Column alignHeader="center" header="Department" rowSpan={2} colSpan={1} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Worker Type" rowSpan={2} colSpan={1} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
        </Row>
        <Row>
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
        </Row>
      </ColumnGroup>
    );
  }

  componentDidMount() {
    this.getAttendanceCodes();
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let headers = this.state.header;
    let data = this.state.shifts;
    for (let i = 0; i < headers.length; i++) {
      csv += headers[i] + ",";
    }
    csv += "\n";
    for (let i = 0; i < data.length; i++) {
      let s = data[i];
      csv += this.fixDate(s) + ",";
      csv += this.convertTimeToHours(s.start_time) + ",";
      csv += this.convertTimeToHours(s.adj_start_time) + ",";
      csv += this.convertTimeToHours(s.end_time) + ",";
      csv += this.convertTimeToHours(s.adj_end_time) + ",";
      csv += s.hours + ",";
      csv += s.attendance === null ? "DIRECT," : s.attendance.replace(/,/g, "/") + ",";
      csv += s.user.shift + ",";
      csv += s.user.pin + ",";
      csv += s.user.name + ",";
      csv += s.user.department + ",";
      csv += s.user.workerType;
      csv += "\n";
    }
    csv += "\n";
    this.download(csv, "dataExport");
  }

  convertTimeToHours(time) {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = "am";
    if (minutes < 10) {
      minutes = 0 + JSON.stringify(minutes);
    }
    if (hours > 12) {
      hours -= 12;
      ampm = "pm";
    }
    return hours + ":" + minutes + " " + ampm;
  }

  setDate(data) {
    this.setState({ date: data }, () => {
      if (data[1] !== null) {
        console.log("called :)");
        this.getReport(this.state.date[0].toISOString(), new Date(new Date(this.state.date[1]).setDate(this.state.date[1].getDate() + 1)).toISOString());
      }
    });
  }

  async getReport(startDate, endDate) {
    console.log("here!", endDate, startDate);
    this.setState({ loading: true });
    let userId = this.state.user === null || this.state.user === undefined ? null : this.state.user.pin;
    axios
      .post(address + "/createTimesheet", {
        start_time: startDate,
        end_time: endDate,
        division: this.props.user.division,
        department: "ALL",
        shift: "ALL",
        type: "both",
        user_id: userId,
        active: this.state.active,
        payType: this.state.payType,
        workerType: this.state.workerType,
      })
      .then(async (res) => {
        const data = res.data;
        console.log(data);
        const processedData = processDataByUser(data.dataByUser, data.breaks, data.newBreakInfo, startDate, endDate, this.state.attendanceCodes);
        this.setState(
          {
            newBreaks: data.newBreakInfo,
            breaks: data.breaks,
            shifts: processedData.data.filter((obj) => obj.display !== false),
          },
          () => this.setState({ visible: true, loading: false })
        );
        this.setState({ CreateShiftModal: false });
      });
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  runReport = async (data) => {
    await this.setState(data);
    this.togglePop();
    this.getReport(this.state.date[0], new Date(new Date(this.state.date[1]).setDate(this.state.date[1].getDate() + 1)));
  };

  fixDate(rowData) {
    const {start_time,adj_start_time,end_time,adj_end_time} = rowData
    let start = adj_start_time || start_time
    let end = adj_end_time || end_time
    if (end === null) {
      end = new Date(start).getTime() + 8*60*60*1000
    }
    return new Date(new Date(new Date(start).getTime() + (new Date(end) - new Date(start)) / 2).toLocaleString().split(",")[0]).toISOString().slice(0, 10)    
  }

  render() {
    return (
      <>
        <ReportSetting popSeen={this.state.popSeen} defaultDate={this.state.defaultDate} active="ALL" user workerType={this.state.workerType} payType={this.state.payType} date={this.state.date} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <LoadingScreen show={this.state.loading} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "20vw auto",
            marginLeft: "2.5vw",
            marginRight: "2.5vw",
            columnGap: "2.5vw",
          }}
        >
          <Button label="Generate Report" className="hideMe adminPageButtons red" style={{ width: "100%" }} onClick={this.togglePop} />

          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
        </div>
        <DataTable value={this.state.shifts} headerColumnGroup={this.renderHeader}>
          <Column field="date" style={{ textAlign: "center" }} 
          body={(rowData)=>{
            return this.fixDate(rowData)
          }}/>
          <Column
            bodyStyle={{ textAlign: "center" }}
            field="start_time"
            body={(rowData, row) => {
              return this.convertTimeToHours(rowData[row.field]);
            }}
          />
          <Column
            bodyStyle={{ textAlign: "center" }}
            field="adj_start_time"
            body={(rowData, row) => {
              return this.convertTimeToHours(rowData[row.field]);
            }}
          />
          <Column
            bodyStyle={{ textAlign: "center" }}
            field="end_time"
            body={(rowData, row) => {
              return this.convertTimeToHours(rowData[row.field]);
            }}
          />
          <Column
            bodyStyle={{ textAlign: "center" }}
            field="adj_end_time"
            body={(rowData, row) => {
              return this.convertTimeToHours(rowData[row.field]);
            }}
          />

          <Column
            bodyStyle={{ textAlign: "center" }}
            field="hours"
            body={(rowData) => {
              return rowData.hours;
              // return calcTotalHours({
              //   start: rowData.adj_start_time,
              //   end: rowData.adj_end_time,
              //   userInfo: rowData.user,
              //   breaks: this.state.breaks,
              //   shift: rowData.user.shift,
              //   attendance: rowData.attendance === null ? "DIRECT" : getHighestAttendance(rowData.attendance, this.state.attendanceCodes),
              //   approved: rowData.approved,
              //   shiftHour:rowData.hours,
              //    newBreaks:this.state.newBreaks,
              //    breakShift:rowData.break_shift
              // });
            }}
          />
          <Column
            field="attendance"
            body={(rowData, row) => {
              return rowData[row.field] === null ? "DIRECT" : rowData[row.field];
            }}
          />
          <Column field="user.shift" bodyStyle={{ textAlign: "center" }} />
          <Column field="user.pin" />
          <Column bodyStyle={{ textAlign: "center" }} field="user.name" />
          <Column bodyStyle={{ textAlign: "center" }} field={"user.department"} />
          <Column bodyStyle={{ textAlign: "center" }} field={"user.workerType"} />
        </DataTable>
      </>
    );
  }
}
export default connect(mapStateToProps)(Page);
