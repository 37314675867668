import React from "react";
import "./modalHeader.styles.css";
import logo from "../../assets/images/ModalHeader.jpg";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      height: "12vh",
      fontSize: "3vw",
    };
  }

  render() {
    return (
      <div style={{ width: "100%", height: this.props.height || this.state.height }}>
        <img id="ModalIMG" src={logo} alt={"img failed to load"} />
        <h1 id="ModalTitle" style={{ lineHeight: this.props.height || this.state.height, fontSize: this.props.titleFontSize || this.state.fontSize }}>
          {this.props.title}
        </h1>
        {this.props.showX === true ? (
          <span
            id="ModalButton"
            onClick={() => {
              if (this.props.hide) {
                this.props.hide();
              } else {
                console.log("no hide function given!");
              }
            }}
          >
            <i id="TopIcon" class="fa-solid fa-xmark"></i>
          </span>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Page;
