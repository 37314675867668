import React from "react";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import LoadingScreen from "../loading/loading.component.jsx";
import ReportSetting from "../reportSetting/reportSetting.component";
import "./timesheet.styles.css";
import { Button } from "primereact/button";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";

import calcTotalHours from "../global_functions/calcTotalHours.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";
import processDataByUser from "../global_functions/processDataByUser.function";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Page extends React.Component {
  // top class,

  constructor(props) {
    super(props);
    this.state = {
      defaultDate: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      date: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      shifts: [],
      dateHeader: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"],
      breaks: [],
      newBreaks: [],
      payType: "both",
      active: true,
      workerType: "ALL",
    };
    this.dumbPlaceholder = [{ name: "Not Finished Yet!", value: "NY" }];
    console.log(this.props);
  }

  componentDidMount() {
    this.getAttendanceCodes();
    axios.get(address + "/getUsers").then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ users: data });
    });
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  setDate(data) {
    if (data[1] !== null) {
      this.callCreateTimesheet(data);
    }
    this.setState({ date: data });
  }

  callCreateTimesheet(date) {
    console.log(date);
    if (date[1] !== null) {
      this.setState({ shifts: [], expandedRows: [], loading: true });
      console.log("CALL API");
      let startDate = date[0].toISOString().slice(0, 10);
      let endDate = new Date(new Date(date[1]).setDate(date[1].getDate() + 1)).toISOString().slice(0, 10);
      axios
        .post(address + "/createTimesheet", {
          start_time: startDate,
          end_time: endDate,
          division: this.props.user.division,
          department: "ALL",
          shift: "ALL",
          payType: this.state.payType,
          type: "both",
          user_id: null,
          active: this.state.active,
          workerType: this.state.workerType,
        })
        .then(async (res) => {
          const data = res.data;
          let processedData = await processDataByUser(data.dataByUser, data.breaks, data.newBreakInfo, startDate, endDate, this.state.attendanceCodes);
          let dataWithinFrame = processedData.data.filter((element) => element.display !== false);
          console.log(dataWithinFrame);
          this.setState({ shifts: dataWithinFrame, breaks: data.breaks, newBreaks: data.newBreakInfo, loading: false });
        });
    }
  }

  headerTemplate(rowData) {
    return <div style={{ display: "inline-block" }}>{rowData.user.name}</div>;
  }

  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let baseData = JSON.parse(JSON.stringify(this.state.shifts));
    let data = [];

    for (let i = 0; i < baseData.length; i++) {
      let approvedTime = baseData[i];
      approvedTime.attendance = approvedTime.attendance === null || approvedTime.attendance === undefined ? "DIRECT" : getHighestAttendance(approvedTime.attendance, this.state.attendanceCodes);
      //Paylocity has a different set of attendance codes.
      //TODO ADD THIS INTO THE SQL!!!! FOR FLEXIBILITY
      //TODO HANDLE OVERTIME PROPERLY!!! OVER 40 HOURS, INCLUDES WELLNESS TIME
      if (approvedTime.attendance === "VT") {
        approvedTime.attendance = "VAC";
      } else if (approvedTime.attendance === "OVERTIME" || approvedTime.attendance === "OTWT") {
        approvedTime.attendance = "OT";
      } else if (approvedTime.attendance === "JD") {
        approvedTime.attendance = "JURY";
      } else if (approvedTime.attendance === "FL") {
        approvedTime.attendance = "BRVMT";
      } else if (approvedTime.attendance === "L" || approvedTime.attendance === "DIRECT") {
        approvedTime.attendance = "REG";
      } else if (approvedTime.attendance === "APPTO" || approvedTime.attendance === "BOPTO" || approvedTime.attendance === "UNPTO") {
        approvedTime.attendance = "PTO";
      } else {
        // rather just skip it than cause an error...
        continue;
      }

      let index = data.findIndex((e) => e.user_id === approvedTime.user_id && e.attendance === approvedTime.attendance);
      if (index === -1) {
        approvedTime.hours = approvedTime.hours;
        //calcTotalHours({ start: approvedTime.adj_start_time, end: approvedTime.adj_end_time, userInfo: approvedTime.user, breaks: this.state.breaks, shift: approvedTime.user.shift, attendance: approvedTime.attendance, approved: approvedTime.approved, shiftHour:approvedTime.hours, newBreaks:this.state.newBreaks,breakShift:approvedTime.break_shift });
        data.push(approvedTime);
      } else {
        data[index].hours = Number(data[index].hours) + approvedTime.hours;
        //calcTotalHours({ start: approvedTime.adj_start_time, end: approvedTime.adj_end_time, userInfo: approvedTime.user, breaks: this.state.breaks, shift: approvedTime.user.shift, attendance: approvedTime.attendance, approved: approvedTime.approved, shiftHour:approvedTime.hours, newBreaks:this.state.newBreaks,breakShift:approvedTime.break_shift });
      }
    }

    for (let i = 0; i < data.length; i++) {
      let s = data[i];
      csv += s.user.pin + ",";
      csv += "E,";
      csv += s.attendance + ",";
      csv += s.hours + ",";
      csv += "\n";
    }
    if (data.length === 0) {
      console.log("BREAK OUT!!!!!!! NO DATA");
      return;
    }
    csv += "\n";
    this.download(csv, "paylocityExport");
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  runReport = async (data) => {
    await this.setState(data);
    this.togglePop();
    this.callCreateTimesheet(this.state.date);
  };

  render() {
    return (
      <>
        <LoadingScreen show={this.state.loading} />
        <ReportSetting popSeen={this.state.popSeen} active="ALL" workerType={this.state.workerType} payType={this.state.payType} date={this.state.date} defaultDate={this.state.defaultDate} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <div style={{ display: "grid", gridTemplateColumns: "15vw 12vw 8vw auto", columnGap: "3vw", marginLeft: "2.5vw", marginRight: "2.5vw", width: "100%", height: "5vh" }}>
          <Button label="Generate Report" className="hideMe adminPageButtons red" style={{ width: "100%" }} onClick={this.togglePop} />
          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
          <Button label="Expand All" className="hideMe adminPageButtons red" onClick={() => this.setState({ expandedRows: this.state.shifts })} />
          <Button label="Collapse All" className="hideMe adminPageButtons red" onClick={() => this.setState({ expandedRows: [] })} />
        </div>
        <DataTable style={{ width: "100%" }} value={this.state.shifts} rowGroupMode="subheader" groupRowsBy="user.name" expandableRowGroups rowGroupHeaderTemplate={(rowData) => this.headerTemplate(rowData)} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data }, () => console.log(e.data))}>
          <Column filter filterPlaceholder="Search by Name" header="Employee Name" field={"user.name"} style={{ paddingLeft: "3rem", width: "16.5vw" }} />
          <Column header="Employee #" field={"user.pin"} style={{ width: "13vw" }} />
          <Column header="Date" field="date" style={{ width: "16vw" }} />
          <Column
            header="Attend Code"
            field="attendance"
            body={(rowData) => {
              return rowData.attendance === null || rowData.attendance === undefined ? "DIRECT" : getHighestAttendance(rowData.attendance, this.state.attendanceCodes);
            }}
            style={{ width: "25vw" }}
          />
          <Column
            header="Hours"
            field="hours"
            body={(rowData) => {
              return rowData.hours;
              // return calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.state.breaks, shift: rowData.user.shift, attendance: rowData.attendance === null ? "DIRECT" : getHighestAttendance(rowData.attendance, this.state.attendanceCodes), approved: rowData.approved,shiftHour:rowData.hours, newBreaks:this.state.newBreaks,breakShift:rowData.break_shift });
            }}
            style={{ width: "10vw" }}
          />
        </DataTable>
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
