import { Button, Navbar } from "react-bootstrap";
// import logo from "../../assets/haven_steel_logo.png";
import logo from "../../assets/icons/Haven_logo_Final_White-01.svg";
import address from "../address.component";
import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { Avatar, Dropdown, Badge } from "antd";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import IndepthPTO from "../admin/indepthpto.component";
import CommentSection from "../admin/input.component.jsx";
import { store } from "../../redux2/store";
import { Dialog } from "primereact/dialog";
import { TIMETRACK_LOG_OUT, TIMETRACK_LOG_IN } from "../../redux2/types";
import { ScrollPanel } from "primereact/scrollpanel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Switch } from "antd";
import "./header.styles.css";
function updateStatus(userObject) {
  return new Promise((resolve) => {
    console.log(userObject);
    store
      .dispatch({
        TTUser: userObject,
        type: TIMETRACK_LOG_IN,
      })
      .then(resolve());
  });
}
function SignOut() {
  store.dispatch({
    type: TIMETRACK_LOG_OUT,
  });
}
function mapStateToProps(state) {
  console.log(state.TTUser);
  return {
    user: state.TTUser,
  };
}
function updateSearchSetting(newDivisionUser) {
  return new Promise((resolve) => {
    store
      .dispatch({
        TTUser: newDivisionUser,
        type: TIMETRACK_LOG_IN,
      })
      .then(resolve());
  });
}
class Header extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      allNotifications: [],
      notifications: [],
      count: 0,
      dialog: false,
      viewComment: false,
      comment: "",
      consumeType: "N",
      indepthPTOModal: false,
      PTOUser: { ID: 0 },
      PTOTypeModal: false,
      disableBonus: false,
      teamMembers: [],
      divisions: [
        { name: "Haven", value: "HAVEN" },
        { name: "Mckenzie", value: "MCKENZIE" },
      ],
      selectUser: null,
      selectUnread: true,
      status: this.props.user?.status === "ONLINE",
      changeStatusModal: false,
      transferMembers: [],
      selectTransferUser: null,
    };
  }

  componentDidMount() {
    this.callRequests();
    if (this.props.user !== undefined) {
      this.getTeamMembers();
      this.getTransferMembers();

      //todo check to see if user even exists or has not been disabled...
      this.checkLogin(this.props.user);

      this.getNotification(this.props.user);
    }
  }

  checkLogin(user) {
    console.log(user);
    axios
      .post(address + "/confirmUser", {
        user: this.props.user,
      })
      .then((res) => {
        const data = res.data;
        if (data.err) {
          console.log("system error", data.err);
        } else {
          if (data.msg === "safe") {
            //congrats! no need to do anything
          } else {
            //HEY LOG ME OUT THIS USER IS OUT OF DATE!!!
            SignOut();
          }
        }
      });
  }

  getTeamMembers() {
    axios
      .post(address + "/getTeamMembers", {
        userId: this.props.user?.id || 0,
        division: this.props.user?.division || "HAVEN",
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({
          teamMembers: data,
        });
      });
  }

  getTransferMembers() {
    axios
      .post(address + "/getTeamMembers", {
        userId: this.props.user?.id || 0,
        division: this.props.user?.division || "HAVEN",
        all: true,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({
          transferMembers: data,
        });
      });
  }

  getNotification(selectUser) {
    if (selectUser === undefined || selectUser === null) {
      selectUser = this.props.user;
      this.setState({ selectUser: null });
    }
    axios
      .get(address + "/Notification", {
        params: { userId: selectUser.id },
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (this.props.user.id === selectUser.id) {
          const count = data.filter((data) => {
            return data.readed === false;
          }).length;
          this.setState({
            count: count,
          });
        }
        let unreadNotification = data.filter((data) => data.readed === false || data.requestStatus === "pending");

        this.setState({
          allNotifications: data,
          notifications: unreadNotification,
        });
      });
  }

  callRequests() {
    axios
      .post(address + "/GetRequests", {
        supervisor_id: 0,
      })
      .then((res) => {
        const data = res.data;
        this.setState({
          requests: data,
        });
      });
  }

  onRowEditComplete = (index) => {
    let copy = JSON.parse(JSON.stringify(this.state.requests));
    copy.splice(index, 1);
    this.setState({ requests: copy, count: copy.length });
  };

  async openNotification(index) {
    if (this.state.selectUser === undefined || this.state.selectUser === null) {
      await this.setState({ selectUser: this.props.user });
    }
    if (this.props.user.id === this.state.selectUser.id) {
      let notificationIndex = this.state.allNotifications.findIndex((ele) => ele.ID === this.state.notifications[index].ID);
      let newNotifications = this.state.allNotifications;
      if (!newNotifications[notificationIndex].readed) {
        let notificationID = newNotifications[notificationIndex].ID;
        axios
          .patch(address + "/Notification", {
            ID: notificationID,
            readed: true,
          })
          .then();
        newNotifications[notificationIndex].readed = true;
        const count = newNotifications.filter((data) => {
          return data.readed === false;
        }).length;
        this.setState({
          allNotifications: newNotifications,
          count: count,
        });

        console.log(this.state.notifications);
      }
    }
  }

  async ptoRequest(decision, notification, type) {
    console.log(notification);
    if (notification.type === "PTO Request") {
      if (decision) {
        await axios
          .post(address + "/approvePTORequest", {
            notification,
            secureUser_id: this.props.user.id,
            comment: this.state.comment,
            type: type,
          })
          .then((res) => {});
      } else {
        await axios
          .post(address + "/rejectPTORequest", {
            notification,
            secureUser_id: this.props.user.id,
            comment: this.state.comment,
          })
          .then((res) => {});
      }
    } else if (notification.type === "HOUR Request") {
      await axios
        .post(address + "/decideHourRequest", {
          decision,
          notification,
          secureUser_id: this.props.user.id,
          comment: this.state.comment,
        })
        .then((res) => {});
    }
    let index = this.state.notifications.findIndex((obj) => {
      return obj.ID === notification.ID;
    });
    let indexOfAll = this.state.allNotifications.findIndex((obj) => {
      return obj.ID === notification.ID;
    });
    console.log(index, indexOfAll);
    if (index !== -1 && indexOfAll !== -1) {
      let newNotifications = this.state.notifications;
      newNotifications[index].requestStatus = null;
      let newAllNotifications = this.state.allNotifications;
      newAllNotifications[indexOfAll].requestStatus = null;
      this.setState({ allNotifications: newAllNotifications, notification: newNotifications });
    }
    this.setState({ comment: "" });
  }

  async getPTOInfo(notification) {
    await axios
      .post(address + "/getTerminalPTOData", {
        userId: notification.PTOUser_id,
      })
      .then((res) => {
        let data = res.data;
        console.log(data);
        let disableBonus = data.bonus < (notification.PTOHalfOrFull === "Full" ? 1 : 0.5);
        console.log(disableBonus);
        this.setState({ PTOTypeModal: true, selectNotification: notification, disableBonus: data.bonus < (notification.PTOHalfOrFull === "Full" ? 1 : 0.5) });
      });
  }
  onUserChange(e) {
    let selectUser = e.value === undefined || e.value === null ? this.props.user : e.value;
    this.setState({ selectUser: selectUser });
    this.getNotification(selectUser);
  }
  async onDivisionChange(e) {
    let user = this.props.user;
    user.division = e.value;
    SignOut();
    await updateSearchSetting(user);
    window.location.reload(false);
  }
  switchRead(unRead) {
    console.log(unRead);
    console.log(this.state.selectUnread);
    if (this.state.selectUnread !== unRead) {
      let notifications = [];
      if (unRead) {
        notifications = this.state.allNotifications.filter((data) => data.readed !== unRead || data.requestStatus === "pending");
      } else {
        notifications = this.state.allNotifications.filter((data) => data.readed !== unRead && data.requestStatus !== "pending");
      }
      this.setState({ notifications: notifications, selectUnread: unRead });
    }
  }

  openChangeStatusModal() {
    this.setState({ changeStatusModal: true });
  }
  async changeStatus() {
    let userObject = this.props.user;
    let unreadNotifications = this.state.allNotifications.filter((data) => data.readed !== true || data.requestStatus === "pending");
    if (this.state.status && unreadNotifications.length !== 0) {
      this.toast.show({ severity: "error", summary: "Unfinished Notifications", detail: "You must clear your notifications and perform necessary actions before changing your status.", life: 5000 });
      return;
    } else {
      userObject.status = this.state.status ? "AWAY" : "ONLINE";
      axios
        .post(address + "/updateStatus", {
          transferId: this.state.selectTransferUser,
          ...userObject,
        })
        .then(async (res) => {
          const data = res.data;
          console.log(data);
          console.log(userObject, this.state.status);
          SignOut();
          await updateStatus(userObject);
          console.log("still hit update");
          this.setState({ status: !this.state.status, changeStatusModal: false, selectTransferUser: null });
        });
    }
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Dialog className="proceed-container" resizable={false} draggable={false} visible={this.state.changeStatusModal} dismissableMask onHide={() => this.setState({ changeStatusModal: false })} showHeader={false}>
          <div className="proceed-content" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
            <h2 className="proceed-text" style={{ fontSize: "2.0em", textAlign: "center" }}>
              {this.state.status ? "Choose a supervisor your notifications transfer to" : "Are you sure you are back and ready for getting notifications?"}
            </h2>
            {this.state.status ? (
              <PrimeDropdown
                className="primereactfix-large-font-size-dropdown"
                style={{
                  margin: "0 30% 2vh 30%",
                  width: "40%",
                  fontSize: "2em",
                }}
                value={this.state.selectTransferUser}
                placeholder={"Select the supervisor"}
                options={this.state.transferMembers}
                onChange={(e) => this.setState({ selectTransferUser: e.value })}
                optionLabel="username"
              />
            ) : null}
            <button disabled={this.state.status && this.state.selectTransferUser === null} className="accept" style={{ paddingLeft: "1vw", paddingRight: "1vw", fontWeight: "bold", fontSize: "1.5em", textAlign: "center" }} onClick={() => this.changeStatus()}>
              OK
            </button>
          </div>
        </Dialog>
        <Dialog
          draggable={false}
          dismissableMask
          visible={this.state.dialog}
          modal={true}
          style={{ width: "50%", borderRadius: ".8em" }}
          onHide={() => this.setState({ dialog: false })}
          contentStyle={{
            width: "auto",
            height: "auto",
            padding: 0,
            overflow: "hidden",
            borderRadius: ".8em",
          }}
          showHeader={false}
        >
          <ModalHeader title="Notifications" hide={() => this.setState({ dialog: false })} />
          <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.indepthPTOModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ indepthPTOModal: false })} contentStyle={{ width: "auto", height: "auto", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
            <IndepthPTO user={this.props.user} data={this.state.PTOUser} />
          </Dialog>
          <Dialog className="proceed-container" resizable={false} draggable={false} visible={this.state.PTOTypeModal} dismissableMask onHide={() => this.setState({ PTOTypeModal: false })} showHeader={false}>
            <div className="proceed-content" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
              <h2 className="proceed-text" style={{ fontSize: "1.5em", textAlign: "center" }}>
                Which type of PTO want to consume
              </h2>
            </div>
            <div className="btn-grid" style={{ marginRight: "35%" }}>
              <button className="btn adminPageButtons red" onClick={() => this.setState({ PTOTypeModal: false }, () => this.ptoRequest(true, this.state.selectNotification, "N"))}>
                Normal
              </button>
              {this.state.disableBonus ? null : (
                <button className="btn adminPageButtons red" onClick={() => this.setState({ PTOTypeModal: false }, () => this.ptoRequest(true, this.state.selectNotification, "B"))}>
                  Bonus
                </button>
              )}
            </div>
          </Dialog>
          <CommentSection visible={this.state.viewComment} onHide={() => this.setState({ viewComment: false })} value={this.state.comment} updateParent={(data) => this.setState({ comment: data })} />
          <div className="scrollpanel-demo">
            {this.state.teamMembers.length > 0 ? (
              <PrimeDropdown
                className="primereactfix-large-font-size-dropdown"
                style={{
                  margin: "1vh 10% 1vh 10%",
                  width: "80%",
                  fontSize: "1em",
                }}
                value={this.state.selectUser}
                placeholder={"Select which supervisor's mail box you want to check"}
                options={this.state.teamMembers}
                onChange={(e) => this.onUserChange(e)}
                showClear
                optionLabel="username"
              />
            ) : null}
            <div
              style={{
                width: "10%",
                height: "50vh",
                float: "left",
                backgroundColor: "rgba(220,220,220,0.5)",
                textAlign: "center",
              }}
            >
              <div className={this.state.selectUnread ? "readbtn-select" : "readbtn"} onClick={() => this.switchRead(true)}>
                Unread
              </div>
              <div className={this.state.selectUnread ? "readbtn" : "readbtn-select"} onClick={() => this.switchRead(false)}>
                Read
              </div>
            </div>
            <ScrollPanel style={{ width: "90%", height: "50vh", float: "right" }} className="custombar1">
              <div style={{ lineHeight: "1.5" }}>
                <Accordion onTabOpen={(e) => this.openNotification(e.index)} onTabClose={(e) => this.openNotification(e.index)} className="accordion-custom" activeIndex={-1}>
                  {this.state.notifications.map((notification) => (
                    <AccordionTab
                      style={
                        notification.readed
                          ? null
                          : {
                              borderLeftStyle: "solid",
                              borderColor: "red",
                              borderWidth: "0.2vw",
                            }
                      }
                      header={
                        <React.Fragment>
                          <i className="pi pi-calendar"></i>
                          <span>{notification.header}</span>
                        </React.Fragment>
                      }
                    >
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          display: "block",
                          width: "100%",
                          wordBreak: "break-all",
                          wordWrap: "break-word",
                        }}
                      >
                        {notification.basic_message}
                        {notification.type === "PTO Request" && notification.requestStatus === "pending" ? (
                          <button className="info" onClick={() => this.setState({ indepthPTOModal: true, PTOUser: { ID: notification.PTOUser_id } })}>
                            In-depth PTO
                          </button>
                        ) : null}
                      </p>
                      {notification.requestStatus === "pending" ? (
                        <div style={{ display: "inline" }}>
                          <button className="accept" onClick={() => (notification.type === "HOUR Request" || (!notification.attendance_Name.includes("PTO") && !notification.attendance_Name.includes("NOPTO")) || notification.attendance_Name.includes("NOPTO") ? this.ptoRequest(true, notification) : this.getPTOInfo(notification))}>
                            Approve
                          </button>
                          <button className="comment" onClick={() => this.setState({ viewComment: true })}>
                            Comment
                          </button>
                          <button className="deny" onClick={() => this.ptoRequest(false, notification)}>
                            Reject
                          </button>
                        </div>
                      ) : null}
                    </AccordionTab>
                  ))}
                </Accordion>
              </div>
            </ScrollPanel>
          </div>
        </Dialog>

        <Navbar className="Nav" bg="dark" variant="dark" expand="sm">
          <img alt="" src={logo} style={{ height: "65px" }} className="d-inline-block align-top" />

          {
            /*hardcode for now, maybe will have another column in database for this access*/
            this.props.user?.changeDivision === true ? (
              <PrimeDropdown
                className="primereactfix-large-font-size-dropdown"
                style={{
                  margin: ".5vh 5% .5vh 10%",
                  width: "30%",
                  fontSize: "2em",
                }}
                value={this.props.user.division}
                placeholder={"Select Division"}
                options={this.state.divisions}
                onChange={(e) => this.onDivisionChange(e)}
                optionLabel="name"
              />
            ) : null
          }
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse className="justify-content-end">
            {this.props.user ? (
              <>
                <Switch checkedChildren="Online" unCheckedChildren="Offline" checked={this.state.status} onClick={(e) => this.openChangeStatusModal(e)} />
                {
                  <Badge count={this.state.count} style={{ marginRight: "1.25vw", marginTop: ".5vh" }}>
                    <Avatar
                      onClick={() => this.setState({ dialog: true })}
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "1vw",
                        height: "48px",
                        width: "48px",
                        fontSize: "1.2vw",
                        lineHeight: "50px",
                        textAlign: "center",
                      }}
                    >
                      <i className="pi pi-envelope" style={{ fontSize: "1.4vw", lineHeight: "52px" }}></i>
                    </Avatar>
                  </Badge>
                }

                <Dropdown
                  style={{ justifyContent: "right" }}
                  overlay={
                    <Button size="middle" variant="danger" onClick={() => SignOut()}>
                      Sign out
                    </Button>
                  }
                  trigger={["click"]}
                >
                  <Avatar
                    style={{
                      backgroundColor: "#1E90FF",
                      marginRight: "1vw",
                      height: "48px",
                      width: "48px",
                      fontSize: "1.2vw",
                      lineHeight: "48px",
                    }}
                  >
                    {this.props.user.initials}
                  </Avatar>
                </Dropdown>
              </>
            ) : (
              <></>
            )}
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default connect(mapStateToProps)(Header);
