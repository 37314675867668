import React from "react";
import axios from "axios";
import address from "../address.component";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import LoadingScreen from "../loading/loading.component.jsx";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import ReportSetting from "../reportSetting/reportSetting.component";
import "./timesheet.styles.css";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      data: [],
      nonProductiveData: [],
      defaultDate: [new Date(), new Date()],
      date: [new Date(), new Date()],
      user: {},
      expandedRow: [], // this is used for nonProductiveTime
      popSeen: false,
    };
    this.renderHeader = (
      <ColumnGroup>
        <Row>
          <Column header="Work Center" rowSpan={2} headerStyle={{ textAlign: "center", width: "10%" }} />
          <Column header="Setup" colSpan={3} headerStyle={{ textAlign: "center" }} />
          <Column header="Total" colSpan={3} headerStyle={{ textAlign: "center" }} />
        </Row>
        <Row>
          <Column header="Est. Hours" />
          <Column header="Actual Hours" />
          <Column header="%" />
          <Column header="Est. Hours" />
          <Column header="Actual Hours" />
          <Column header="%" />
        </Row>
      </ColumnGroup>
    );
  }

  percentageCalculator(rowData, type) {
    let toReturn;
    if (type === "quality") {
      toReturn = ((rowData[type].scrapQuantity / rowData[type].quantity) * 100).toFixed(2);
    } else if (type === "total") {
      toReturn = Math.round(((rowData.setup.estHours + rowData.run.estHours) / (rowData.setup.actHours + rowData.run.actHours)) * 100);
    } else {
      toReturn = Math.round((rowData[type].estHours / rowData[type].actHours) * 100);
    }

    if (isNaN(toReturn)) {
      return "0%";
    } else {
      return toReturn + "%";
    }
  }

  totalHoursCalculator(rowData, type) {
    let toReturn;
    if (type === "est") {
      toReturn = (rowData.setup.estHours + rowData.run.estHours).toFixed(3);
    } else if (type === "act") {
      toReturn = (rowData.setup.actHours + rowData.run.actHours).toFixed(3);
    } else if (type === "machineHours") {
      toReturn = (rowData.setup.estHours + rowData.run.estHours - (rowData.setup.actHours + rowData.run.actHours)).toFixed(2);
    }
    if (isNaN(toReturn)) {
      return "NaN";
    } else {
      return toReturn;
    }
  }

  totalCalculator(type, subType) {
    let toReturn = 0;
    if (type === "setup") {
      for (let data of this.state.data) {
        toReturn += data.setup[subType];
      }
    } else {
      for (let data of this.state.data) {
        toReturn += Number(this.totalHoursCalculator(data, subType));
      }
    }
    return toReturn;
  }

  covertHourToHourMins(totalHours) {
    let hour = Math.floor(totalHours);
    let mins = Math.floor((totalHours % 1) * 60);
    return hour + " hours " + mins + " mins";
  }

  componentDidMount() {}

  callData() {
    console.log(this.state.date, this.state.date[1]);
    if (!this.state.date[1]) {
      //TODO ADD TOAST
      return;
    }
    if (JSON.stringify(this.state.user) === "{}") {
      //TODO ADD TOAST
      return;
    }
    this.setState({ loading: true });
    if (this.state.user === null || this.state.user === undefined) {
      this.toast.show({ severity: "error", summary: "Please select a user", detail: "Please select a user", life: 3000 });
      this.setState({ loading: false });
      return;
    }
    if (this.state.date[1] === null || this.state.date[1] === undefined) {
      this.toast.show({ severity: "error", summary: "Please select The right date", detail: "Please select The right date", life: 3000 });
      this.setState({ loading: false });
      return;
    }
    axios.post(address + "/PerformanceSummary", { user_id: this.state.user.ID, start_time: this.state.date[0], end_time: this.state.date[1] }).then(async (res) => {
      const data = res.data;
      console.log(data);
      this.setState({ data: data.ToReturn, nonProductiveData: data.nonProductiveToReturn });
      this.setState({ loading: false });
    });
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  runReport = async (data) => {
    await this.setState({ date: data.date, user: data.user === undefined ? null : data.user });
    this.togglePop();
    this.callData();
  };

  workcenterTemplate = (rowData) => {
    let workcenter = rowData.workcenter;
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
        <i className={"pi" + (JSON.stringify(this.state.expandedRow) === JSON.stringify([]) ? " pi-chevron-right" : " pi-chevron-down")} style={{ fontSize: "1vw", display: workcenter === "NON PRODUCTIVE" ? "block" : "none" }} onClick={() => this.toggleRow(rowData)} />
        {workcenter}
      </div>
    );
  };

  toggleRow(rowData) {
    console.log("hit toggle");
    if (JSON.stringify(this.state.expandedRow) === JSON.stringify([])) {
      console.log("wow this worked");
      this.expandRow(rowData);
    } else {
      console.log("expected result");
      this.collapseRow(rowData);
    }
  }

  collapseRow(rowData) {
    this.setState({ expandedRow: [] });
  }

  expandRow(rowData) {
    this.setState({ expandedRow: [rowData] });
  }
  nonProductiveTemplate() {
    return (
      <DataTable value={this.state.nonProductiveData}>
        <Column header="Work Center" field="workCenter" />
        <Column header="Act. Hours" field="actualHour" body={(rowData) => rowData.actualHour.toFixed(3)} />
      </DataTable>
    );
  }

  render() {
    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column footer="Totals:" footerStyle={{ textAlign: "left", width: "10%" }} />
          <Column footer={this.covertHourToHourMins(this.totalCalculator("setup", "estHours").toFixed(3))} />
          <Column footer={this.covertHourToHourMins(this.totalCalculator("setup", "actHours").toFixed(3))} />
          <Column footer={((this.totalCalculator("setup", "estHours") / (this.totalCalculator("setup", "actHours") || 0.1)) * 100).toFixed(2) + "%"} />
          <Column footer={this.covertHourToHourMins(this.totalCalculator("total", "est").toFixed(3))} />
          <Column footer={this.covertHourToHourMins(this.totalCalculator("total", "act").toFixed(3))} />
          <Column footer={((this.totalCalculator("total", "est") / (this.totalCalculator("total", "act") || 0.1)) * 100).toFixed(2) + "%"} />
        </Row>
      </ColumnGroup>
    );
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <LoadingScreen show={this.state.loading} />
        <ReportSetting popSeen={this.state.popSeen} user active department={this.props.user.department || "ALL"} date={this.state.date} defaultDate={this.state.defaultDate} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <div className="hideMe" style={{ display: "grid", gridTemplateColumns: "20vw auto", width: "40vw", columnGap: "2vw", marginLeft: "2.5vw", marginRight: "2.5vw" }}>
          <Button label="Generate Report" className="adminPageButtons red" style={{ width: "20vw", height: "5vh", fontSize: "1.5em" }} onClick={this.togglePop} />
          <Button label="Print" className="adminPageButtons red" style={{ width: "10vw", height: "5vh", fontSize: "1.5em" }} onClick={() => window.print()} />
        </div>
        <div className="showMeGrid" style={{ gridTemplateColumns: "40vw 25vw 25vw", width: "80vw", marginLeft: "5vw" }}>
          <h3>
            {this.state.date[0] ? this.state.date[0].toISOString().slice(0, 10) : ""} to {this.state.date[1] ? this.state.date[1].toISOString().slice(0, 10) : ""}
          </h3>
          <h3 style={{ textAlign: "center" }}>{!this.state.user?.first_name ? "No User" : this.state.user.first_name + " " + this.state.user.last_name}</h3>
        </div>
        <div>
          <DataTable value={this.state.data} expandedRows={this.state.expandedRow} rowExpansionTemplate={(data) => this.nonProductiveTemplate(data)} id="PrimeReactDataTableOddRow" headerColumnGroup={this.renderHeader} footerColumnGroup={footerGroup}>
            <Column field="workcenter" body={this.workcenterTemplate} />

            <Column field="setup.estHours" body={(rowData) => this.covertHourToHourMins(rowData.setup.estHours.toFixed(3))} />
            <Column field="setup.actHours" body={(rowData) => this.covertHourToHourMins(rowData.setup.actHours.toFixed(3))} />
            <Column field="setup.percentage" body={(rowData) => this.percentageCalculator(rowData, "setup")} />

            <Column field="total.estHours" body={(rowData) => this.covertHourToHourMins(this.totalHoursCalculator(rowData, "est"))} />
            <Column field="total.actHours" body={(rowData) => this.covertHourToHourMins(this.totalHoursCalculator(rowData, "act"))} />
            <Column field="total.percentage" body={(rowData) => this.percentageCalculator(rowData, "total")} />
          </DataTable>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps)(Page);
