import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import ReportSetting from "../reportSetting/reportSetting.component";
import LoadingScreen from "../loading/loading.component.jsx";
import { getYOS, getRemainingAccrue, getAccrualRate } from "../global_functions/accural";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class Page extends React.Component {
  // top class,

  constructor(props) {
    super(props);
    this.state = {
      shift: this.props.user.shift || "ALL",
      department: this.props.user.department || "ALL",
      data: [],
      active: "ALL",
      header: ["Employee Number", "First Name", "Last Name", "Department", "Shift", "Title", "Overtime date", "Position/Grade", "Pay Type", "Total Offenses", "Years of Service", "Date Hired", "Intern Hire Date", "Intern Agency", "Termination Date", "Active", "Paid Time Off", "Bonus PTO", "Will Accrue"],
    };
    this.renderHeader = (
      <ColumnGroup>
        <Row>
          <Column alignHeader="center" header="pin" rowSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: "2em" }} />
          <Column alignHeader="center" header="first_name" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="last_name" colSpan={2} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
          <Column alignHeader="center" header="Total Hours" rowSpan={2} headerStyle={{ textAlign: "center", width: "6vw", padding: 0 }} />
          <Column alignHeader="center" header="Attend Code" rowSpan={2} headerStyle={{ textAlign: "center", width: "5w", padding: 0 }} />
          <Column alignHeader="center" header="Shift" rowSpan={2} headerStyle={{ textAlign: "center", width: "3vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp #" rowSpan={2} headerStyle={{ textAlign: "center", width: "4vw", padding: 0 }} />
          <Column alignHeader="center" header="Emp Name" rowSpan={2} headerStyle={{ textAlign: "center", width: "14vw", padding: 0 }} />
          <Column alignHeader="center" header="Department" rowSpan={2} colSpan={1} headerStyle={{ textAlign: "center", width: "10vw", padding: 0 }} />
        </Row>
        <Row>
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Act." headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Adj." headerStyle={{ textAlign: "center", padding: 0 }} />
        </Row>
      </ColumnGroup>
    );
  }

  componentDidMount() {
    this.getReport();
  }
  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let headers = this.state.header;
    let data = this.state.data;
    for (let i = 0; i < headers.length; i++) {
      csv += headers[i] + ",";
    }
    csv += "\n";
    for (let i = 0; i < data.length; i++) {
      let s = data[i];
      csv += s.pin ? s.pin + "," : ",";
      csv += s.first_name ? s.first_name + "," : ",";
      csv += s.last_name ? s.last_name + "," : ",";
      csv += s.department ? s.department + "," : ",";
      csv += s.shift ? s.shift + "," : ",";
      csv += s.title ? s.title + "," : ",";
      csv += s.overtime ? s.overtime + "," : ",";
      // likely to be requested, should be supervisor name not the id.
      // csv += s.supervisor_id ? s.supervisor_id + "," : ",";
      csv += s.position ? s.position + "," : ",";
      csv += s.type ? s.type + "," : ",";
      csv += s.points ? s.points + "," : ",";
      csv += getYOS(s.date_hired, s.intern_hire_date) + ",";
      csv += s.date_hired ? s.date_hired.slice(0, 10) + "," : ",";
      csv += s.intern_hire_date ? s.intern_hire_date.slice(0, 10) + "," : ",";
      csv += s.intern_agency ? s.intern_agency + "," : ",";
      csv += s.termination_date ? s.termination_date.slice(0, 10) + "," : ",";
      csv += s.active ? "Yes," : "No,";
      csv += s.vacation_hours.toFixed(2) + ",";
      csv += s.wellness_hours.toFixed(2) + ",";
      csv += getRemainingAccrue(getAccrualRate(s.date_hired, s.intern_hire_date, s.accrual_rate));
      csv += "\n";
    }
    csv += "\n";
    this.download(csv, "censusExport");
  }

  yos(data) {
    //NOTE This just calculates Year of Service!
    let newDate = new Date(data.intern_hire_date || data.date_hired || new Date());
    let yos = 0;
    while (new Date() > newDate) {
      yos += 1;
      newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
    }
    return yos;
  }

  getReport() {
    this.setState({ loading: true });
    axios
      .post(address + "/getCensusReport", {
        active: this.state.active,
        department: this.state.department,
        shift: this.state.shift,
        division: this.props.user.division,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({ data: data }, () => this.setState({ loading: false }));
      });
  }
  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };
  runReport = async (data) => {
    this.setState(data, () => {
      this.togglePop();
      this.getReport();
    });
  };
  render() {
    return (
      <>
        <LoadingScreen show={this.state.loading} />
        <ReportSetting popSeen={this.state.popSeen} active department={this.props.user.department || "ALL"} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <div style={{ display: "grid", gridTemplateColumns: "20vw auto", marginLeft: "2.5vw", marginRight: "2.5vw", columnGap: "2.5vw" }}>
          <Button label="Generate Report" className="hideMe adminPageButtons red" style={{ width: "100%" }} onClick={this.togglePop} />
          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
        </div>
        <DataTable value={this.state.data} scrollable scrollHeight="85vh" scrollDirection="both">
          <Column sortable field="pin" header="Employee #" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column sortable field="first_name" header="First Name" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column sortable field="last_name" header="Last Name" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column sortable field="department" header="Department" style={{ textAlign: "center", width: "150px" }} />
          <Column sortable field="shift" header="Shift" style={{ textAlign: "center", width: "120px" }} />
          <Column field="title" header="Title" style={{ textAlign: "center", width: "100px" }} />
          <Column field="overtime" header="Overtime Day" style={{ textAlign: "center", width: "120px" }} />
          <Column field="position" header="Position/Grade" style={{ textAlign: "center", width: "120px" }} />
          <Column sortable field="type" header="Pay Type" style={{ textAlign: "center", width: "150px" }} />
          <Column sortable field="points" header="Total Offenses" style={{ textAlign: "center", width: "180px" }} />
          <Column
            sortable
            field="year_of_service"
            header="Years of Service"
            style={{ textAlign: "center", width: "160px" }}
            body={(rowData) => {
              return getYOS(rowData.date_hired, rowData.intern_hire_date);
            }}
          />
          <Column
            sortable
            field="date_hired"
            header="Date Hired"
            style={{ textAlign: "center", width: "150px" }}
            body={(rowData) => {
              return rowData.date_hired ? rowData.date_hired.slice(0, 10) : "";
            }}
          />
          <Column
            sortable
            field="intern_hire_date"
            header="Intern Hire Date"
            style={{ textAlign: "center", width: "150px" }}
            body={(rowData) => {
              return rowData.intern_hire_date ? rowData.intern_hire_date.slice(0, 10) : "";
            }}
          />
          <Column field="intern_agency" header="Intern Agency" style={{ textAlign: "center", width: "120px" }} />
          <Column
            sortable
            field="termination_date"
            header="Termination Date"
            style={{ textAlign: "center", width: "150px" }}
            body={(rowData) => {
              return rowData.termination_date ? rowData.termination_date.slice(0, 10) : "";
            }}
          />
          <Column
            sortable
            filter
            field="active"
            header="Active"
            style={{ textAlign: "center", width: "120px" }}
            body={(rowData) => {
              return rowData.active ? "Yes" : "No";
            }}
          />
          <Column
            field="vacation_hours"
            header="Paid Time Off"
            style={{ textAlign: "center", width: "120px" }}
            body={(rowData) => {
              return rowData.vacation_hours.toFixed(2);
            }}
          />
          <Column
            field="wellness_hours"
            header="Bonus PTO"
            style={{ textAlign: "center", width: "120px" }}
            body={(rowData) => {
              return rowData.wellness_hours.toFixed(2);
            }}
          />
          <Column
            field="will_accrue"
            header="Will Accrue"
            style={{ textAlign: "center", width: "120px" }}
            body={(rowData) => {
              return getRemainingAccrue(getAccrualRate(rowData.date_hired, rowData.intern_hire_date, rowData.accrual_rate));
            }}
          />
        </DataTable>
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
