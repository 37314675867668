function dateSort(a, b) {
  //assumes both a & b have adj_start/end_time
  if (new Date(b.adj_start_time) > new Date(a.adj_start_time)) {
    return -1;
  } else if (new Date(b.adj_start_time) < new Date(a.adj_start_time)) {
    return 1;
  } else {
    return 0;
  }
}

export default dateSort;
