import { CONNECT_WITH_SSO, AppAction, AppState, SHOW_SECRET, DISCONNECT, TIMETRACK_LOG_IN, TIMETRACK_LOG_OUT, CONNECT_WITH_BAD_CREDENTIALS, UPDATE_VERSION, TIMETRACK_SEARCH_SETTING, TIMETRACK_AUTH_LOGIN } from "./types";

export const initialState: AppState = {
  secret: "",
  error: false,
};

export function reducer(previousState = initialState, action: AppAction): AppState {
  const state = { ...previousState };
  state.error = false;
  switch (action.type) {
    case CONNECT_WITH_SSO: {
      state.user = action.user;
      console.log("connect with sso ok");
      return state;
    }
    case CONNECT_WITH_BAD_CREDENTIALS: {
      state.error = true;
      console.log("connect with sso ok");
      return state;
    }
    case UPDATE_VERSION: {
      // window.location.reload();
      //console.log(action);
      //console.log({ ...state, workcenter: action.workcenter });
      return { ...state, version: action.version };
    }
    case SHOW_SECRET: {
      state.secret = action.secret;
      console.log("set secret", state);

      return state;
    }
    case TIMETRACK_LOG_IN: {
      state.TTUser = action.TTUser;
      return state;
    }

    case TIMETRACK_LOG_OUT: {
      state.TTUser = undefined;
      return state;
    }
    case DISCONNECT: {
      state.secret = "";
      state.user = undefined;
      return state;
    }
    case TIMETRACK_SEARCH_SETTING: {
      state.SearchSetting = action.SearchSetting;
      return state;
    }
    case TIMETRACK_AUTH_LOGIN: {
      state.AuthForLogin = action.AuthForLogin;
      console.log(action)
      return state;
    }
    default:
      return previousState;
  }
}
