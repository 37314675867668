import React from "react";
import "./terminal.styles.css";
import axios from "axios";
import address from "../address.component";
import logo from "../../assets/icons/Haven_logo_Final_White-01.svg";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";

class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      input: "",
      requestComment: "",
      layoutName: "default",
    };
  }

  componentDidMount() {
    if (this.props.requestComment !== null && this.props.requestComment !== "") {
      this.setState({ input: this.props.requestComment });
    }
  }

  onChange = (input) => {
    this.setState({ input });
    console.log("Input changed", input);
  };

  onKeyPress = (button) => {
    console.log("Button pressed", button);

    //  * If you want to handle the shift and caps lock buttons
    if (button === "{enter}") {
      this.props.closeAndSave(this.state.input);
    }
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    const layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default",
    });
  };

  onChangeInput = (event) => {
    const input = event.target.value;
    this.setState({ input });
  };

  render() {
    return (
      <div>
        <InputTextarea style={{ width: "100%", height: "34vh", resize: "none" }} value={this.state.input} placeholder={"Type the message for this request and press save"} onChange={this.onChangeInput} />
        <Button label="Save" style={{ float: "none", marginLeft: "70%", fontSize: "2vw", width: "30%", height: "15%", marginTop: "5vh", borderRadius: "15px" }} className="adminPageButtons red" onClick={() => this.props.closeAndSave(this.state.input)}></Button>
      </div>
    );
  }
}

export default Page;
