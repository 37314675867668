import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { getRemainingAccrue, getAccrualRate } from "../global_functions/accural";
class IndepthPTO extends React.Component {
  //key pad code
  constructor(props) {
    super(props);
    this.state = {
      BonusOrNormalPTO: [
        { name: "Normal PTO", value: "N" },
        { name: "Bonus PTO", value: "B" },
      ],
      userInfo: {},
      insertPTOModal: false,
      showNormal: true,
      linkedBonusDataId: null,
      normalTotal: 0,
      bonusTotal: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async createPTO() {
    if (this.state.BorNPTO === null || this.state.BorNPTO === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Please select the type of PTO", life: 3000 });
      return;
    }
    if (this.state.amount === null || this.state.amount === undefined) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "Please enter the amount", life: 3000 });
      return;
    }
    if (isNaN(this.state.amount) && Number(this.state.amount) !== 0) {
      this.toast.show({ severity: "error", summary: "Invalid Action", detail: "The amount is not a correct number", life: 3000 });
      return;
    }
    axios.post(address + "/insertPTO", { admin_id: this.props.user.id, user_id: this.props.data.ID, type: this.state.BorNPTO, amount: this.state.amount }).then(async (res) => {
      this.getData();
      this.setState({ insertPTOModal: false });
    });
  }

  getData() {
    axios.get(address + "/getPTOReasons").then((res) => {
      const data = res.data;
      this.setState({ WellnessVacation: data });
    });
    axios.post(address + "/getEmployeeCardInfo", { ID: this.props.data ? this.props.data.ID || 0 : 0 }).then((res) => {
      const data = res.data;
      console.log(data);
      if (data === "User Not Found") {
        this.toast.show({ severity: "error", summary: data, life: 3000 });
      } else {
        this.setState({ userInfo: data });
      }
    });
    axios.post(address + "/getIndepthPTO", { user_id: this.props.data ? this.props.data.ID || 0 : 0 }).then((res) => {
      const data = res.data;
      const normalData = data.total.filter((ele) => ele.pto_type === "N");
      const bonusData = data.total.filter((ele) => ele.pto_type === "B");

      normalData.sort((prev, next) => {
        // Check if date_expire is expired or not
        const isDisableA = new Date(prev.expires) < new Date();
        const isDisableB = new Date(next.expires) < new Date();
        // Sort by expiration status first
        if (isDisableA && !isDisableB) {
          return -1;
        } else if (!isDisableA && isDisableB) {
          return 1;
        }
        // If both are expired or not expired
        return new Date(prev.date_applied) - new Date(next.date_applied);
      });

      let normalTotal = 0;
      normalData.forEach((element, index, array) => {
        //Check if carry over or not
        if (element.detail === "Carry over from previous year") {
          normalTotal = Number(element.amount);
        } else {
          normalTotal += Number(element.amount);
        }
        array[index].total = normalTotal;
      });

      const addExpireBonusData = bonusData
        .map((data) => {
          if (Number(data.remaining) > 0 && new Date(data.expires) < new Date()) {
            //some bonus data expire, create the expire row
            let expire = {
              ...data,
              type: "Expired",
              earned: data.expires,
              date_applied: data.expires,
              remaining: 0,
              amount: -Number(data.remaining),
            };
            return [{ ...data }, { ...expire }];
          } else {
            return { ...data };
          }
        })
        .flat();
      addExpireBonusData.sort((prev, next) => {
        return new Date(prev.date_applied) - new Date(next.date_applied);
      });
      let expireTotal = 0;
      addExpireBonusData.forEach((element, index, array) => {
        expireTotal += Number(element.amount);
        array[index].total = expireTotal;
      });
      this.setState({ normalTotal: normalTotal, bonusTotal: expireTotal, bonusPTOData: addExpireBonusData, timeoff: this.props.user ? normalData : normalData.reverse(), planned: data.planned });
    });
  }
  rowClassName = (data) => (this.isDisable(data) ? "expire-row" : "");

  //check should disable or not
  isDisable = (data) => new Date(data.expires) < new Date();

  handleRowClick = (data) => {
    if (data.type === "Expired") {
      if (this.state.linkedBonusDataId !== data.id) {
        this.setState({ linkedBonusDataId: data.id });
        return;
      }
    }
    this.setState({ linkedBonusDataId: null });
  };

  bonusRowClassName = (data) => (data.type === "Expired" ? "clickable-row" : "" + (data.id === this.state.linkedBonusDataId ? " marked-row" : "") + (this.isDisable(data) ? "" : ""));
  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Dialog draggable={false} dismissableMask visible={this.state.insertPTOModal} onHide={() => this.setState({ insertPTOModal: false })} modal={true} style={{ borderRadius: "1em", width: "30vw" }} contentStyle={{ padding: "0em", overflow: "hidden", borderRadius: "1em", justifyContent: "center" }} showHeader={false}>
          <ModalHeader title="Insert PTO" />
          <div style={{ display: "grid", gridTemplateColumns: "12.5vw 12.5vw ", columnGap: "1vw", marginLeft: "2vw" }}>
            <h3 style={{ width: "100%", textAlign: "center" }}>1. Type of PTO?</h3>
            <h3 style={{ width: "100%", textAlign: "center" }}>2. Amount</h3>
            <Dropdown className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "2em" }} value={this.state.BorNPTO} placeholder={"Required"} options={this.state.BonusOrNormalPTO} onChange={(e) => this.setState({ BorNPTO: e.value, disabledFirstDropdown: false }, () => console.log(e.value))} optionLabel="name" />
            <InputText disabled={this.state.disabledFirstDropdown} keyfilter="num" value={this.state.amount} style={{ fontSize: "2em", width: "95%" }} placeholder={"Required"} onChange={(e) => this.setState({ amount: e.target.value, disabledThird: false })} className="p-d-block" />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto", columnGap: "1vw", marginTop: "3vh" }}>
            <Button label="Back" className="createShiftButton" onClick={() => this.setState({ insertPTOModal: false })} />
            <Button disabled={this.state.disabledThird} label="Create" className="createShiftButton" onClick={() => this.createPTO()} />
          </div>
        </Dialog>

        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.detailVisible} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ detailVisible: false, detail: null })} contentStyle={{ width: "auto", height: "auto", padding: 0, overflow: "hidden", borderRadius: ".8em" }} showHeader={false}>
          <ModalHeader title="Comment" height="12vh" titleFontSize="6vh" />
          <InputTextarea disable resizable={false} style={{ width: "100%", height: "100%" }} rows={10} cols={60} value={this.state.detail} />
        </Dialog>

        <ModalHeader title="PTO Management" />
        <div style={{ float: "left" }}>
          {this.props.user ? (
            <div className="ColumnHeaderFooter ColumnFooter" style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>PTO left to Accrue: {getRemainingAccrue(getAccrualRate(this.state.userInfo.date_hired, this.state.userInfo.intern_hire_date, this.state.userInfo.accrual_rate))} days</div>
              <div>Total PTO Balance: {(this.state.normalTotal + this.state.bonusTotal).toFixed(2)} days</div>
            </div>
          ) : null}{" "}
          <div className="ColumnHeaderFooter ColumnHeader" onClick={() => this.setState({})}>
            <Button
              className="menuButton"
              style={{ fontSize: "1vw" }}
              onClick={() => {
                this.setState({ showNormal: !this.state.showNormal });
              }}
            >
              Show {this.state.showNormal ? "Bonus" : "Normal"} PTO
            </Button>
          </div>
          <div style={{ height: "60vh", backgroundColor: "white" }}>
            {this.state.showNormal ? (
              <DataTable editMode="row" scrollable scrollHeight="60vh" style={{ width: "55vw", height: "60vh", overflow: "hidden", overflowY: "scroll" }} rowClassName={this.rowClassName} editingRows={this.state.editingRows} value={this.state.timeoff} onRowEditChange={this.onRowEditChange} onRowEditComplete={this.onRowEditComplete}>
                <Column
                  field="type"
                  filter={this.props.user !== null}
                  filterPlaceholder="Search by Type"
                  style={{ minWidth: "6vw" }}
                  bodyStyle={{ textAlign: "center" }}
                  header="Type"
                  alignHeader="center"
                  body={(rowData) => {
                    return rowData.detail === "Carry over from previous year" ? "Rollover" : rowData.type;
                  }}
                />
                <Column field="pto_type" filter={this.props.user !== null} filterPlaceholder="Search by Type" style={{ minWidth: "6vw" }} bodyStyle={{ textAlign: "center" }} header="PTO Type" alignHeader="center" />
                <Column field="amount" bodyStyle={{ textAlign: "center" }} header="Amount" alignHeader="center" body={(rowData) => Number.parseFloat(rowData.amount).toFixed(2)} />
                <Column field="date_applied" sortable bodyStyle={{ textAlign: "center" }} header="Date Applied" alignHeader="center" body={(rowData) => (rowData.date_applied ? new Date(rowData.date_applied).toLocaleDateString() : "")} />
                <Column field="expires" bodyStyle={{ textAlign: "center" }} header="Expiry Date" alignHeader="center" body={(rowData) => (rowData.expires === null ? null : rowData.expires.slice(0, 10))} />
                {this.props.user ? <Column field="username" bodyStyle={{ textAlign: "center" }} header="Granted By" alignHeader="center" body={(rowData) => (rowData.username === null ? "System" : rowData.username)} /> : null}
                <Column field="total" bodyStyle={{ textAlign: "center" }} header="Total" alignHeader="center" body={(rowData) => (Math.round(Number.parseFloat(rowData.total) * 100) / 100).toFixed(2)} />
                {this.props.user ? (
                  <Column
                    header="Detail"
                    align="center"
                    style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0 }}
                    body={(rowData, b) => {
                      return rowData.detail === null ? null : <i className="pi pi-comment p-text-secondary p-overlay-badge" style={{ fontSize: "1.5vw" }} onClick={() => this.setState({ detailVisible: true, detail: rowData.detail })}></i>;
                    }}
                  ></Column>
                ) : null}
              </DataTable>
            ) : (
              <DataTable editMode="row" scrollable scrollHeight="60vh" style={{ width: "55vw", height: "60vh", overflow: "hidden", overflowY: "scroll" }} rowClassName={this.bonusRowClassName} value={this.state.bonusPTOData} onRowClick={(e) => this.handleRowClick(e.data)}>
                <Column field="type" filter={this.props.user !== null} filterPlaceholder="Search by Type" style={{ minWidth: "6vw" }} bodyStyle={{ textAlign: "center" }} header="Type" alignHeader="center" />
                <Column field="pto_type" filter={this.props.user !== null} filterPlaceholder="Search by Type" style={{ minWidth: "6vw" }} bodyStyle={{ textAlign: "center" }} header="PTO Type" alignHeader="center" />
                <Column field="amount" bodyStyle={{ textAlign: "center" }} header="Amount" alignHeader="center" body={(rowData) => Number.parseFloat(rowData.amount).toFixed(2)} />
                <Column field="date_applied" sortable bodyStyle={{ textAlign: "center" }} header="Date Applied" alignHeader="center" body={(rowData) => (rowData.date_applied ? new Date(rowData.date_applied).toLocaleDateString() : "")} />
                <Column field="expires" bodyStyle={{ textAlign: "center" }} header="Expiry Date" alignHeader="center" body={(rowData) => (rowData.expires === null ? null : rowData.expires.slice(0, 10))} />
                {this.props.user ? <Column field="username" bodyStyle={{ textAlign: "center" }} header="Granted By" alignHeader="center" body={(rowData) => (rowData.username === null ? "System" : rowData.username)} /> : null}
                <Column field="total" bodyStyle={{ textAlign: "center" }} header="Total" alignHeader="center" body={(rowData) => (Math.round(Number.parseFloat(rowData.total) * 100) / 100).toFixed(2)} />
                {this.props.user ? (
                  <Column
                    header="Detail"
                    align="center"
                    style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0 }}
                    body={(rowData, b) => {
                      return rowData.detail === null ? null : <i className="pi pi-comment p-text-secondary p-overlay-badge" style={{ fontSize: "1.5vw" }} onClick={() => this.setState({ detailVisible: true, detail: rowData.detail })}></i>;
                    }}
                  ></Column>
                ) : null}
              </DataTable>
            )}
          </div>
          {this.props.user?.permissions === "ALL" ? (
            <div className="ColumnHeaderFooter ColumnFooter" onClick={() => this.setState({ insertPTOModal: true })}>
              Insert PTO
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default IndepthPTO;
