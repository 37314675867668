import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";

import MTUUserPage from "./pages/mtu/mtu.component";
import ReportXDays from "./pages/report/xdays.component";
import ReportAttendance from "./pages/report/weeklyattendance.component";
import PaylocityExport from "./pages/report/paylocity.component";
import Census from "./pages/report/census.component";
import AttendanceReport from "./pages/report/attendance.component";
import AttendanceSheetExport from "./pages/report/attendanceSheet.component";
import ReportPosting from "./pages/report/postingData.component";
import ReportRouting from "./pages/report/routingExport.component";
import ReportTimesheet from "./pages/report/timesheet.component";
import ReportWorkCenter from "./pages/report/workcenterperformance.component";
import AdminPage from "./pages/mtu/admin.component";
import HRAdminUserPage from "./pages/mtu/adminManagementPage.component";
import TimeOffPage from "./pages/mtu/vacation.component";
import LoginPage from "./pages/mtu/login.component";
import Holiday from "./pages/mtu/holiday.component";
import Schedule from "./pages/mtu/schedule.component";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { CONNECT_WITH_SSO } from "../src/redux2/types";
import { store } from "../src/redux2/store";
import axios from "axios";
import { connect } from "react-redux";
import { TIMETRACK_LOG_IN } from "../src/redux2/types";

const mapStateToProps = (state) => ({
  user: state.TTUser,
});
function GenerateTTUser(userObject) {
  console.log("OBJECT", userObject);
  store.dispatch({
    type: TIMETRACK_LOG_IN,
    TTUser: userObject,
  });
}
async function connectWithSSO() {
  const json = await axios.get("http://192.168.1.8:3080/ws/connect-with-sso", { method: "GET", withCredentials: true });
  const group = await axios.get("http://192.168.1.8:3047/getgroup?userId=" + json.data.sso.user.adUser.sAMAccountName[0]);
  console.log(group.data);
  const newObject = { ...json.data.sso.user, ...group.data };
  store.dispatch({
    type: CONNECT_WITH_SSO,
    user: newObject,
  });
}
function App({ user }) {
  if (user) {
  } else {
    if (window.location.pathname !== "/" && window.location.pathname !== "/mckenzie") {
      console.log("lame");
      return <LoginPage loginCallback={GenerateTTUser} />;
    }
  }
  return (
    <div>
      <Switch>
        <Route path="/admin" exact component={AdminPage} />
        <Route path="/reset-password/" component={LoginPage} />
        <Route path="/adminUser" exact component={HRAdminUserPage} />
        <Route path="/schedule" exact component={Schedule} />
        <Route path="/report/attendance" component={AttendanceReport} />
        <Route path="/report/xdays" component={ReportXDays} />
        <Route path="/report/workcenter" component={ReportWorkCenter} />
        <Route path="/report/posting" component={ReportPosting} />
        <Route path="/report/timesheet" component={ReportTimesheet} />
        <Route path="/report/dataexport" component={ReportAttendance} />
        <Route path="/report/paylocityexport" component={PaylocityExport} />
        <Route path="/report/routingexport" component={ReportRouting} />
        <Route path="/report/attendancesheet" component={AttendanceSheetExport} />
        <Route path="/report/attendance" component={AttendanceReport} />
        <Route path="/report/census" component={Census} />
        <Route path="/holiday" component={Holiday} />
        <Route path="/mckenzie" render={() => <MTUUserPage div="MCKENZIE" />} />
        <Route path="/" render={() => <MTUUserPage div="HAVEN" />} />
      </Switch>
    </div>
  );
}

export default connect(mapStateToProps)(App);
