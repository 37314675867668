import calcTotalHours from "./calcTotalHours.function";
import getNewStartTime from "./getNewStartTime.function";
import returnWeekObject from "./returnWeekObject.function";
import dateSort from "./dateSort.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";

function processDataByUser(rawData, breaks, newBreaks, startDate, endDate, attendanceCodes) {
  let processedShifts = [];
  rawData = JSON.parse(JSON.stringify(rawData));
  for (let i = 0; i < rawData.length; i++) {
    let { user, weeks } = rawData[i];
    for (let w = 0; w < weeks.length; w++) {
      if (weeks[w] === null) {
        //todo look into this error, but for now this should be quick handling.
        continue;
      }
      let { baseApprovedHours, shifts } = weeks[w];
      let week = weeks[w]; // sorry, lazy coding in too much of a rush to rename vars.
      let approvedOTHours = baseApprovedHours;
      shifts = shifts.sort(dateSort);
      //todo sort shifts here?
      for (let s = 0; s < shifts.length; s++) {
        //* Hey why is this here? because some people approve shifts out of order! which has caused a bug in the past and this was the solution!
        let shift = shifts[s];
        let start = new Date(shift.adj_start_time);
        let end = new Date(shift.adj_end_time);

        let shiftHours = calcTotalHours({
          start: shift.adj_start_time || null,
          end: shift.adj_end_time || null,
          userInfo: shift.user,
          breaks: breaks,
          newBreaks: newBreaks,
          shift: user.shift || 1,
          attendance: getHighestAttendance(shift.attendance, attendanceCodes) || null,
          approved: shift.approved || false,
          shiftHour: shift.hours,
          breakShift: shift.break_shift,
        });
        if (shift.copy === null || shift.copy === undefined) {
          shift.hours = shiftHours;
        }
        if (new Date(start) >= new Date(week.end)) {
          shift.flag = "Pushed to next week";
          shift.trueWeek = JSON.parse(JSON.stringify(shift.week));
          shift.trueWeek += 1;
          if (weeks[w + 1] === undefined) {
            weeks[w + 1] = returnWeekObject(weeks[w]);
          }
          weeks[w + 1].shifts.splice(0, 0, shift);
          weeks[w].shifts.splice(s, 1);
          s = s - 1;
          continue;
        } else if (new Date(end) >= new Date(week.end) && shift.approved !== true) {
          let shiftCopy = JSON.parse(JSON.stringify(shift));
          shiftCopy.copy = 2;
          shift.copy = 1;
          shiftCopy.FLAG = "WHAT!~";
          shift.flag = "COME ON DUDE";
          shiftCopy.modified = "start";
          shift.modified = "end";
          shift.adj_end_time = new Date(new Date(new Date(new Date(new Date(start).setHours(24)).setMinutes(0)).setMilliseconds(0)).setSeconds(0));
          shiftCopy.adj_start_time = shift.adj_end_time;
          shift.hours = calcTotalHours({
            start: shift.adj_start_time || null,
            end: shift.adj_end_time || null,
            userInfo: shift.user,
            breaks: breaks,
            newBreaks: newBreaks,
            shift: user.shift || 1,
            attendance: getHighestAttendance(shift.attendance, attendanceCodes) || null,
            approved: shift.approved || false,
            shiftHour: shift.hours,
            breakShift: shift.break_shift,
          });
          shiftCopy.hours = calcTotalHours({
            start: shiftCopy.adj_start_time || null,
            end: shiftCopy.adj_end_time || null,
            userInfo: shiftCopy.user,
            breaks: breaks,
            newBreaks: newBreaks,
            shift: user.shift || 1,
            attendance: getHighestAttendance(shiftCopy.attendance, attendanceCodes) || null,
            approved: shiftCopy.approved || false,
            shiftHour: shiftCopy.hours,
            breakShift: shiftCopy.break_shift,
          });
          shiftCopy.trueWeek = shift.week + 1;
          if (weeks[w + 1] === undefined) {
            weeks[w + 1] = returnWeekObject(weeks[w]);
          }
          weeks[w + 1].shifts.splice(0, 0, shiftCopy);
          // continue;
        }

        if (shift.approved === true && (shift.attendance === "DIRECT" || shift.attendance === "WT")) {
          approvedOTHours += shiftHours;
          continue;
        }
        // if ()
      }
      for (let s = 0; s < shifts.length; s++) {
        let shift = shifts[s];
        // if (new Date(shift.adj_end_time) >= new Date(endDate) && !(new Date(shift.adj_start_time) < new Date(endDate))) {
        if (new Date(shift.adj_end_time) > new Date(endDate) && shift.adj_end_time !== null) {
          //console.log(new Date(endDate).toISOString(), new Date(shift.adj_end_time).toISOString());
          shift.display = false;
        }
        if (new Date(shift.adj_end_time) <= new Date(startDate) && shift.adj_end_time !== null) {
          shift.display = false;
        }
        if (new Date(shift.adj_start_time) > new Date(endDate) && shift.adj_end_time === null) {
          shift.display = false;
        }

        let shiftHours = calcTotalHours({
          start: shift.adj_start_time || null,
          end: shift.adj_end_time || null,
          userInfo: shift.user,
          breaks: breaks,
          newBreaks: newBreaks,
          shift: user.shift || 1,
          attendance: getHighestAttendance(shift.attendance, attendanceCodes) || null,
          approved: shift.approved || false,
          shiftHour: shift.hours,
          breakShift: shift.break_shift,
        });
        if (shift.approved === true) {
          continue;
        }
        if (shift.copy === null || shift.copy === undefined) {
          shift.hours = shiftHours;
        }
        let newTotalHours = approvedOTHours + shiftHours;
        if (shift.attendance === "DIRECT" || shift.attendance === "WT") {
          // console.log(newTotalHours);
          if (newTotalHours > 40) {
            let difference = newTotalHours - 40;
            if (difference > 0 && difference < shiftHours) {
              let shiftCopy = JSON.parse(JSON.stringify(shift));
              if (shift.attendance === "WT") {
                shiftCopy.attendance = "OVERTIME";
              } else {
                shiftCopy.attendance = "OVERTIME";
              }
              //let newStartEnd = getNewStartTime({ start: shiftCopy.adj_start_time, end: shiftCopy.adj_end_time, hours: difference, shift: user.shift, breaks: breaks, newBreaks: newBreaks });
              let newStartEnd = new Date(new Date(shiftCopy.adj_start_time).setHours(new Date(shiftCopy.adj_start_time).getHours() + (shiftHours - difference)));
              shiftCopy.adj_start_time = newStartEnd;
              shiftCopy.hours = difference;
              if (shiftCopy.modified === "end" || shiftCopy.modified === "both") {
                shiftCopy.modified = "both";
              } else {
                shiftCopy.modified = "start";
              }
              if (shift.modified === "start" || shift.modified === "both") {
                shift.modified = "both";
              } else {
                shift.modified = "end";
              }
              shift.copy = 1;
              shiftCopy.copy = 2;
              shift.adj_end_time = newStartEnd;
              shift.hours = shiftHours - difference;
              approvedOTHours = 40;

              shifts.splice(s + 1, 0, shiftCopy);
            } else {
              if (shift.attendance === "WT") {
                shift.attendance = "OTWT";
              } else {
                shift.attendance = "OVERTIME";
              }
            }
          } else {
            approvedOTHours += shiftHours;
          }
        }
      }
      processedShifts = processedShifts.concat(shifts);
    }
  }

  //todo needs a date sort.

  return { data: processedShifts, msg: "finished!", dataByUser: rawData };
}

export default processDataByUser;
