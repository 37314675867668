import React from "react";
import "./terminal.styles.css";
import axios from "axios";
import address from "../address.component";
import logo from "../../assets/icons/Haven_logo_Final_White-01.svg";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import { ConfirmDialog } from "primereact/confirmdialog";
import Keyboard from "./KeyboardInput.component.jsx";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "react-simple-keyboard/build/css/index.css";
import "./PTOrequests.style.css";
import IndepthPTO from "../admin/indepthpto.component";
import { getRemainingAccrue } from "../global_functions/accural";
class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      date: [],
      PTO: [],
      planned: 0,
      pending: 0,
      remaining: 0,
      halfOrFull: { name: "Full" },
      PTORequest: [],
      requestComment: "",
      layoutName: "default",
      commentOpen: false,
      attendanceType: "PTO",
      attendanceTypeList: [],
      halfOrFullList: [{ name: "1st Half" }, { name: "2nd Half" }, { name: "Full" }],
      confirmModal: false,
      msg: "",
      msgArray: [],
    };
  }

  async componentDidMount() {
    await this.callPlanned();
    this.getAttendanceCodes();
    //this.selectDate([new Date()]);
  }

  async callPlanned() {
    await axios.post(address + "/getTerminalPTOData", { userId: this.props.userId || 0 }).then(async (res) => {
      const data = res.data;
      console.log(data);
      this.setState({ PTO: data.days, remaining: data.total, planned: data.planned - data.pending, pending: data.pending });
    });
  }

  getAttendanceCodes() {
    axios.get(address + "/getPTOReasons").then((res) => {
      const data = res.data;
      const result = data.filter((data) => !data.name.includes("PTO") && data.name !== "HOLIDAY" && data.name !== "OVERTIME");
      result.push({ name: "PTO", value: "PTO" });
      result.push({ name: "BOPTO", value: "BOPTO" });
      result.push({ name: "NOPTO", value: "NOPTO" });
      console.log(result);
      this.setState({ attendanceTypeList: result });
    });
  }

  calculateRequest(hours, requestDate) {
    let yos = new Date().getFullYear() - new Date(this.props.userHireDate).getFullYear() - 1;
    let numMonth = (new Date(requestDate).getFullYear() - new Date().getFullYear()) * 12 + (new Date(requestDate).getMonth() - new Date().getMonth());
    let rate = 0;
    if (yos < 2) {
      rate = 10 / 12;
    } else if (yos < 5) {
      rate = 15 / 12;
    } else if (yos < 20) {
      rate = 20 / 12;
    } else if (yos >= 20) {
      rate = 25 / 12;
    } else {
      rate = 0;
    }

    return this.state.remaining + rate * numMonth > hours;
  }
  getRemainingAccrue() {
    let remainingAccrue = "Unknown";
    if (this.props.accrual_rate !== undefined || this.props.accrual_rate !== null) {
      let remainingMonth = 11 - new Date().getMonth();
      remainingAccrue = (remainingMonth * Number(this.props.accrual_rate)).toFixed(2);
    }
    return remainingAccrue;
  }
  async createRequest() {
    if (this.state.date.length === 0) {
      this.toast.show({ severity: "error", summary: "No date select", detail: "You don't have any date selected", life: 3000 });
      return;
    }
    let requestType = this.getButtonLabel();
    console.log(requestType);
    if (this.state.PTORequest === undefined || (this.state.PTORequest.length === 0 && this.state.date !== null && requestType === "Request Day Off")) {
      let days = 0;
      if (this.state.halfOrFull.name.includes("Half")) {
        days = 0.5;
      } else if (this.state.halfOrFull.name === "Full") {
        days = 1;
      }
      let checkDateWithinTwoBusinessDay = await this.checkAllDates(this.state.date);
      //check the attendanceType is PTO or other leaves(PTO will consume the Vacation time other won't)
      if (this.state.attendanceType === "PTO" || this.state.attendanceType === "BOPTO") {
        //Auto calculated the PTO remaining time on the request day, Customer want to be set maximum -40 hours for remaining
        let newRemaining = this.state.remaining - this.state.pending - days * this.state.date.length;

        if (newRemaining >= -8) {
          //waring employee that the PTO remaining hour will be negative
          if (newRemaining >= 0) {
            //check the request date is within 3 day or not
            if (checkDateWithinTwoBusinessDay) {
              await this.createPTORequest();
            } else {
              this.setState({ confirmModal: true, msg: "Warning: The request date is within 2 days!" });
            }
          } else {
            let numberOfDate = Math.ceil((0 - newRemaining) / days);
            let msgArray = this.state.date.map((element, index, array) => {
              if (index >= array.length - numberOfDate) {
                return "Warning: The Remaining time will be negative!";
              } else {
                return "";
              }
            });
            this.setState({ confirmModal: true, msg: "Warning: The Remaining time will be negative!", msgArray: msgArray });
          }
        } else {
          this.toast.show({ severity: "error", summary: "No Enough Hours", detail: "You don't have enough PTO hours remaining for the time off request", life: 3000 });
          return;
        }
      } else {
        //check the request date is within 3 day or not
        if (checkDateWithinTwoBusinessDay) {
          await this.createPTORequest();
        } else {
          this.setState({ confirmModal: true, msg: "Warning: The request date is within 2 days!" });
        }
      }
    } else if (requestType === "Cancel Request") {
      axios.post(address + "/cancelPTORequest", { userId: this.props.userId || 0, date: this.state.date, comment: this.state.requestComment }).then(async (res) => {
        const data = res.data;
        //console.log(data, ":)");
        this.setState({ PTO: data.days, remaining: data.total, planned: data.planned - data.pending, pending: data.pending, requestComment: "", date: [], PTORequest: [] }, () => this.selectDate(this.state.date));
      });
    } else if (requestType === "Refund PTO") {
      axios.post(address + "/refundPTORequest", { userId: this.props.userId || 0, date: this.state.date, comment: this.state.requestComment }).then(async (res) => {
        const data = res.data;
        //console.log(data, ":)");
        this.setState({ PTO: data.days, remaining: data.total, planned: data.planned - data.pending, pending: data.pending, requestComment: "", date: [], PTORequest: [] }, () => this.selectDate(this.state.date));
      });
    }
  }
  getSupervisorComment() {
    let comment = "\n";
    this.state.PTORequest.forEach((element) => (comment += element.year + "-" + element.month + "-" + element.day + ": " + (element.supervisor_Comment === null ? "\n" : element.supervisor_Comment + "\n")));
    return comment;
  }

  async checkAllDates(dates) {
    let notWaring = true;
    let msgArray = dates.map((date) => {
      if (this.checkDateWithinTwoBusinessDay(date)) {
        return "";
      } else {
        notWaring = false;
        return "Warning: The request date is within 2 days!";
      }
    });
    await this.setState({ msgArray: msgArray });
    return notWaring;
  }

  checkDateWithinTwoBusinessDay(date) {
    let day = new Date().getDay();
    console.log(day);
    //if the day is Friday, it will count Monday as next business day, so check it within 4 days actually
    if (day === 5) {
      return (new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) > 3;
    } else if (day === 6 || day === 0) {
      //if the day is Saturday or Sunday, it will get the date of next monday and then check within 2 days for next Monday
      let monday = new Date(new Date().setDate(new Date().getDate() + ((7 - day + 1) % 7)));
      return (new Date(date).getTime() - new Date(monday).getTime()) / (1000 * 60 * 60 * 24) > 1;
    } else {
      //if the day not in Friday, Saturday, Sunday, then just check it within 2 days will be fine
      return (new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) > 1;
    }
  }

  async createPTORequest() {
    console.log(this.state);
    axios.post(address + "/createPTORequest", { userId: this.props.userId || 0, date: this.state.date, halfOrFull: this.state.halfOrFull.name, attendanceType: this.state.attendanceType, comment: this.state.requestComment, waringMessage: this.state.msgArray }).then(async (res) => {
      const data = res.data;
      //console.log(data, ":)");
      this.setState({ PTO: data.days, remaining: data.total, planned: data.planned - data.pending, pending: data.pending, msg: "", requestComment: "", date: [], PTORequest: [] }, () => this.selectDate(this.state.date));
    });
  }

  absencesCalendar = (date) => {
    //console.log(this.state.PTO);
    for (let i = 0; i < this.state.PTO.length; i++) {
      let PTO = this.state.PTO[i];
      if (Number(PTO.month) - 1 === date.month) {
        if (Number(PTO.day) === date.day) {
          if (Number(PTO.year) === date.year) {
            return <div className={"dateDayMimic " + PTO.status}>{date.day}</div>;
          }
        }
      }
    }
    return <div className="dateDayMimic">{date.day}</div>;
  };

  async selectDate(date) {
    console.log(date);
    await this.setState({ date: date });
    let found = false;
    let PTORequest = [];
    for (let j = 0; j < date.length; j++) {
      for (let i = 0; i < this.state.PTO.length; i++) {
        let PTO = this.state.PTO[i];
        if (Number(PTO.month) - 1 === date[j].getMonth()) {
          if (Number(PTO.day) === date[j].getDate()) {
            if (Number(PTO.year) === date[j].getFullYear()) {
              found = true;
              PTORequest.push(this.state.PTO[i]);
            }
          }
        }
      }
    }
    console.log(PTORequest);
    if (found) {
      if (PTORequest.length !== this.state.date.length) {
        this.toast.show({ severity: "error", summary: "Not same status", detail: "Can not select dates in different status", life: 3000 });
        await this.setState({ PTORequest: [], date: [] });
      } else {
        let status = PTORequest[0].status;
        if (PTORequest.every((element) => element.status === status)) {
          await this.setState({ PTORequest: PTORequest });
        } else {
          this.toast.show({ severity: "error", summary: "Not same status", detail: "Can not select dates in different status", life: 3000 });
          await this.setState({ PTORequest: [], date: [] });
        }
      }
    }
    if (found === false) {
      this.setState({ PTORequest: [] });
    }
    console.log(this.state.date);
    console.log(this.state.PTORequest);
  }

  getButtonLabel(status) {
    if (this.state.PTORequest.length === 0) {
      if (status === true) {
        return "Unplanned";
      } else {
        return "Request Day Off";
      }
    } else if (this.state.PTORequest[0].status === "approved") {
      if (status === true) {
        return "Approved";
      } else {
        return "Refund PTO";
      }
    } else if (this.state.PTORequest[0].status === "rejected") {
      if (status === true) {
        return "Rejected";
      } else {
        return "Cancel Request";
      }
    } else if (this.state.PTORequest[0].status === "pending") {
      if (status === true) {
        return "Pending";
      } else {
        return "Cancel Request";
      }
    }
  }

  closeAndSave = (input) => {
    this.setState({ requestComment: input, commentOpen: false });
  };

  render() {
    return (
      <div style={{ display: "flex", flex: "1 1", padding: this.props.operator ? "50px 100px" : "3% 2%", gap: this.props.operator ? "8vw" : "2%", backgroundColor: "#b0b0b0", height: "100%" }}>
        <ConfirmDialog visible={this.state.confirmModal} dismissableMask draggable={false} onHide={() => this.setState({ confirmModal: false })} message={this.state.msg} icon="pi pi-exclamation-triangle" accept={() => this.createPTORequest()} reject={() => this.setState({ confirmModal: false, msg: "" })} />
        <Dialog visible={this.state.commentOpen} style={{ width: "80%", height: "60%", textAlign: "center" }} onHide={() => this.setState({ commentOpen: false })}>
          <Keyboard requestComment={this.state.requestComment} closeAndSave={this.closeAndSave} />
        </Dialog>
        <Dialog resizable={false} draggable={false} dismissableMask visible={this.state.indepthPTOModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ indepthPTOModal: false })} contentStyle={{ width: "100%", height: "auto", overflow: "hidden", borderRadius: ".8em", padding: 0 }} showHeader={false}>
          <IndepthPTO user={null} data={{ ID: this.props.userId || 0 }} />
        </Dialog>
        <div style={{ height: "80%" }}>
          <Calendar value={this.state.date} minDate={this.props.operator ? new Date() : null} id="PTOCalendar" dateTemplate={this.absencesCalendar} inline panelStyle={{ height: "80%", width: "auto", fontSize: "1.8vw", borderStyle: "solid", borderWidth: "5px", borderColor: "red", borderRadius: "8px", boxShadow: "5px 15px 20px #808080" }} placeholder={"Select a range of dates"} ref={(e) => (this.calendar = e)} onChange={(e) => this.selectDate(e.value)} selectionMode="multiple" />
          {this.props.operator ? <Button label={"Indepth PTO"} style={{ marginLeft: "30%", marginRight: "50%", float: "none", fontSize: "2vw", width: "40%", height: "15%", marginTop: "10vh", borderRadius: "15px" }} className="adminPageButtons red" onClick={() => this.setState({ indepthPTOModal: true })}></Button> : null}
        </div>
        <div style={{ backgroundColor: "white", height: "85%", width: "100%", padding: this.props.operator ? "10px 30px" : "1%", textAlign: "center", borderStyle: "solid", borderWidth: "5px", borderColor: "red", borderRadius: "8px", boxShadow: "5px 15px 20px #808080" }}>
          <div style={{ width: "100%", fontSize: "2.5vw" }}>
            PTO
            <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", fontSize: this.props.operator ? "2vw" : "1.5vw", width: "100%" }}>
              <span>Remaining</span>
              <span>Planned</span>
              <span>Will Accrue</span>
              <span>{(Math.round(this.state.remaining * 100) / 100).toFixed(2)} days</span>
              <span>
                {this.state.planned}({this.state.pending}) days
              </span>
              <span>{getRemainingAccrue(this.props.accrual_rate)} days</span>
            </div>
          </div>
          <Calendar disabled={true} style={{ width: "100%", fontSize: "2em" }} value={this.state.date} hourFormat="12" onChange={(e) => this.setState({ newDate: e.value, disabledSecond: false })} selectionMode="multiple" />
          {this.getButtonLabel(true) === "Unplanned" ? (
            <React.Fragment>
              <Dropdown style={{ padding: "0vw 1vw", marginTop: "2vh", width: "100%" }} value={this.state.halfOrFull} className="terminalLargeDropdown" panelClassName="terminalLargeDropdown" placeholder={"Half or Full"} options={this.state.halfOrFullList} onChange={(e) => this.setState({ halfOrFull: e.value })} optionLabel="name" />
              <Dropdown style={{ padding: "0vw 1vw", marginTop: "2vh", width: "100%" }} value={this.state.attendanceType} className="terminalLargeDropdown" panelClassName="terminalLargeDropdown" placeholder={"Request Type"} options={this.state.attendanceTypeList} onChange={(e) => this.setState({ attendanceType: e.value })} optionLabel="name" />{" "}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ScrollPanel className="custombar1" style={{ textAlign: "left", margin: "auto", border: "0.01em solid black", whiteSpace: "pre-line", wordBreak: "break-all", wordWrap: "break-word", float: "none", fontSize: "1vw", width: "100%", height: "15vh", marginTop: "2vh" }}>
                <div style={{ paddingLeft: "1vw" }}>Message from supervisor: {this.getSupervisorComment()}</div>
              </ScrollPanel>
              <Button label={this.state.PTORequest.attendance_Code === undefined ? this.getButtonLabel(true) : this.state.PTORequest.attendance_Code + " " + this.getButtonLabel(true)} style={{ float: "none", fontSize: "2.5vw", width: "100%", height: "auto", marginTop: "2vh", backgroundColor: "white", color: "black" }} />
            </React.Fragment>
          )}
          {this.props.operator ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto auto", fontSize: "2vw" }}>
              <span style={{ display: "inline-block", textAlign: "left" }}>
                <Button label={"Comment"} style={{ float: "none", fontSize: "2vw", width: "100%", height: "80%", marginTop: "5vh", borderRadius: "15px" }} className="adminPageButtons red" onClick={() => this.setState({ commentOpen: true })}></Button>
              </span>
              <span style={{ display: "inline-block", textAlign: "right" }}>
                <Button label={this.getButtonLabel()} style={{ float: "none", fontSize: "2vw", width: "95%", height: "80%", marginTop: "5vh", borderRadius: "15px" }} className="adminPageButtons red" onClick={() => this.createRequest()}></Button>
              </span>
            </div>
          ) : null}
        </div>
        <Toast ref={(el) => (this.toast = el)} />
      </div>
    );
  }
}

export default Page;
