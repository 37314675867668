import React from "react";
import { Layout } from "antd";
import Boxes from "../../components/reports/xdays.component";
import Header from "../../components/header/generalheader.component";
const { Content } = Layout;
const MTUPage = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <Layout>
      <Layout className="site-layout">
        <Content style={{ margin: "0" }}>
          {/*
    <div className="site-layout-background" style={{ padding: 0, height: '100vh',cursor:'none'}}>
*/}
          <Header />
          <div className="site-layout-background">
            <Boxes />
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MTUPage;
