import React from "react";
import "./admin.styles.css";
import "./login.styles.css";
import axios from "axios";
import address from "../address.component";
import { Button, Input, Form, Space } from "antd";
import { Toast } from "primereact/toast";
import { withRouter } from "react-router-dom";
import LoadingScreen from "../loading/loading.component.jsx";

class LoginBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      newUser: false,
      page: this.props.history.location.pathname.split("/")[1],
      loading: false,
    };
    this.unlisten = this.props.history.listen((location, action) => {
      console.log(location);
      this.setState({ page: location.pathname.split("/")[1] });
    });
  }

  hidePassword() {
    let target = this.state.password;
    let pass = "";
    for (let i = 0; i < target.length; i++) {
      if (i === target.length - 1) {
        pass += target[i];
      } else {
        pass += "*";
      }
    }
    return pass;
  }

  setPassword(password, e) {
    console.log(e);
    let string = this.state.password;
    if (e.nativeEvent.inputType !== "deleteContentBackward") {
      string += password.slice(password.length - 1);
    } else {
      string = string.slice(0, string.length - 1);
    }
    this.setState({ password: string });
  }

  callLogin(input) {
    console.log(input);
    let user = input.email;
    let pass = input.password;
    if (user === "" && pass === "") {
      this.toast.show({ severity: "error", summary: "No Username & Password Entered", detail: "please enter a username and password", life: 3000 });
      return;
    }
    if (user === "") {
      this.toast.show({ severity: "error", summary: "No Username Entered", detail: "please enter a username", life: 3000 });
      return;
    }
    if (pass === "") {
      this.toast.show({ severity: "error", summary: "No Password Entered", detail: "please enter a password", life: 3000 });
      return;
    }
    axios.post(address + "/adminLogin", { user: user, pass: pass }).then((res) => {
      const data = res.data;
      console.log(data);
      if (data.err) {
        this.toast.show({ severity: "error", summary: data.err });
      } else {
        if (pass === "newuser") {
          this.setState({ id: data.id, user: input.email, newUser: true, password: "" }, () => console.log(this.state));
          console.log("damn formatter");
        } else {
          this.props.loginCallback(data);
        }
      }
    });
  }

  updatePassword({ password }) {
    if (password === "newuser") {
      this.toast.show({ severity: "error", summary: "Cant have that as your new password" });

      return;
    }
    console.log(password);
    axios.post(address + "/updatePassword", { user: this.state.user, pass: password, id: this.state.id }).then((res) => {
      const data = res.data;
      console.log(data);
      if (data.err) {
        this.toast.show({ severity: "error", summary: data.err });
      } else {
        this.props.loginCallback(data);
      }
    });
  }

  async requestResetPassWord(input) {
    this.setState({ loading: true });
    await axios.post(address + "/auth/requestReset", input).then((success) => {
      console.log(success);
      this.setState({ loading: false });
      if (success?.data === "Account not valid") {
        this.toast.show({ severity: "error", summary: "Your email is not recognized. Please try again!" });
      } else if (success?.data === "Account Disabled") {
        this.toast.show({ severity: "error", summary: "Your account is disabled and cannot request password reset!" });
      } else {
        this.props.history.push("/admin");
      }
    });
  }

  async resetPassword(input) {
    this.setState({ loading: true });
    const token = this.props.history.location.pathname.split("/reset-password/")[1];
    if (token) {
      await axios.post(address + "/auth/validateToken", { token }).then(async (res) => {
        const success = res?.data;
        console.log(success);
        if (success) {
          await axios.post(address + "/auth/updateUserPass", { password: input.password, token: token }).then((res) => {
            const result = res.data;
            console.log(result);
            if (result === false) {
              this.toast.show({ severity: "error", summary: "Password Reset Failed!" });
            } else {
              this.toast.show({ severity: "success", summary: "Password Reset Success!" });
            }
          });
        } else {
          this.toast.show({ severity: "error", summary: "Your password reset is expired!" });
        }
      });
    } else {
      this.toast.show({ severity: "error", summary: "Your password reset is invalid or unauthorized!" });
    }
    setTimeout(
      function () {
        this.setState({ loading: false });
        this.props.history.push("/admin");
      }.bind(this),
      3000
    );
  }

  checkEmailFormat(value) {
    if (value === "" || value === null || value === undefined) {
      return Promise.reject(new Error("Please enter the email!"));
    } else if (value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid email!"));
    }
  }

  render() {
    if (this.state.newUser) {
      return (
        <>
          <LoadingScreen show={this.state.loading} />
          <Toast ref={(el) => (this.toast = el)} />
          <div className="login-component">
            <p className="application-name">Time Tracking</p>
            <div className="login-content-container">
              <div className="login-container">
                <Form ref={this.form} layout="vertical" requiredMark={false} onFinish={(input) => this.updatePassword(input)}>
                  <Form.Item label="New Password" name="password" rules={[{ required: true }]}>
                    <Input.Password visibilityToggle={true} placeholder="Enter your password" autoComplete="current-password" />
                  </Form.Item>
                  <Form.Item className="space-up">
                    <Button htmlType="submit" className="primary-btn" loading={false}>
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (this.state.page === "request-reset") {
      return (
        <>
          <LoadingScreen show={this.state.loading} />
          <Toast ref={(el) => (this.toast = el)} />
          <div className="login-component">
            <p className="application-name">Time Tracking</p>
            <div className="login-content-container">
              <div className="login-container">
                <h3 className="login-title">Reset Password</h3>
                <p>Please enter your email to request a password reset</p>
                <Form ref={this.form} layout="vertical" requiredMark={false} onFinish={(input) => this.requestResetPassWord(input)}>
                  <Form.Item label="email" name="email" rules={[{ required: true, message: "" }, { validator: (_, value) => this.checkEmailFormat(value) }]}>
                    <Input placeholder="Please enter your email address" />
                  </Form.Item>
                  <Form.Item className="space-up">
                    <Space className="button-group">
                      <Button htmlType="submit" className="primary-btn" loading={false}>
                        Reset password
                      </Button>
                      <Button htmlType="button" className="primary-btn" loading={false} onClick={() => this.props.history.push("/admin")}>
                        Back
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (this.state.page === "reset-password") {
      return (
        <>
          <LoadingScreen show={this.state.loading} />
          <Toast ref={(el) => (this.toast = el)} />
          <div className="login-component">
            <p className="application-name">Time Tracking</p>
            <div className="login-content-container">
              <div className="login-container">
                <Form ref={this.form} layout="vertical" requiredMark={false} onFinish={(input) => this.resetPassword(input)}>
                  <Form.Item
                    label="Enter New Password"
                    name="password"
                    rules={[
                      { required: true, message: "Please input a new password" },
                      { min: 6, message: "Must be minimum 6 characters" },
                    ]}
                  >
                    <Input.Password visibilityToggle={true} placeholder="Enter your password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm"
                    rules={[
                      { required: true, message: "Please confirm your password!" },
                      { min: 6, message: "Password must be minimum 6 characters." },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Your passwords do not match!"));
                        },
                      }),
                    ]}
                  >
                    <Input.Password visibilityToggle={true} placeholder="Confirm your password" />
                  </Form.Item>
                  <Form.Item className="space-up">
                    <Space className="button-group">
                      <Button htmlType="submit" className="primary-btn" loading={false}>
                        Reset Password
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <LoadingScreen show={this.state.loading} />
        <div className="login-component">
          <p className="application-name">Time Tracking</p>
          <div className="login-content-container">
            <div className="login-container">
              <Form ref={this.form} layout="vertical" requiredMark={false} onFinish={(input) => this.callLogin(input)}>
                <Form.Item label="Username" name="email" rules={[{ required: true }]}>
                  <Input placeholder="Please enter your username" autoComplete="username" />
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                  <Input.Password visibilityToggle={false} placeholder="Please enter your password" autoComplete="current-password" />
                </Form.Item>
                <Form.Item className="space-up">
                  <Space className="button-group">
                    <Button htmlType="submit" className="primary-btn" loading={false}>
                      Log in
                    </Button>
                    <Button htmlType="button" className="primary-btn" loading={false} onClick={() => this.props.history.push("/request-reset")}>
                      Forgot Password?
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LoginBox);
