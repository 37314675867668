import isDaylightSavingTime from "./checkDaylightSaving.function";

function calcTotalHours({ start, end, timeMS, userInfo, breaks, shift, attendance, approved, shiftHour, newBreaks, breakShift }) {
  //Code written by Tyler
  if (approved) {
    //if the record is approved, using the hours value
    return Number(shiftHour);
  }

  let timeToRemove = 0;
  let breakInfo = userInfo.breakInfo || [];
  breaks = breaks.breakInfo;
  if (breakInfo.length === 0) {
    //use the default break time
    if (breakShift === null) {
      //still the old records using pervious method to calculate
      if (breaks) {
        for (let i = 0; i < breaks.length; i++) {
          //*HAS TO BE A COPY OTHERWISE BREAK GOES INFINITELY UP!
          let brk = JSON.parse(JSON.stringify(breaks[i])); // cant use break because it is a key word, so brk it is.
          if (brk.description === "missing data") {
            continue;
          }
          let startBreak = new Date(start);
          let endBreak = new Date(start);
          let startToUse = new Date(start);
          let endToUse = new Date(end);
          let changed = false;
          if (isDaylightSavingTime(startBreak)) {
            brk.item1++;
            brk.item3++;
          }
          if (startToUse.getUTCDate() !== endToUse.getUTCDate()) {
            //the start Time and end Time is not int same day, need to check the break in start or end
            if (((brk.item3 < startToUse.getUTCHours() || (Number(brk.item3) === startToUse.getUTCHours() && brk.item4 < startToUse.getUTCMinutes())) && brk.item1 < endToUse.getUTCHours()) || (Number(brk.item1) === endToUse.getUTCHours() && brk.item2 < endToUse.getUTCMinutes())) {
              //if the break time is in the second day means the end break hour is less than the start hour and start break hour is less than the end hour
              startBreak = new Date(end);
              endBreak = new Date(end);
            }
          }
          startBreak = new Date(startBreak.setUTCHours(brk.item1));
          startBreak = new Date(startBreak.setUTCMinutes(brk.item2));
          endBreak = new Date(endBreak.setUTCHours(brk.item3));
          endBreak = new Date(endBreak.setUTCMinutes(brk.item4));

          if (startToUse <= endBreak && startBreak <= endToUse && startBreak < endBreak) {
            // changed = true;
            if (startToUse <= startBreak) {
              startToUse = new Date(startBreak);
              changed = true;
            }
            if (endToUse >= endBreak) {
              changed = true;
              endToUse = new Date(endBreak);
            }
            let netTime = new Date(endToUse).getTime() - new Date(startToUse).getTime();
            if (changed === true && netTime > 0) {
              timeToRemove += netTime;
              break;
            }
          }
        }
      }
    } else {
      //if not calculate the hours
      let midShiftTime = new Date((new Date(end).getTime() + new Date(start).getTime()) / 2);
      let currentDay = midShiftTime.getDay();
      let newBreak = newBreaks.filter((element) => Number(element.Day) === currentDay && element.SubDivision === element.SubDivision && Number(element.Shift) === Number(breakShift));
      if (newBreak.length !== 0) {
        let brkStart = newBreak[0].Break_Start;
        let brkEnd = newBreak[0].Break_End;
        let startBreak = new Date(start);
        let endBreak = new Date(start);
        let startToUse = new Date(start);
        let endToUse = new Date(end);
        let changed = false;
        if (isDaylightSavingTime(startBreak)) {
          brkStart += 60;
          brkEnd += 60;
        }
        if (startToUse.getUTCDate() !== endToUse.getUTCDate()) {
          //the start Time and end Time is not int same day, need to check the break in start or end
          if (((brkEnd / 60 < startToUse.getUTCHours() || (brkEnd / 60 === startToUse.getUTCHours() && brkEnd % 60 < startToUse.getUTCMinutes())) && brkStart / 60 < endToUse.getUTCHours()) || (brkStart / 60 === endToUse.getUTCHours() && brkStart % 60 < endToUse.getUTCMinutes())) {
            //if the break time is in the second day means the end break hour is less than the start hour and start break hour is less than the end hour
            startBreak = new Date(end);
            endBreak = new Date(end);
          }
        }
        startBreak = new Date(startBreak.setUTCHours(brkStart / 60, brkStart % 60));
        endBreak = new Date(endBreak.setUTCHours(brkEnd / 60, brkEnd % 60));

        if (startToUse <= endBreak && startBreak <= endToUse && startBreak < endBreak) {
          // changed = true;
          if (startToUse <= startBreak) {
            startToUse = new Date(startBreak);
            changed = true;
          }
          if (endToUse >= endBreak) {
            changed = true;
            endToUse = new Date(endBreak);
          }
          let netTime = new Date(endToUse).getTime() - new Date(startToUse).getTime();
          if (changed === true && netTime > 0) {
            timeToRemove += netTime;
          }
        }
      }
    }
  } else {
    //use the customize break time
    for (let i = 0; i < breakInfo.length; i++) {
      //*HAS TO BE A COPY OTHERWISE BREAK GOES INFINITELY UP!
      let userInfo = JSON.parse(JSON.stringify(breakInfo[i]));
      if (userInfo.break_start_hour === null || userInfo.break_start_min === null || userInfo.break_end_hour === null || userInfo.break_end_min === null) {
        continue;
      }

      let startBreak = new Date(start);
      let endBreak = new Date(start);
      let startToUse = new Date(start);
      let endToUse = new Date(end);
      let changed = false;
      if (isDaylightSavingTime(startBreak)) {
        userInfo.break_start_hour++;
        userInfo.break_end_hour++;
      }

      if (startToUse.getUTCDate() !== endToUse.getUTCDate()) {
        //the start Time and end Time is not int same day, need to check the break in start or end
        if ((userInfo.break_end_hour < startToUse.getUTCHours() || (Number(userInfo.break_end_hour) === startToUse.getUTCHours() && userInfo.break_end_min < startToUse.getUTCMinutes())) && (userInfo.break_start_hour < endToUse.getUTCHours() || (Number(userInfo.break_start_hour) === endToUse.getUTCHours() && userInfo.break_start_min < endToUse.getUTCMinutes()))) {
          //if the customize break time is in the second day
          startBreak = new Date(end);
          endBreak = new Date(end);
        }
      }
      startBreak = new Date(startBreak.setUTCHours(userInfo.break_start_hour));
      startBreak = new Date(startBreak.setUTCMinutes(userInfo.break_start_min));
      endBreak = new Date(endBreak.setUTCHours(userInfo.break_end_hour));
      endBreak = new Date(endBreak.setUTCMinutes(userInfo.break_end_min));
      //if break interval is in shift interval
      if (startToUse <= endBreak && startBreak <= endToUse && startBreak < endBreak) {
        // changed = true;
        if (startToUse <= startBreak) {
          startToUse = new Date(startBreak);
          changed = true;
        }
        if (endToUse >= endBreak) {
          changed = true;
          endToUse = new Date(endBreak);
        }
        let netTime = new Date(endToUse).getTime() - new Date(startToUse).getTime();

        if (changed === true && netTime > 0) {
          timeToRemove += netTime;
        }
      }
    }
  }

  timeMS = timeMS || new Date(end).getTime() - new Date(start).getTime();
  let hours = (timeMS - timeToRemove) / 1000 / 60 / 60;
  hours = Number(hours.toFixed(2));
  //below code literally just fixes any slight intervals
  let roundedHours = hours - (hours % 1);
  let minutes = Math.round((hours % 1) * 60);

  let remainder = minutes % 15;
  minutes = minutes - remainder;
  if (remainder >= 7.5) {
    minutes += 15;
  }
  let total = roundedHours + minutes / 60;
  // todo this is painful, should go back and add new field to SQL for this kind of nonsense
  if (attendance === "NCNS" || attendance === "NOPTO" || attendance === null || attendance === "ML" || attendance === "MIL" || attendance === "FMLA" || attendance === "SUS" || attendance === "COVID" || attendance === "FLUN" || attendance === "OFF") {
    total = 0;
  }

  if (total < 0) {
    return 0;
  } else {
    return total;
  }
}

export default calcTotalHours;
