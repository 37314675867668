import React from "react";
import { Layout } from "antd";
import Boxes from "../../components/boxes/terminal.component";

const { Content } = Layout;
const MTUPage = (props) => (
  <Layout style={{ minHeight: "100vh" }}>
    <Layout>
      <Layout className="site-layout">
        <Content style={{ margin: "0" }}>
          {/*
    <div className="site-layout-background" style={{ padding: 0, height: '100vh',cursor:'none'}}>
*/}
          <div className="site-layout-background" style={{ padding: 0, height: "100vh", overflow: "hidden" }}>
            <Boxes div={props.div} />
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MTUPage;
