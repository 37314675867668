import React from "react";
import axios from "axios";
import address from "../address.component";
import "./confirmation_modal.styles.css";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";

import calcTotalHours from "../global_functions/calcTotalHours.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      hasPTO: false,
      hasBOPTO: false,
      hasEnoughRemaining: true,
      hasEnoughBRemaining: true,
      checkedPTO: true,
      checkedBOPTO: true,
      disabledBOPTO: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.getAttendanceCodes();
    // this.getPTOData();
    //todo call breaks api... like literally get breaks data
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
      console.log("hey!!! THIS IS DID UPDATE!");
      if (this.props.rows.length > 0) {
        this.processPTOHours();
      }
    }
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  async processPTOHours() {
    let totalPTO = 0;
    let totalBonus = 0;
    let userId = this.props.rows[0].user_id;
    let StateToSet = {
      checkedPTO: true,
      checkedBOPTO: true,
      hasPTO: false,
      hasBOPTO: false,
      hasEnoughRemaining: true,
      hasEnoughBRemaining: true,
      disabledBOPTO: false,
    };
    console.log(this.props);
    for (let i = 0; i < this.props.rows.length; i++) {
      let rowData = this.props.rows[i];
      //for some record before in test database there is not attendance
      if (rowData.attendance === null || rowData.attendance === undefined) {
        //invalid record moving to the next.
        //todo should pass error back to user.
        continue;
      }
      if (rowData.attendance.includes("PTO") && !rowData.attendance.includes("NOPTO") && !rowData.attendance.includes("BOPTO")) {
        StateToSet.hasPTO = true;
        if (rowData.approved !== true) {
          totalPTO += rowData.hours;
          //calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.props.breaks, shift: rowData.user.shift || 1, attendance: getHighestAttendance(rowData.attendance, this.state.attendanceCodes), approved: rowData.approved || false, shiftHour:rowData.hours, newBreaks:this.props.newBreaks,breakShift:rowData.break_shift });
        }
      } else if (rowData.attendance.includes("BOPTO")) {
        StateToSet.hasBOPTO = true;
        if (rowData.approved !== true) {
          totalBonus += rowData.hours;
          //calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.props.breaks, shift: rowData.user.shift || 1, attendance: getHighestAttendance(rowData.attendance, this.state.attendanceCodes), approved: rowData.approved || false, shiftHour:rowData.hours, newBreaks:this.props.newBreaks,breakShift:rowData.break_shift });
        }
      }
    }
    const PTOData = await this.getPTOData(userId);
    if (totalBonus > 0) {
      StateToSet.hasEnoughBRemaining = PTOData.bonus * 8 >= totalBonus;
      if (totalBonus > PTOData.bonus * 8 && this.props.typeOfConfirm.includes("approval")) {
        this.toast.show({ severity: "error", summary: "Not enough BOPTO", detail: "Don't have enough BOPTO to consume", life: 5000 });
        StateToSet.checkedBOPTO = false;
      }
    }

    if (totalPTO > 0) {
      StateToSet.hasEnoughRemaining = PTOData.remaining * 8 >= totalPTO;
    }
    console.log("totalPTO", totalPTO);
    console.log(StateToSet);
    StateToSet.totalPTO = totalPTO;
    this.setState(StateToSet, () => console.log(this.state));
  }

  async getPTOData(userId) {
    const data = await axios.post(address + "/getTerminalPTOData", { userId: userId || 0 }).then(async (res) => {
      const data = res.data;
      console.log(data);
      // this does not need a set state nor should it be! its only used once.
      //todo remove this set state after confirming it is harmless to remove.
      this.setState({ remaining: data.remaining, planned: data.planned, bonus: data.bonus });
      return { remaining: data.remaining, planned: data.planned, bonus: data.bonus };
    });
    return data;
  }

  renderCheckbox(pto) {
    return (
      <div>
        <div className="" style={{ display: "grid", justifyContent: "center", gridTemplateColumns: "4vw auto" }}>
          <input type="checkbox" style={{ height: "4vh", width: "4vh" }} disabled={pto === "BOPTO" ? !this.state.hasEnoughBRemaining : ""} checked={this.state["checked" + pto]} onChange={() => this.setState({ [`${"checked" + pto}`]: !this.state["checked" + pto] })} />
          <span className="proceed-text" style={{ lineHeight: "4vh" }}>
            {this.props.rows[0].approved ? (this.state["checked" + pto] ? "Unapproving this will refund " + pto : "Unapproving this will not refund " + pto) : this.state["checked" + pto] ? "approving this will consume " + pto : "approving this will not consume " + pto}
          </span>
        </div>
        <>{this.props.rows[0].approved === true ? <></> : pto === "BOPTO" ? !this.state.hasEnoughBRemaining ? this.warningMessage(pto) : <></> : !this.state.hasEnoughRemaining ? this.warningMessage(pto) : <></>}</>
      </div>
    );
  }

  warningMessage(pto) {
    if (pto === "BOPTO") {
      return <Message style={{ marginLeft: "auto", justifyContent: "center", width: "100%" }} severity="warn" text={"Cant consume BOPTO user does not have enough, please adjust the record."} />;
    }
    return <Message style={{ marginLeft: "auto", justifyContent: "center", width: "100%" }} severity="warn" text={"If you consume " + pto + " the remaining " + pto + " balance will be negative"} />;
  }

  async accept() {
    if (this.props.accept) {
      let checkObject = {
        bopto: this.state.checkedBOPTO,
        pto: this.state.checkedPTO,
      };
      await this.props.accept(checkObject);
    } else {
      console.log("ERROR~ approve prop missing");
    }
  }

  reject() {
    if (this.props.reject) {
      this.props.clearRow();
      this.props.reject();
    } else {
      console.log("ERROR~ reject prop missing");
    }
  }

  hide() {
    if (this.props.hide) {
      this.props.hide();
    } else {
      console.log("ERROR~ hide prop missing");
    }
  }

  formatTime(rowData, field, modified) {
    let time = rowData[field];
    if (time === null) {
      return "N/A";
    }
    let color = true;
    let approved = rowData.approved;
    if (field.includes(modified) || (modified === "both" && approved) || !field.includes("adj") || rowData.approved === true) {
      color = false;
    }
    let hours = new Date(time).getHours();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    let minutes = new Date(time).getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return <div style={{ borderRadius: "5px" }}>{new Date(time).toLocaleDateString() + " " + hours + ":" + minutes + " " + ampm}</div>;
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Dialog className="proceed-container" resizable={false} draggable={false} visible={this.props.visible} dismissableMask onHide={() => this.hide()} showHeader={false}>
          <div className="proceed-content">
            <i className="pi pi-exclamation-triangle proceed-icon" />
            <h4 className="proceed-text">{this.props.message}</h4>
          </div>

          <DataTable ref={(e) => (this.shiftManagement = e)} scrollHeight="50vh" scrollable editMode="cell" value={this.props.rows}>
            <Column header="Employee Name" field={"user.name"} style={{ width: "10.5%" }} />
            <Column
              field="adj_start_time"
              header="Adj. Start"
              body={(rowData, options) => {
                return this.formatTime(rowData, options.field, rowData.modified);
              }}
              editor={(e) => this.TimeEditor(e)}
            />

            <Column
              field="adj_end_time"
              header="Adj. End"
              body={(rowData, options) => {
                return this.formatTime(rowData, options.field, rowData.modified);
              }}
              editor={(e) => this.TimeEditor(e)}
            />

            <Column header="Attend Code" field="attendance" style={{ width: "12.5%" }} editor={(e) => this.AttendanceEditor(e)} />
            <Column
              header="Hours"
              field={"hours"}
              style={{ width: "5%" }}
              body={(rowData) => {
                return rowData.hours;
                // return calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.props.breaks, shift: rowData.user.shift || 1, attendance: getHighestAttendance(rowData.attendance, this.state.attendanceCodes) || "", approved: rowData.approved || false, shiftHour:rowData.hours, newBreaks:this.props.newBreaks,breakShift:rowData.break_shift });
              }}
              onCellEditComplete={this.onCellEditComplete}
            />
            <Column
              header="Late / Left Early"
              headerStyle={{ fontSize: "1em" }}
              className="primereactfix-center-column-header"
              style={{ flexBasis: "7em", flexShrink: 0, flexGrow: 0 }}
              body={(rowData, column) => {
                return <Checkbox checked={rowData.late === null || rowData.late === "0" ? false : rowData.late === "1" ? true : rowData.late}></Checkbox>;
              }}
            />
          </DataTable>

          {this.state.hasPTO && this.props.typeOfConfirm.includes("approval") && this.props.rows.length > 0 ? this.renderCheckbox("PTO") : null}
          {this.state.hasBOPTO && this.props.typeOfConfirm.includes("approval") && this.props.rows.length > 0 ? this.renderCheckbox("BOPTO") : null}
          <div className="btn-grid">
            <button className="btn adminPageButtons white" onClick={() => this.reject()}>
              No
            </button>
            <button className="btn adminPageButtons red" onClick={() => this.accept()}>
              Yes
            </button>
          </div>
        </Dialog>
      </>
    );
  }
}

export default ConfirmationDialog;
