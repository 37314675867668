import React from "react";
import { withRouter } from "react-router-dom";
import { UPDATE_VERSION } from "../../redux2/types";
import { store } from "../../redux2/store";

import { Layout, Menu } from "antd";
import { DoubleLeftOutlined } from "../../assets/icons/custom-antd/DoubleLeftOutlined";
import { DoubleRightOutlined } from "../../assets/icons/custom-antd/DoubleRightOutlined";
import { connect } from "react-redux";
import { setDefault } from "../../functions/setDefault";
import { UserOutlined, BookOutlined, ExportOutlined, ClockCircleOutlined, TeamOutlined, CalendarOutlined, ScheduleOutlined } from "@ant-design/icons";
import axios from "axios";
import address from "../address.component";
import "./sidebar.styles.css";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
    version: state.version,
  };
}

function updateVersion(version) {
  store.dispatch({
    version,
    type: UPDATE_VERSION,
  });
  // window.location.reload();
}

const { Sider } = Layout;
const { SubMenu } = Menu;
const TabController = [
  { key: "1", url: "/admin" },
  { key: "2", url: "/report/timesheet" },
  { key: "3", url: "/report/attendance" },
  { key: "4", url: "/report/dataexport" },
  { key: "5", url: "/report/routingexport" },
  { key: "6", url: "/report/paylocityexport" },
  { key: "7", url: "/report/workcenter" },
  { key: "8", url: "/report/posting" },
  { key: "9", url: "/report/attendanceSheet" },
  { key: "10", url: "/adminUser" },
  { key: "11", url: "/report/census" },
  { key: "12", url: "/holiday" },
  { key: "13", url: "/schedule" },
];

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: setDefault(this.props.location.collapsed, false),
    };
  }
  componentDidMount() {
    this.checkVersion();
    setInterval(() => this.checkVersion(), 1200000);
  }

  checkVersion() {
    axios.get(`${address}/GetVersion`).then((res) => {
      var version = res.data;
      if (version.err) {
      } else {
        if (version.data !== this.props.version) {
          updateVersion(version.data);
          setTimeout(() => window.location.reload(), 10000);
        }
      }
    });
  }

  getSelectedKey = () => {
    const { pathname } = this.props.location;
    const key = TabController.find((tab) => tab.url === pathname).key;
    return [key];
  };

  renderTrigger = () => {
    const { collapsed } = this.state;
    return collapsed ? <DoubleRightOutlined style={{ fontSize: "28px" }} /> : <DoubleLeftOutlined />;
  };

  render() {
    const { collapsed } = this.state;
    const selectedKey = this.getSelectedKey();
    return (
      <Sider id="sidebar" className="sidebar-body hideMe" width={240} collapsible collapsedWidth={90} collapsed={collapsed} onCollapse={this.handleCollapse} trigger={this.renderTrigger()}>
        <Menu theme="dark" selectedKeys={selectedKey} mode="inline" onClick={this.handleChangeMenu}>
          <Menu.Item key="1" icon={<UserOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "23px", top: "1px" }} />}>
            Employee Cards
          </Menu.Item>
          <Menu.Item key="2" icon={<ClockCircleOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "23px", top: "-2px" }} />}>
            Time Sheets
          </Menu.Item>
          <SubMenu key="sub1" icon={<BookOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "25px", top: "1px" }} />} title="Reports">
            <Menu.Item key="3">Attendance</Menu.Item>
            <Menu.Item key="7">Performance</Menu.Item>
            <Menu.Item key="9">Attendance Sheet</Menu.Item>
            <Menu.Item key="8">Posting Data</Menu.Item>
          </SubMenu>
          {this.props.user.permissions === "ALL" ? (
            <>
              <SubMenu key="sub2" icon={<ExportOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "25px", top: "-1px" }} />} title="Exports">
                <Menu.Item key="4">Time Clock</Menu.Item>
                <Menu.Item key="5">Routing App</Menu.Item>
                <Menu.Item key="6">Paylocity</Menu.Item>
                <Menu.Item key="11">Census</Menu.Item>
              </SubMenu>
              <Menu.Item key="10" icon={<TeamOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "23px", top: "1px" }} />}>
                Admin Users
              </Menu.Item>
              <Menu.Item key="12" icon={<CalendarOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "23px", top: "1px" }} />}>
                Holiday
              </Menu.Item>
              <Menu.Item key="13" icon={<ScheduleOutlined className="sidebar-icon" style={{ fontSize: "30px", left: "23px", top: "1px" }} />}>
                Schedule
              </Menu.Item>
            </>
          ) : (
            <></>
          )}
        </Menu>
        <div className="sidebar-footer">v{this.props.version}</div>
      </Sider>
    );
  }

  handleCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  handleChangeMenu = ({ key }) => {
    const { collapsed } = this.state;
    const { history } = this.props;
    const url = TabController.find((tab) => tab.key === key).url;
    return history.push({ pathname: url, collapsed: collapsed });
  };
}

export default connect(mapStateToProps)(withRouter(SideBar));
