import React from "react";
import axios from "axios";
import address from "../../address.component";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import ReportSetting from "../../reportSetting/reportSetting.component";
import LoadingScreen from "../../loading/loading.component.jsx";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultDate: [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))],
      date: [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))],
      user: null,
      shift: this.props.user.shift || "ALL",
      department: this.props.user.department || "ALL",
      expandedRows: null,
      posts: [],
      header: ["Name", "shift", "Time Posted", "Action", "Work Center", "Part Posted", "Op No.", "Part No.", "Hours"],
    };
  }

  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let headers = this.state.header;
    let data = this.state.posts;
    for (let i = 0; i < headers.length; i++) {
      csv += headers[i] + ",";
    }
    csv += "\n";
    for (let i = 0; i < data.length; i++) {
      let s = data[i];
      csv += s.user.first_name + " " + s.user.last_name + ",";
      csv += s.user.shift + ",";
      csv += new Date(s.time).toLocaleString().replace(",", " ") + ",";
      csv += s.action ? s.action + "," : ",";
      csv += s.work_center ? s.work_center + "," : ",";
      csv += s.parts_posted ? s.parts_posted + "," : ",";
      csv += s.op_no ? s.op_no + "," : ",";
      csv += s.part_no ? s.part_no + "," : ",";
      csv += s.hours ? this.covertHourToHourMins(s.hours, "excel") + "," : ",";
      csv += "\n";
    }
    csv += "\n";
    this.download(csv, "postingDataExport");
  }

  // convertTimeToHours(time) {
  //   let date = new Date(time);
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let ampm = "am";
  //   if (minutes < 10) {
  //     minutes = 0 + JSON.stringify(minutes);
  //   }
  //   if (hours > 12) {
  //     hours -= 12;
  //     ampm = "pm";
  //   }
  //   console.log(time, hours, minutes);
  //   return hours + ":" + minutes + " " + ampm;
  // }

  getReport() {
    if (this.state.date[1] === null || this.state.date[1] === undefined) {
      this.toast.show({ severity: "error", summary: "Please select The right date", detail: "Please select The right date", life: 3000 });
      return;
    }
    let endDate = new Date(this.state.date[1]);
    this.setState({ loading: true });
    endDate = new Date(new Date(endDate).setDate(endDate.getDate() + 1)).toISOString();
    let userId = this.state.user === null || this.state.user === undefined ? null : this.state.user.ID;
    axios
      .post(address + "/RoutingDataExport", {
        start_time: new Date(this.state.date[0]).toISOString(),
        end_time: endDate,
        userId: userId,
        department: this.state.department || "ALL",
        shift: this.state.shift || "ALL",
        division: this.props.user.division,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.setState({ posts: data }, () => this.setState({ visible: true, loading: false }));
      });
  }

  // formatTime(rowData, field, modified) {
  //   let time = rowData[field];
  //   if (time === null) {
  //     return "N/A";
  //   }
  //   let color = true;
  //   if (field.includes(modified) || modified === "both" || !field.includes("adj") || rowData.approved === true) {
  //     color = false;
  //   }
  //   let hours = new Date(time).getHours();
  //   let ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
  //   let minutes = new Date(time).getMinutes();
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   return <div style={{ border: color ? " 1px solid red" : "", borderRadius: "5px" }}>{new Date(time).toLocaleDateString() + " " + hours + ":" + minutes + " " + ampm}</div>; // return new Date(time).toLocaleDateString();
  // }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  runReport = async (data) => {
    await this.setState(data);
    this.togglePop();
    this.getReport();
  };

  headerTemplate(rowData) {
    return (
      <div style={{ display: "inline-block", width: "100%", display: "grid", gridTemplateColumns: "10% 15% 15% 15% 15% 13%" }}>
        <span>{rowData.user.first_name + " " + rowData.user.last_name}</span>
        <span>{rowData.user.shift}</span>
      </div>
    );
  }

  expandAll() {
    let expandRows = {};
    this.state.posts.forEach((ele) => {
      expandRows[`${ele.user.pin}`] = true;
    });
    this.setState({ expandedRows: expandRows });
  }

  covertHourToHourMins(totalHours, type) {
    let hour = Math.floor(totalHours);
    let mins = Math.floor((totalHours % 1) * 60);
    let second = Math.floor((((totalHours % 1) * 60) % 1) * 60);
    if (type === "app") {
      return hour + " hours " + mins + " mins";
    } else {
      return hour + ":" + mins + ":" + second;
    }
  }

  render() {
    return (
      <div>
        <LoadingScreen show={this.state.loading} />
        <ReportSetting popSeen={this.state.popSeen} user department={this.props.user.department || "ALL"} date={this.state.date} defaultDate={this.state.defaultDate} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <div style={{ display: "grid", gridTemplateColumns: "20vw 20vw auto auto", marginLeft: "2.5vw", marginRight: "2.5vw", columnGap: "2.5vw" }}>
          <Button label="Generate Report" className="adminPageButtons red" style={{ width: "15vw", height: "5vh", fontSize: "1.5em" }} onClick={this.togglePop} />
          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
          <Button label="Expand All" className="hideMe adminPageButtons red" onClick={() => this.expandAll()} />
          <Button label="Collapse All" className="hideMe adminPageButtons red" onClick={() => this.setState({ expandedRows: [] })} />
        </div>
        {/*      <DataTable value={this.state.posts}>
          <Column field="id" header="Row Id" />
          <Column field="insert_datetime" header="Time Posted" body={(rowData) => this.formatTime(rowData, "insert_datetime")} />
          <Column field="part_count" header="Parts Posted" />
          <Column field="order_no" header="Order No" />
          <Column field="hspop_no" header="Op No" />
          <Column field="item_no" header="Part #" />
          <Column field="workcenter" header="Workcenter" />
          <Column field="user.name" header="Employee" />
    </DataTable>*/}
        <DataTable value={this.state.posts} expandableRowGroups expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })} scrollHeight="80vh" scrollable dataKey="user.pin" rowGroupMode="subheader" groupRowsBy="user.pin" rowGroupHeaderTemplate={(rowData) => this.headerTemplate(rowData)}>
          <Column field="name" header="Name" body={null} />
          <Column field="shift" header="Shift" body={null} />
          <Column
            field="time"
            header="Time Posted"
            body={(rowData, options) => {
              return new Date(rowData.time).toLocaleString();
            }}
          />
          <Column field="action" header="Action" />
          <Column field="work_center" header="Work Center" />
          <Column field="parts_posted" header="Parts Posted" />
          <Column field="op_no" header="Op No" />
          <Column field="part_no" header="Part #" />
          <Column
            field="hours"
            header="Hours"
            body={(rowData, options) => {
              return rowData.hours ? this.covertHourToHourMins(rowData.hours, "app") : null;
            }}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Page);
