import Icon from '@ant-design/icons';

export const DoubleLeftOutlined = props => <Icon component={DoubleLeftSvg} {...props} />;

const DoubleLeftSvg = () => (
	<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 400 400">
        <path d="M194.531 0.768 C 193.672 1.055,190.859 1.749,188.281 2.311 C 182.544 3.561,7.775 177.089,3.010 186.266 
            C -0.938 193.869,-0.936 206.138,3.015 213.734 C 6.128 219.720,179.585 393.768,185.156 396.496 C 204.740 406.085,226.786 
            392.615,226.786 371.061 C 226.786 358.784,229.285 361.655,145.546 277.734 L 67.980 200.000 145.546 122.266 C 229.285 38.345,226.786
            41.216,226.786 28.939 C 226.786 11.423,209.044 -4.073,194.531 0.768 M362.500 1.808 C 358.208 3.006,337.472 22.903,266.163 94.246 C
            164.812 195.645,170.047 189.231,173.774 207.422 C 175.956 218.069,352.462 395.442,363.848 398.428 C 386.349 404.329,405.021 384.833,399.310
            361.400 C 398.586 358.428,373.172 331.921,319.839 278.508 L 241.433 199.985 318.991 122.258 C 402.694 38.373,400.000 41.450,400.000 29.740
            C 400.000 8.689,383.058 -3.930,362.500 1.808 " />
	</svg>
);