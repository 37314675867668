Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 6, 1);
  var jul = new Date(this.getFullYear(), 12, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};
function isDaylightSavingTime(date) {
  //daylight saving time will change time zone greater for 1 hour
  return date.getTimezoneOffset() >= date.stdTimezoneOffset();
}

export default isDaylightSavingTime;
