import React from "react";
import { Layout } from "antd";
import Boxes from "../../components/admin/login.component";
import Header from "../../components/header/generalheader.component";
const { Content } = Layout;
const MTUPage = (props) => (
  <Layout style={{ height: "100vh" }}>
    <Layout>
      <Header />
      <Layout style={{ flex: 1 }}>
        <Content style={{ height: "100%", margin: "0", overflow: "auto" }}>
          {/*
    <div className="site-layout-background" style={{ padding: 0, height: '100vh',cursor:'none'}}>
*/}
          <Boxes loginCallback={(obj) => props.loginCallback(obj)} />
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MTUPage;
