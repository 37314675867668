import React from "react";
import axios from "axios";
import address from "../address.component";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import LoadingScreen from "../loading/loading.component.jsx";
import CommentSection from "../admin/input.component.jsx";
import ReportSetting from "../reportSetting/reportSetting.component";
import { Dialog } from "primereact/dialog";
import ConfirmationModal from "../confirmation_modal/confirmation_modal.component.jsx";
import { ConfirmDialog } from "primereact/confirmdialog";
//import ShiftEditor from "../admin/shifteditor.component.jsx";
import ShiftCreator from "../admin/shiftcreator.component.jsx";
import "./timesheet.styles.css";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";

import ModalHeader from "../modalHeader/modalHeader.component.jsx";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";

import calcTotalHours from "../global_functions/calcTotalHours.function";
import returnWeekObject from "../global_functions/returnWeekObject.function";
import processDataByUser from "../global_functions/processDataByUser.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";
import dateSort from "../global_functions/dateSort.function";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popSeen: false,
      shifts: [],
      defaultDate: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      date: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      breaks: [],
      newBreaks: [],
      users: [],
      shift: this.props.user.shift || "ALL",
      department: this.props.user.department || "ALL",
      user: null,
      attendanceCode: [],
      shiftNumbers: [
        { name: "None", value: 0 },
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
      ],
      departments: [
        { name: "IT", value: "IT" },
        { name: "OFFICE", value: "OFFICE" },
        { name: "PRODUCTION", value: "PRODUCTION" },
        { name: "TOOLING", value: "TOOLING" },
        { name: "SHIPPING", value: "SHIPPING" },
        { name: "MAINTENANCE", value: "MAINTENANCE" },
      ],
      attendanceCodes: [
        { value: "Call In PTO", code: "CALL IN PTO" },
        { value: "Unexcused PTO", code: "UNEXCUSED PTO" },
        { value: "FL", code: "BEREAVEMENT" },
        { value: "Overtime", code: "OVERTIME" },
        { value: null, code: "DIRECT" },
      ],
      type: "both",
      rowData: {},
      typeOfConfirm: null,
      msg: "Are you sure you want to approve this record?",
      currentApproveIndex: -1,
      active: true,
    };
    this.dumbPlaceholder = [{ name: "Not Finished Yet!", value: "NY" }];
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    console.log("hit remount");
    let day = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0));
    let start = day;
    let end = day;
    if (this.state.department !== null) {
      this.callCreateTimesheet([start, end]);
    }
    this.getAttendanceCodes();
    //this.getDepartments();
  }

  getDepartments() {
    axios.get(address + "/getDepartments").then((res) => {
      const data = res.data;
      this.setState({ departments: data.departments });
    });
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  getNewStartTime({ start, end, hours, shift, breaks }) {
    let timeToRemove = 0;
    if (breaks) {
      for (let i = 0; i < breaks.length; i++) {
        let brk = breaks[i]; // cant use break because it is a key word, so brk it is.
        let startBreak = new Date(end);
        let endBreak = new Date(end);
        let endToUse = new Date(end);
        startBreak = new Date(startBreak.setUTCHours(brk.item1));
        startBreak = new Date(startBreak.setUTCMinutes(brk.item2));
        endBreak = new Date(endBreak.setUTCHours(brk.item3));
        endBreak = new Date(endBreak.setUTCMinutes(brk.item4));
        let netTime = endBreak - startBreak;

        let UTCHours = new Date(endToUse.getTime() - netTime - hours * 60 * 60 * 1000).getUTCHours();
        if (UTCHours >= brk.item1 && UTCHours <= brk.item3) {
          console.log("hit");
          timeToRemove += netTime;
        }
        console.groupEnd();
      }
    }
    return new Date(new Date(end).getTime() - timeToRemove - hours * 60 * 60 * 1000).toISOString();
  }

  convertTimeToHours(time) {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = 0 + JSON.stringify(minutes);
    }
    return hours + ":" + minutes;
  }

  setDate(data) {
    this.setState({ date: data }, () => {
      if (data[1] !== null || undefined) {
        this.calendar.updateInputfield(this.state.date);
      }
    });
  }

  createNewShift() {
    this.toast.show({ severity: "success", summary: "Shift Data Created", life: 3000 });
    this.callCreateTimesheet(this.state.date);
  }

  callCreateTimesheet(date) {
    if (this.state.department === null) {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Department", life: 3000 });
      return;
    }
    if (this.state.shift === null) {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Shift", life: 3000 });
      return;
    }
    if (date[1] !== null && date[1] !== undefined) {
      this.setState({ shifts: [], expandedRows: [], loading: true });
      let startDate = date[0].toISOString();
      let endDate = new Date(new Date(date[1]).setDate(date[1].getDate() + 1)).toISOString();
      // this.setState({searchData:})
      let userId = this.state.user === null || this.state.user === undefined ? null : this.state.user.pin;
      axios
        .post(address + "/createTimesheet", {
          start_time: startDate,
          end_time: endDate,
          department: this.state.department || "ALL",
          shift: this.state.shift || "ALL",
          type: this.state.type,
          user_id: userId,
          division: this.props.user.division,
          active: this.state.active,
          attendanceCode: this.state.attendanceCode,
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          this.dataUpdater(data.dataByUser, data.breaks, data.newBreakInfo);

          this.setState({ dataByUser: data.dataByUser, loading: false, originalShifts: data.originalShifts, breaks: data.breaks, newBreaks: data.newBreakInfo });
        });
    } else {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Start Date & End Date", life: 3000 });
      return "error invalid date";
    }
  }

  headerTemplate(rowData) {
    let display = this.state.shifts.filter((obj) => obj.display !== false && obj.user.id === rowData.user.id);
    if (display.length > 0) {
      display = false;
    } else {
      display = true;
    }
    return (
      <div className={display > 0 ? "hideThisHeader" : ""} style={{ display: "inline-block", width: "100%", display: "grid", gridTemplateColumns: "15% 15% 15% 15% 15% 13%" }}>
        <span>{rowData.user.name}</span>
        <span>Unapproved Hours: {this.calculateTotalHours(rowData.user.name, false)}</span>
        <span>Approved Hours: {this.calculateTotalHours(rowData.user.name, true)}</span>
        <span>Overtime Hours: {this.calculateTotalHours(rowData.user.name, "overtime")}</span>
        <span>Total Hours: {this.calculateTotalHours(rowData.user.name)}</span>
        <Button label="Approve All for this User" className="p-button-outlined" style={{ backgroundColor: "red", color: "white", borderRadius: ".5em", width: "80%", padding: ".05em" }} onClick={() => this.setState({ rowData: rowData, valueToSet: true, typeOfConfirm: "approvalByUser", msg: "Are you sure you want to approve all shifts for " + rowData.user.name }, () => this.prepForApproval())} />
        <span></span>
      </div>
    );
  }

  calculateTotalHours = (name, approved) => {
    let total = 0;
    for (let i = 0; i < this.state.shifts.length; i++) {
      if (this.state.shifts[i].user.name === name) {
        if (this.state.shifts[i].display === false) {
          continue;
        }
        if (approved !== null) {
          if (approved === true) {
            if (this.state.shifts[i].approved !== true) {
              continue;
            }
          } else if (approved === false) {
            if (this.state.shifts[i].approved === true) {
              continue;
            }
          } else if (approved === "overtime") {
            if (this.state.shifts[i].attendance !== "OVERTIME" && this.state.shifts[i].attendance !== "OTWT") {
              continue;
            }
          }
        }
        total += this.state.shifts[i].hours;
        // total += calcTotalHours({ start: this.state.shifts[i].adj_start_time || null, end: this.state.shifts[i].adj_end_time || null, userInfo: this.state.shifts[i].user, breaks: this.state.breaks, shift: this.state.shifts[i].user.shift || 1, attendance: getHighestAttendance(this.state.shifts[i].attendance, this.state.attendanceCodes) || null, approved: this.state.shifts[i].approved || false, shiftHour: this.state.shifts[i].hours, newBreaks: this.state.newBreaks, breakShift: this.state.shifts[i].break_shift });
      }
    }
    return total.toFixed(2);
  };

  async checkForDupeRecords(id, value, shifts, originalShift, weekRowIndex, field) {
    let x = await axios.post(address + "/checkAndReturnDupeByShift_Id", { shift_id: id }).then((res) => {
      return res.data;
    });

    if (!x.err) {
      let remainingMatches = [];
      shifts = JSON.parse(JSON.stringify(shifts));
      if (x.data) {
        x.data.week = originalShift.week;
        if (value === false && field === "approved") {
          // unapprove records
          remainingMatches = shifts.filter((obj) => (obj.ID || obj.shift_id) === (originalShift.ID || originalShift.shift_id) && obj.approved === true);
        }
        shifts = shifts.filter((obj) => (obj.ID || obj.shift_id) !== (originalShift.ID || originalShift.shift_id));

        shifts.splice(weekRowIndex, 0, x.data);
        shifts = shifts.sort((a, b) => {
          console.log(a, b);
          let aStart = new Date(a.adj_start_time === null ? a.adj_start_time : a.start_time);
          let bStart = new Date(b.adj_start_time === null ? b.adj_start_time : b.start_time);
          if (aStart > bStart) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return { data: x.data, remainingMatches: remainingMatches };
    } else {
      return x;
    }
  }

  async updateCheckbox(value, rowIndex, rowData, field, shouldConsumePTO) {
    console.log(value);
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
      //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
      return;
    }
    let rows = [];
    let copyOfUserData = JSON.parse(JSON.stringify(this.state.dataByUser));
    let indexOfUserData = copyOfUserData.findIndex((obj) => {
      return obj.user.ID === rowData.user.id;
    });
    copy[rowIndex][field] = value;
    // if (value === true && field === "approved") {
    //   copy[rowIndex].hours = calcTotalHours({ start: copy[rowIndex].adj_start_time, end: copy[rowIndex].adj_end_time, userInfo: copy[rowIndex].user, breaks: this.state.breaks, shift: copy[rowIndex].user.shift || 1, attendance: getHighestAttendance(copy[rowIndex].attendance, this.state.attendanceCodes), approved: false, shiftHour: copy[rowIndex].hours, newBreaks: this.state.newBreaks, breakShift: copy[rowIndex].break_shift });
    // }
    let copyRaw = copy[rowIndex];
    let rowId = copy[rowIndex].ID || copy[rowIndex].id;
    let week = copy[rowIndex].week;
    console.log(week);
    let weekIndex = copyOfUserData[indexOfUserData].weeks[week].shifts.findIndex((obj) => (obj.ID || obj.id) === rowId);
    console.log(weekIndex);
    if (weekIndex === -1) {
      return;
      // console.log("new week", week);
    }
    copyOfUserData[indexOfUserData].weeks[week].shifts[weekIndex][field] = value;
    let weekRowIndex = copyOfUserData[indexOfUserData].weeks[week].shifts.findIndex((obj) => {
      return (obj.id || obj.ID) === (copyRaw.id || copyRaw.ID);
    });
    let fieldToCheck = copyRaw.ID ? "ID" : "shift_id";
    rows = this.state.shifts.filter((obj) => obj[fieldToCheck] === copyRaw[fieldToCheck] && obj.user_id === copyRaw.user_id);

    if (copyRaw.copy && field === "approved") {
      if (weekRowIndex !== -1) {
        //just dumb cautionary check
        if (value === true) {
          copyOfUserData[indexOfUserData].weeks[week].shifts.splice(weekRowIndex, 1); // gets rid of the original record.
          for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let weekIndexToSpliceInto = row.trueWeek || row.week;
            //row.hours = calcTotalHours({ start: row.adj_start_time, end: row.adj_end_time, userInfo: row.user, breaks: this.state.breaks, shift: row.user.shift || 1, attendance: getHighestAttendance(row.attendance, this.state.attendanceCodes), approved: false, shiftHour: row.hours, newBreaks: this.state.newBreaks, breakShift: row.break_shift });
            row.approved = true;
            if (copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] === undefined) {
              copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] = returnWeekObject(copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto - 1]);
            }
            copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts.splice(0, 0, { approved: true, ...row });
          }
        } else if (field === "approved") {
          let x = await this.checkForDupeRecords(copyRaw.ID || copyRaw.shift_id, value, copyOfUserData[indexOfUserData].weeks[week].shifts, copyRaw, weekRowIndex, field);
          if (!x.err) {
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              let weekIndexToSpliceInto = row.trueWeek || row.week;
              row.approved = true;
              if (copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] === undefined) {
                copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] = returnWeekObject(copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto - 1]);
              }
              let fieldToCheck = row.ID ? "ID" : "shift_id";
              copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts = copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts.filter((obj) => obj[fieldToCheck] !== row[fieldToCheck]);
            }
            copyOfUserData[indexOfUserData].weeks[x.data.week].shifts.splice(0, 0, x.data);
          }
        }
      }
    } else {
      if (field === "approved") {
        if (value === false) {
          let x = await this.checkForDupeRecords(copyRaw.ID || copyRaw.shift_id, value, copyOfUserData[indexOfUserData].weeks[week].shifts, copyRaw, weekRowIndex, field);
          if (!x.err) {
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              let weekIndexToSpliceInto = row.trueWeek || row.week;
              row.approved = true;
              if (copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] === undefined) {
                copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] = { baseApprovedHours: 0, shifts: [] };
              }
              let fieldToCheck = row.ID ? "ID" : "shift_id";
              console.log("FFS", fieldToCheck);
              copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts = copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts.filter((obj) => obj[fieldToCheck] !== row[fieldToCheck]);
            }
            let fieldToCheck2 = copyRaw.ID ? "ID" : "shift_id";
            copyOfUserData[indexOfUserData].weeks[copyRaw.week].shifts = copyOfUserData[indexOfUserData].weeks[copyRaw.week].shifts.filter((obj) => obj[fieldToCheck2] !== copyRaw[fieldToCheck2]);

            copyOfUserData[indexOfUserData].weeks[x.data.week].shifts.splice(0, 0, x.data);
          } else {
            //todo display err msg
          }
        } else if (value === true) {
          for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let weekIndexToSpliceInto = row.trueWeek || row.week;
            row.approved = true;
            if (copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] === undefined) {
              copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto] = returnWeekObject(copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto - 1]);
            }
            let fieldToCheck = row.ID ? "ID" : "shift_id";
            console.log(fieldToCheck);
            copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts = copyOfUserData[indexOfUserData].weeks[weekIndexToSpliceInto].shifts.filter((obj) => obj[fieldToCheck] !== row[fieldToCheck]);
          }
          let fieldToCheck2 = copyRaw.ID ? "ID" : "shift_id";

          copyOfUserData[indexOfUserData].weeks[copyRaw.week].shifts = copyOfUserData[indexOfUserData].weeks[copyRaw.week].shifts.filter((obj) => obj[fieldToCheck2] !== copyRaw[fieldToCheck2]);
          copyOfUserData[indexOfUserData].weeks[copyRaw.week].shifts.splice(0, 0, copyRaw);
          console.log(copyOfUserData);
        }
      }
    }
    if (field !== "approved") {
      if (rowData.approved === true) {
        this.toast.show({ severity: "error", summary: "Cant edit a Approved Row", life: 3000 });
        return;
      }
    }
    if (field === "late") {
      axios.post(address + "/UpdateSQLTime", {
        field: field,
        rowData: copy[rowIndex],
      });
    }
    if (field === "approved") {
      // for (let i = 0; i < rows.length; i++) {
      //   let hours = calcTotalHours({ start: rows[i].adj_start_time, end: rows[i].adj_end_time, userInfo: rows[i].user, breaks: this.state.breaks, shift: rows[i].user.shift || 1, attendance: getHighestAttendance(rows[i].attendance, this.state.attendanceCodes), approved: false, shiftHour: rows[i].hours, newBreaks: this.state.newBreaks, breakShift: rows[i].break_shift });
      //   rows[i].hours = hours;
      // }
      await axios.post(address + "/confirmTimesheet", {
        admin_id: this.props.user.id,
        rows: rows,
        rowData: rowData,
        value: value,
        consumePTO: shouldConsumePTO,
      });
    }

    this.setState({ dataByUser: copyOfUserData }, () => this.dataUpdater(this.state.dataByUser, this.state.breaks, this.state.newBreaks));
  }

  async dataUpdater(rawDataByUser, breaks, newBreaks, startDate = this.state.date[0], endDate = new Date(new Date(this.state.date[1]).setDate(this.state.date[1].getDate() + 1)).toISOString()) {
    //todo come back to me and this stupid thing not waiting!
    console.log(rawDataByUser, breaks, newBreaks, startDate, endDate, this.state.attendanceCodes);
    const data = await processDataByUser(rawDataByUser, breaks, newBreaks, startDate, endDate, this.state.attendanceCodes);
    console.log(data);
    if (data.data.length !== this.state.shifts.length) {
      this.shiftManagement?.closeEditingCell();
    }
    this.setState({ shifts: data.data }, () => console.log(this.state));
  }

  formatTime(rowData, field, modified) {
    let time = rowData[field];
    if (time === null) {
      return "N/A";
    }
    let color = true;
    let approved = rowData.approved;
    if (field.includes(modified) || modified === "both" || approved || !field.includes("adj") || rowData.approved === true) {
      color = false;
    }
    let hours = new Date(time).getHours();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    let minutes = new Date(time).getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return <div style={{ border: color ? " 1px solid red" : "", borderRadius: "5px" }}>{new Date(time).toLocaleDateString() + " " + hours + ":" + minutes + " " + ampm}</div>; // return new Date(time).toLocaleDateString();
  }

  updateShiftsValue(rowIndex, field, value, userId) {
    if (field === "attendance") {
      console.log(value);
      if (value.length === 0) {
        value = "";
      } else {
        value = value.toString();
      }
    }
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
      //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
      return;
    }
    console.log(field, value, "updating record.");
    if (value === undefined) {
      value = copy[rowIndex][field];
      if (copy[rowIndex].copy === 1 && field === "adj_end_time") {
        value = copy.find((ele) => ele.ID === copy[rowIndex].ID && ele.copy === 2)[field];
      }
    }
    let copyOfUserData = JSON.parse(JSON.stringify(this.state.dataByUser));
    let indexOfUserData = copyOfUserData.findIndex((obj) => {
      return obj.user.ID === userId;
    });
    copy[rowIndex][field] = value;
    let rowId = copy[rowIndex].ID || copy[rowIndex].id;

    let week = copy[rowIndex].week;
    let weekIndex = copyOfUserData[indexOfUserData].weeks[week].shifts.findIndex((obj) => (obj.ID || obj.id) === rowId);
    copyOfUserData[indexOfUserData].weeks[week].shifts[weekIndex][field] = value;
    this.setState({ dataByUser: copyOfUserData }, () => this.dataUpdater(this.state.dataByUser, this.state.breaks, this.state.newBreaks));
    axios.post(address + "/UpdateSQLTime", {
      field: field,
      rowData: copy[rowIndex],
    });
    return "updated";
  }

  updateJustTheShift(rowIndex, field, value, userId) {
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
      //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
      return;
    }
    copy[rowIndex][field] = value;
    this.setState({ shifts: copy });
    return "finished :)";
  }

  AttendanceEditor(options) {
    if (options.rowData.approved === true) {
      return options.rowData.attendance;
    }
    return <MultiSelect className="primereactfix-large-font-size-dropdown" style={{ width: "60%", padding: ".12em", fontSize: "1em" }} value={options.rowData.attendance === null ? null : options.rowData.attendance.split(",")[0] === "" ? null : options.rowData.attendance.split(",")} placeholder={"N/A"} options={this.state.attendanceCodes} onChange={(e) => this.updateShiftsValue(options.rowIndex, options.field, e.value, options.rowData.user.id)} optionLabel="name" />;
  }
  BreakShiftEditor(options) {
    if (options.rowData.approved === true) {
      return options.rowData.break_shift === 0 || options.rowData.break_shift === "0" ? "None" : options.rowData.break_shift || "N/A";
    } else {
      console.log();
      return <Dropdown value={options.rowData.break_shift} placeholder={"N/A"} className="primereactfix-large-font-size-dropdown" options={this.state.shiftNumbers} optionLabel="name" onChange={(e) => this.updateShiftsValue(options.rowIndex, options.field, e.value, options.rowData.user.id)} />;
    }
  }

  TimeEditor(options) {
    // let date = new Date(options.rowData[options.field]);
    let field = options.field;
    let modified = options.rowData.modified;
    let approved = options.rowData.approved;
    if (field.includes(modified) || modified === "both" || approved || !field.includes("adj") || options.rowData.approved === true) {
      return this.formatTime(options.rowData, options.field, modified);
    }
    return (
      <Calendar
        ref={(e) => (this.Calendar = e)}
        id="time24"
        showTime
        hourFormat="12"
        value={this.state.shifts[options.rowIndex][field] !== null ? new Date(this.state.shifts[options.rowIndex][field]) : new Date()}
        onChange={(e) => {
          console.log("hit change");
          this.updateJustTheShift(options.rowIndex, field, e.value, options.rowData.user.id);
        }}
        onBlur={(e) => {
          console.log("hit blur");
          this.updateShiftsValue(options.rowIndex, field, e.value, options.rowData.user.id);
        }}
      />
    );
  }

  updateComment(id, data) {
    console.log(this.state.commentObject);
    let field = "comment";
    let value = data;
    let copy = JSON.parse(JSON.stringify(this.state.shifts));
    let rowID = JSON.stringify(this.state.commentObject.ID || this.state.commentObject.id);
    console.log(rowID);
    let rowIndex = copy.findIndex((obj) => JSON.stringify(obj.ID) === rowID || JSON.stringify(obj.id) === rowID);
    console.log(rowIndex, "rowIndex", copy[rowIndex]);
    if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
      //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
      return;
    }

    let copyOfUserData = JSON.parse(JSON.stringify(this.state.dataByUser));
    let indexOfUserData = copyOfUserData.findIndex((obj) => {
      return obj.user.ID === this.state.commentObject.user.id;
    });
    console.log("index", indexOfUserData);
    copy[rowIndex][field] = data;
    let rowId = copy[rowIndex].ID || copy[rowIndex].id;

    let week = copy[rowIndex].week;
    let weekIndex = copyOfUserData[indexOfUserData].weeks[week].shifts.findIndex((obj) => (obj.ID || obj.id) === rowId);
    copyOfUserData[indexOfUserData].weeks[week].shifts[weekIndex][field] = value;
    this.setState({ dataByUser: copyOfUserData }, () => this.dataUpdater(this.state.dataByUser, this.state.breaks, this.state.newBreaks));
  }

  async deleteRow(data) {
    //fix a bug here when unapproved a Record and delete it won't pass the ID since it not change the rows, so need to check ID and shift_id
    let ID = data.id === undefined ? data.ID : data.shift_id;
    let x = await axios.post(address + "/deleteRow", { row_id: ID, secureUser_id: this.props.user?.id }).then(async (res) => {
      //TODO DONT NEED A REFRESH PROP IF YOU DELETE SOMETHING, JUST REMOVE IT FROM THE TABLE!!! AND HAVE A REFRESH FLAG FOR WHEN MODAL IS CLOSED.
      if (this.props.refreshData) {
        await this.props.refreshData();
      } else {
        console.log("missing refreshData prop");
        let rowIndex = this.state.shifts.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(data));
        if (rowIndex !== -1) {
          let copy = JSON.parse(JSON.stringify(this.state.shifts));
          let copyRaw = copy[rowIndex];
          let rowId = copy[rowIndex].ID || copy[rowIndex].id;
          let copyOfUserData = JSON.parse(JSON.stringify(this.state.dataByUser));
          let indexOfUserData = copyOfUserData.findIndex((obj) => {
            return obj.user.ID === copyRaw.user.id;
          });
          let rows = [];
          let week = copy[rowIndex].week;
          let weekIndex = copyOfUserData[indexOfUserData].weeks[week].shifts.findIndex((obj) => (obj.ID || obj.id) === rowId);
          copyOfUserData[indexOfUserData].weeks[week].shifts.splice(weekIndex, 1);

          if (copyRaw.copy === 1) {
            copy.splice(rowIndex, 1);
            copy.splice(rowIndex, 1);
          } else if (copyRaw.copy === 2) {
            copy.splice(rowIndex, 1);
            copy.splice(rowIndex - 1, 1);
          }

          this.setState({ shifts: copy, dataByUser: copyOfUserData }, () => this.dataUpdater(this.state.dataByUser, this.state.breaks, this.state.newBreaks));
        }
      }
      this.toast.show({ severity: "success", summary: "Login Deleted", life: 3000 });

      this.shiftManagement.closeEditingCell();
    });
    return x;
  }

  async confirmationAccept(shouldConsumePTO) {
    let shifts = JSON.parse(JSON.stringify(this.state.shifts));
    if (this.state.typeOfConfirm === "approval") {
      let index = shifts.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(this.state.rowData));
      console.log(index, shifts);
      if (index !== -1) {
        this.updateCheckbox(this.state.valueToSet, index, this.state.rowData, "approved", shouldConsumePTO);
      }
    } else if (this.state.typeOfConfirm === "approvalByUser") {
      this.setState({ loading: true });
      for (let i = 0; i < this.state.rows.length; i++) {
        let index = shifts.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(this.state.rows[i]));
        if (index !== -1) {
          if (shifts[index].copy === 1 || shifts[index].copy === undefined) {
            await this.updateCheckbox(true, index, shifts[index], "approved", shouldConsumePTO);
          }
        }
      }
      this.setState({ loading: false });
    } else if (this.state.typeOfConfirm === "deleteRow") {
      this.deleteRow(this.state.rowData);
    } else if (this.state.typeOfConfirm === "approvalAll") {
      //approve the current user's data
      this.setState({ loading: true });
      for (let i = 0; i < this.state.rows.length; i++) {
        let index = shifts.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(this.state.rows[i]));
        if (index !== -1) {
          if (shifts[index].copy === 1 || shifts[index].copy === undefined) {
            await this.updateCheckbox(true, index, shifts[index], "approved", shouldConsumePTO);
          }
        }
      }
      //set the confirmModal to next user
      if (this.state.currentApproveIndex < this.state.dataByUser.length - 1) {
        let currentIndex = this.state.currentApproveIndex;
        let rows = [];
        for (let i = currentIndex + 1; i < this.state.dataByUser.length; i++) {
          let user = this.state.dataByUser[i].user;
          rows = shifts.filter((obj) => obj.user_id === user.ID && !obj.approved);
          if (rows.length > 0) {
            currentIndex = i;
            this.setState({ rows: rows, confirmModal: true, currentApproveIndex: currentIndex, loading: false, msg: "Are you sure you want to approve " + user.first_name + " " + user.last_name + " shifts" });
            return;
          }
        }
      }

      this.setState({ currentApproveIndex: -1, loading: false });
    }
    this.setState({ confirmModal: false });
  }

  closeDialogAndRunReport() {
    this.togglePop();
    this.callCreateTimesheet(this.state.date);
  }

  confirmationReject() {
    console.log("rejected");
    if (this.state.typeOfConfirm === "approvalAll") {
      let currentIndex = this.state.currentApproveIndex;
      if (this.state.currentApproveIndex < this.state.dataByUser.length - 1) {
        let rows = [];
        let shifts = JSON.parse(JSON.stringify(this.state.shifts));
        for (let i = currentIndex + 1; i < this.state.dataByUser.length; i++) {
          let user = this.state.dataByUser[i].user;
          //approveAll needs rework from PrepApproval
          rows = this.filterRowsOnUser(user.ID);
          if (rows.length > 0) {
            currentIndex = i;
            this.setState({ rows: rows, confirmModal: true, currentApproveIndex: currentIndex, msg: "Are you sure you want to approve " + user.first_name + " " + user.last_name + " shifts" });
            return;
          }
        }
      } else {
        this.setState({ currentApproveIndex: -1 });
      }
    }
    this.setState({ confirmModal: false });
  }

  // there are something wrong when expandRow, it create extra lines with same user_id,
  // can not figure why it is, so create a new array without duplicate user_id and set to expandedRows
  expandRow() {
    let rows = this.state.shifts;
    let duplicate = false;
    let result = [];
    for (let i = 0; i < rows.length; i++) {
      if (!duplicate) {
        result.push(rows[i]);
      }

      if (i < rows.length - 1) {
        if (rows[i].user_id === rows[i + 1].user_id) {
          duplicate = true;
        } else {
          duplicate = false;
        }
      }
    }
    this.setState({ expandedRows: result });
  }
  // there are something wrong when modify a row, it create extra lines with same id/ID so it won't collapse it,
  // can not figure why it is, so create a new array without duplicate id/ID and set to expandedRows
  hideRow(e) {
    let rows = e.data;

    let idList = [];
    let result = [];
    for (let i = 0; i < rows.length; i++) {
      idList.push(rows[i].id ? rows[i].id : rows[i].ID);
    }
    let resultWithDuplicateId = idList.filter((p, i, a) => a.indexOf(p) !== i);
    for (let i = 0; i < rows.length; i++) {
      let rowId = rows[i].id ? rows[i].id : rows[i].ID;
      if (!resultWithDuplicateId.includes(rowId)) {
        result.push(rows[i]);
      }
    }

    this.setState({ expandedRows: result });
  }

  filterRowsOnUser(user_id) {
    //todo create system that doesnt screw up your awful awful 3rd shift system.
    let shifts = JSON.parse(JSON.stringify(this.state.shifts));
    console.log(user_id);
    let rows = shifts.filter((obj) => obj.user.id === user_id && !obj.approved && obj.display !== false);
    let rowsToReturn = [];
    for (let i = 0; i < rows.length; i++) {
      let fieldToCheck = rows[i].ID ? "ID" : "shift_id";
      let otherRecords = this.state.shifts.filter((obj) => obj[fieldToCheck] === rows[i][fieldToCheck] && !obj.approved && obj.user_id === rows[i].user_id); // CANT BE APPROVED!!!
      // just always add them
      rowsToReturn = rowsToReturn.concat(otherRecords);
    }
    rowsToReturn = rowsToReturn.sort(dateSort);
    // below filter removes and duplicate data. Can't go off of ID or even shift_id since those can both be used in multiple objects.
    rowsToReturn = rowsToReturn.filter((item, index) => rowsToReturn.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item)) === index);

    console.log(rowsToReturn);
    return rowsToReturn;
  }

  prepForApproval() {
    let rows = [];
    if (this.state.typeOfConfirm === "approvalByUser") {
      let shifts = JSON.parse(JSON.stringify(this.state.shifts));
      rows = this.filterRowsOnUser(this.state.rowData.user_id);
    } else if (this.state.typeOfConfirm === "approvalAll") {
      if (this.state.dataByUser.length > 0) {
        let shifts = JSON.parse(JSON.stringify(this.state.shifts));
        let user = this.state.dataByUser[0].user;
        let currentApproveIndex = -1;
        for (let i = 0; i < this.state.dataByUser.length; i++) {
          let currentUser = this.state.dataByUser[i].user;
          rows = this.filterRowsOnUser(currentUser.ID);
          if (rows.length > 0) {
            currentApproveIndex = i;
            user = currentUser;
            break;
          }
        }
        //if there is no record match, then show the error message and not open confirmModal
        if (currentApproveIndex === -1) {
          this.toast.show({ severity: "error", summary: "There is no record to approve", life: 3000 });
          return;
        } else {
          this.setState({ currentApproveIndex: currentApproveIndex, msg: "Are you sure you want to approve " + user.first_name + " " + user.last_name + "'s shifts" });
        }
      }
    } else {
      let rowIndex = this.state.shifts.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(this.state.rowData));
      let field = "approved";
      let copy = JSON.parse(JSON.stringify(this.state.shifts));
      if (copy[rowIndex] === null || copy[rowIndex] === undefined) {
        //TODO FIX BUG PROPERLY, TEMPORARY SOLUTION TO PEREVENT CRASHES WHEN USER MAKES ROW INDEX NO LONGER EXIST
        return;
      }

      let copyRaw = copy[rowIndex];
      let fieldToCheck = copyRaw.ID ? "ID" : "shift_id";
      rows = this.state.shifts.filter((obj) => obj[fieldToCheck] === copyRaw[fieldToCheck] && obj.user_id === copyRaw.user_id);
    }
    this.setState({ rows: rows, confirmModal: true });
  }
  runReport = async (data) => {
    this.setState(data, () => {
      this.closeDialogAndRunReport();
    });
  };

  rowClass = (data) => {
    // quick and nasty hide rows.
    return {
      hideThisRow: data.display === false ? true : false,
    };
  };

  render() {
    return (
      <>
        <ReportSetting popSeen={this.state.popSeen} defaultDate={this.state.defaultDate} attendanceCode={this.state.attendanceCode} active approve="both" user department={this.props.user.department || "ALL"} date={this.state.date} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <Toast ref={(el) => (this.toast = el)} />
        <ConfirmationModal rows={this.state.rows} breaks={this.state.breaks} newBreaks={this.state.newBreaks} typeOfConfirm={this.state.typeOfConfirm} message={this.state.msg} visible={this.state.confirmModal} hide={() => this.setState({ confirmModal: false })} accept={(shouldConsumePTO) => this.confirmationAccept(shouldConsumePTO)} reject={() => this.confirmationReject()} clearRow={() => this.setState({ rows: [] })} />
        <Dialog resizable={false} draggable={false} visible={this.state.visible} baseZIndex={1000} dismissableMask onHide={() => this.setState({ visible: false })} style={{ width: "50vw", minHeight: "70vh", overflow: "hidden", borderRadius: ".8em", zIndex: 6000, padding: 0 }} contentStyle={{ overflow: "hidden", padding: "0", display: "grid" }} showHeader={false}>
          <ShiftCreator department={this.state.department} shift={this.state.shift} division={this.props.user.division} hideMe={() => this.setState({ visible: false })} createNewShift={() => this.createNewShift()} />
        </Dialog>
        {/*<Dialog resizable={false} draggable={false} dismissableMask visible={this.state.CreateShiftModal} modal={true} style={{ borderRadius: ".8em" }} onHide={() => this.setState({ visible: false })} contentStyle={{ width: "auto", height: "auto", padding: 0, overflow: "hidden", borderRadius: ".8em" }} showHeader={false}>
          <ShiftEditor shifts={this.state.originalShifts} user={this.props.user} department={this.state.department} shift={this.state.shift} hideMe={() => this.setState({ visible: false })} />
    </Dialog>*/}
        <CommentSection visible={this.state.viewComment} onHide={() => this.setState({ viewComment: false })} commentId={this.state.commentToUpdate} value={this.state.comment} updateParent={(data) => this.setState({ comment: data }, () => this.updateComment(this.state.commentToUpdate, data))} />
        <LoadingScreen show={this.state.loading} />
        <div style={{ width: "100%", display: "flex", height: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "grid", gridTemplateColumns: " 10vw 3vw 10vw 10vw 10vw 10vw ", columnGap: "3vw", paddingLeft: "2.5vw", paddingRight: "2.5vw", width: "100%", height: "5vh" }}>
              <Button label="Generate Report" className="hideMe adminPageButtons red" style={{ width: "100%" }} onClick={this.togglePop} />
              <div className="space"></div>
              <Button label="Expand All" className="hideMe adminPageButtons red" onClick={() => this.expandRow()} />
              <Button label="Collapse All" className="hideMe adminPageButtons red" onClick={() => this.setState({ expandedRows: [] })} />
              <Button label="Approve All" className="hideMe adminPageButtons red" onClick={() => this.setState({ valueToSet: true, typeOfConfirm: "approvalAll", msg: "Are you sure you want to approve All shifts" }, () => this.prepForApproval())} />
              <Button label="Create" className="hideMe adminPageButtons red" onClick={() => this.setState({ visible: true })} />
            </div>

            <div style={{ width: "100%" }}>
              <DataTable ref={(e) => (this.shiftManagement = e)} rowClassName={this.rowClass} expandableRowGroups scrollHeight="80vh" scrollable editMode="cell" value={this.state.shifts} rowGroupMode="subheader" groupRowsBy="user.name" rowGroupHeaderTemplate={(rowData) => this.headerTemplate(rowData)} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.hideRow(e)}>
                <Column header="Employee Name" field={"user.name"} style={{ paddingLeft: "3rem", width: "10.5%" }} />
                <Column header="Employee #" field={"user.pin"} style={{ width: "8%" }} />
                <Column
                  field="start_time"
                  header="Act. Start"
                  body={(rowData, options) => {
                    return this.formatTime(rowData, options.field, rowData.modified);
                  }}
                  editor={(e) => this.TimeEditor(e)}
                />

                <Column
                  field="end_time"
                  header="Act. End"
                  body={(rowData, options) => {
                    return this.formatTime(rowData, options.field, rowData.modified);
                  }}
                  editor={(e) => this.TimeEditor(e)}
                />
                <Column
                  field="adj_start_time"
                  header="Adj. Start"
                  body={(rowData, options) => {
                    return this.formatTime(rowData, options.field, rowData.modified);
                  }}
                  editor={(e) => this.TimeEditor(e)}
                />

                <Column
                  field="adj_end_time"
                  header="Adj. End"
                  body={(rowData, options) => {
                    return this.formatTime(rowData, options.field, rowData.modified);
                  }}
                  editor={(e) => this.TimeEditor(e)}
                />

                <Column filter header="Attend Code" field="attendance" style={{ width: "12.5%" }} editor={(e) => this.AttendanceEditor(e)} />
                <Column
                  header="Hours"
                  field={"hours"}
                  style={{ width: "5%" }}
                  body={(rowData) => {
                    return rowData.hours;
                    // return calcTotalHours({ start: rowData.adj_start_time, end: rowData.adj_end_time, userInfo: rowData.user, breaks: this.state.breaks, shift: rowData.user.shift || 1, attendance: getHighestAttendance(rowData.attendance, this.state.attendanceCodes), approved: rowData.approved || false, shiftHour: rowData.hours, newBreaks: this.state.newBreaks, breakShift: rowData.break_shift });
                  }}
                  onCellEditComplete={this.onCellEditComplete}
                />
               { this.props.user.division==='HAVEN'?null:<Column
                  header="Break Shift"
                  field={"break_shift"}
                  style={{ width: "5%" }}
                  body={(rowData) => {
                    return rowData.break_shift === 0 || rowData.break_shift === "0" ? "None" : rowData.break_shift || "N/A";
                  }}
                  editor={(e) => this.BreakShiftEditor(e)}
                />}
                <Column
                  header="Late / Left Early"
                  headerStyle={{ fontSize: "1em" }}
                  className="primereactfix-center-column-header"
                  style={{ flexBasis: "7em", flexShrink: 0, flexGrow: 0 }}
                  body={(rowData, column) => {
                    return (
                      <Checkbox
                        onChange={(e) => {
                          this.updateCheckbox(e.checked, column.rowIndex, rowData, "late");
                        }}
                        checked={rowData.late === null || rowData.late === "0" ? false : rowData.late === "1" ? true : rowData.late}
                      ></Checkbox>
                    );
                  }}
                />
                <Column
                  header="Comment"
                  align="center"
                  style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0 }}
                  body={(rowData, b) => {
                    return (
                      <i className="pi pi-comment p-text-secondary p-overlay-badge" style={{ fontSize: "1.5vw" }} onClick={() => this.setState({ viewComment: true, commentToUpdate: rowData.ID || rowData.shift_id, commentObject: rowData, comment: rowData.comment })}>
                        {rowData.comment === null || rowData.comment === undefined || rowData.comment === "" ? null : <Badge severity="info"></Badge>}
                      </i>
                    );
                  }}
                ></Column>
                <Column
                  header="Approved"
                  className="primereactfix-center-column-header"
                  style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0 }}
                  body={(rowData, column) => {
                    return <Checkbox onChange={(e) => this.setState({ rowData: rowData, typeOfConfirm: "approval", valueToSet: e.checked, msg: "Are you sure you want to " + (e.checked === true ? "approve" : "unapprove") + " this record?" }, () => this.prepForApproval())} checked={rowData.approved}></Checkbox>;
                  }}
                />
                <Column
                  align="center"
                  style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0, textAlign: "center" }}
                  body={(rowData, b) => {
                    return (
                      <i
                        className="pi pi-trash"
                        style={{ fontSize: "1.1vw" }}
                        onClick={() => {
                          if (rowData.approved !== true) {
                            this.setState({ rowData: rowData, typeOfConfirm: "deleteRow", msg: "Are you sure you want to delete this row?" }, () => this.prepForApproval());
                          } else {
                            this.toast.show({ severity: "error", summary: "Cant delete Approved Row", life: 3000 });
                          }
                        }}
                      />
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
