import React from "react";
import axios from "axios";
import address from "../address.component";
import "./wifi_icon.styles.css";

class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {}

  wifiColor(stage) {
    //todo style is gross!!! go use classnames! thank you future me!
    let style = {};
    let wifi = navigator.connection.downlink;
    if (navigator.onLine === false) {
      wifi = 0;
    }
    let pass = stage * 0.3;
    if (wifi >= pass) {
      if (stage === 1 && wifi < pass + 0.3) {
        style.borderColor = "red";
      } else {
        style.borderColor = "green";
      }
    } else {
      if (stage === 1) {
        style.borderColor = "red";
      } else {
        style.borderColor = "darkgray";
      }
    }
    if (this.props.animate === true && stage !== 1) {
      style.animation = "example 3s infinite";
      style.animationDelay = (stage - 1) * 200 + "ms";
    }
    if (stage === 1 && wifi === 0) {
      style.animation = "example 3s infinite";
      style.animationDelay = 50 + "ms";
      style.borderColor = "darkgray";
    }
    return style;
  }

  render() {
    return (
      <>
        {/* //1.4 max? */}
        <div id="WifiBox" style={{ width: "50px", height: "50px", marginLeft: "2VW", marginTop: "90vh", zIndex: "9999", position: "absolute" }}>
          <div className={"wifi-symbol"}>
            <div className="wifi-circle first" style={this.wifiColor(4)}></div>
            <div className="wifi-circle second" style={this.wifiColor(3)}></div>
            <div className="wifi-circle third" style={this.wifiColor(2)}></div>
            <div className="wifi-circle fourth" style={this.wifiColor(1)}></div>
            {/* todo make exclamation point if no wifi is available */}
          </div>
        </div>
      </>
    );
  }
}

export default Page;
