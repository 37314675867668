export function getRemainingAccrue(accrual_rate){
    let remainingAccrue = "Unknown";
    if (accrual_rate !== undefined || accrual_rate !== null) {
      let remainingMonth = 11 - new Date().getMonth();
      remainingAccrue = (remainingMonth * Number(accrual_rate)).toFixed(2);
    }
    return remainingAccrue;
}

export function getAccrualRate(date_hired,intern_hire_date,accrual_rate){
    if (date_hired === undefined) {
        //off line data don't have date_hired
        return null;
      }
      let newDate = date_hired === null ? intern_hire_date : date_hired;
      if(newDate === undefined || newDate === null){
        return accrual_rate || null;
      }
      newDate = new Date(newDate).setMonth(11);
      newDate = new Date(new Date(newDate).setDate(31));
      let yos = getYOS(newDate,null);
      let rate;
      if (yos < 2) {
        rate = 10 / 12;
      } else if (yos < 5) {
        rate = 15 / 12;
      } else if (yos < 20) {
        rate = 20 / 12;
      } else if (yos >= 20) {
        rate = 25 / 12;
      } else {
        rate = 0;
      }
      return accrual_rate || rate.toFixed(3);
}

export function getYOS(date_hired,intern_hire_date){
      //NOTE This just calculates Year of Service!
      let actualHireDate = date_hired === null ? intern_hire_date : date_hired
      if(actualHireDate === null || actualHireDate === undefined){
        return 0
      }
      let newDate = new Date(actualHireDate);
      newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
      let yos = 0;
      while (new Date() > newDate) {
        yos += 1;
        newDate = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
      }
      return yos;
}