import React from "react";
import "./admin.styles.css";
import axios from "axios";
import address from "../address.component";
import { DataTable } from "primereact/datatable";
import ModalHeader from "../modalHeader/modalHeader.component.jsx";
import IndepthPTO from "./indepthpto.component.jsx";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { connect } from "react-redux";
import PictureComment from "./pictureComment.component.jsx";
import { Row, Col, Form, Input, Select, Switch } from "antd";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class AdminManagementPage extends React.Component {
  constructor(props) {
    super();
    this.formRef = React.createRef();
    this.state = {
      active: 1,
      secureUserDropdown: [
        { name: "Active Users", value: 1 },
        { name: "Inactive Users", value: 0 },
      ],
      selectSecureUser: null,
      secureUsers: [],
      transferMembers: [],
      unconnectedUsers: [],
      permissions: [{ name: "ALL" }, { name: "NONE" }],
      divisions: ["HAVEN", "MCKENZIE"],
      secureUserHierarchy: [],
      formDisabled: true,
    };
  }

  componentDidMount() {
    this.getSecureUsers();
    this.getSecureUserHierarchy();
  }

  componentDidUpdate(prevState) {
    if (prevState.selectSecureUser !== this.state.selectSecureUser && this.formRef.current !== null) {
      this.formRef.current.resetFields();
    }
  }

  getSecureUsers() {
    axios.post(address + "/getSecureUserList", { secureUser: this.props.user, status: this.state.active }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ secureUsers: data });
    });
  }

  getSecureUserInfo(data) {
    axios.post(address + "/getSecureUserInfo", { secureUser_id: data.id }).then((res) => {
      const data = res.data;
      console.log(data[0]);
      this.getUnconnectedUsers(data[0]);
      this.getTransferSecureUsers(data[0]);
      this.setState({ selectSecureUser: data[0], formDisabled: true });
    });
  }

  async getUnconnectedUsers(currentSecureUser) {
    await axios.post(address + "/getUnconnectedUser", { secureUser: currentSecureUser, division: this.props.user?.division }).then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ unconnectedUsers: data });
    });
  }

  async getTransferSecureUsers(currentSecureUser) {
    axios
      .post(address + "/getTeamMembers", {
        userId: currentSecureUser.id || 0,
        division: this.props.user?.division || "HAVEN",
        all: true,
      })
      .then((res) => {
        const data = res.data;
        this.setState({
          transferMembers: data,
        });
      });
  }

  getSecureUserHierarchy() {
    axios.get(address + "/getSecureUserHierarchy").then((res) => {
      const data = res.data;
      console.log(data);
      this.setState({ secureUserHierarchy: data });
    });
  }

  checkEmailFormat(value) {
    if (value === "" || value === null || value === undefined) {
      return Promise.reject(new Error("Please enter the email!"));
    } else if (value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid email!"));
    }
  }

  updateSecureUserInfo(userInfo) {
    axios.post(address + "/updateSecureUserInfo", { secureUser: userInfo }).then(async (res) => {
      const data = res.data;
      console.log(data[0]);
      await this.getUnconnectedUsers(data[0]);
       data[0].Status = data[0].Status === "ONLINE";
      this.setState({ selectSecureUser: data[0], formDisabled: true });
      console.log("hit");
    });
  }

  createSecureUserCall() {
    console.log(this.state);
    let errors = [];
    if (this.state.createSecureUserUsername.length === 0) {
      errors.push("Must enter User Name");
    }
    if (this.state.createSecureUserInitial.length === 0) {
      errors.push("Must enter Initial");
    }
    if (this.state.createSecureUserHierarchy === null) {
      errors.push("Must select a hierarchy");
    }
    if (this.state.createSecureUserPermissions === null) {
      errors.push("Must select a permission");
    }
    if (this.state.createSecureUserEmail !== "" && !this.state.createSecureUserEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.push("Must enter a valid email");
    }
    console.log(errors);
    for (let i = 0; i < errors.length; i++) {
      console.log(errors[i]);
      let msg = errors[i];
      this.toast.show({ severity: "error", summary: msg, life: 3000 });
    }
    if (errors.length > 0) {
      return;
    }
    let div = this.props.user.division;
    //TODO ADD CONDITION FOR ALL DIVISION CODE! (SHOULD USE STATE'S DIVISON CODE)
    if (div === undefined || div === null) {
      div = "HAVEN";
    }
    let secureUser = { division: div, username: this.state.createSecureUserUsername, initials: this.state.createSecureUserInitial, connected_user: this.state.createSecureUserConnectedUser?.ID, permissions: this.state.createSecureUserPermissions?.name, secureUserHierarchy: this.state.createSecureUserHierarchy?.id, active: true };
    if (this.state.createSecureUserEmail !== "") {
      secureUser.recovery_email = this.state.createSecureUserEmail;
    }
    axios.post(address + "/insertSecureUser", { secureUser: secureUser }).then((res) => {
      const data = res.data;
      console.log(data);
      this.getSecureUsers();
      this.getSecureUserHierarchy();
      if (data.err) {
        this.toast.show({ severity: "error", summary: "Created Error!", detail: data.err, life: 3000 });
      } else {
        this.toast.show({ severity: "success", summary: "Secure user Created", detail: "Secure user Created", life: 3000 });
      }
      this.setState({ createSecureUserModal: false });
    });
  }

  async callCreateNewSecureUser() {
    await this.getUnconnectedUsers(this.props.user);
    this.setState({ createSecureUserModal: true, createSecureUserUsername: "", createSecureUserInitial: "", createSecureUserConnectedUser: null, createSecureUserEmail: "", createSecureUserPermissions: null, createSecureUserHierarchy: null });
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />

        <Dialog resizable={false} draggable={false} visible={this.state.createSecureUserModal} baseZIndex={1000} dismissableMask onHide={() => this.setState({ createUserModal: false })} style={{ width: "50vw", minHeight: "30vh", padding: 0, overflow: "hidden", borderRadius: ".8em", zIndex: 6000 }} contentStyle={{ overflow: "hidden", display: "grid", padding: 0 }} showHeader={false}>
          <ModalHeader title="Create New Secure User" height="12vh" />
          <div style={{ display: "grid", padding: "1em", gridTemplateColumns: "20% 20% auto", fontSize: "1.5em", height: "25vh" }}>
            <div className="field" style={{ display: "grid" }}>
              <label>Enter User name</label>
              <InputText value={this.state.createSecureUserUsername} placeholder={"N/A"} onChange={(e) => this.setState({ createSecureUserUsername: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Enter Initial</label>
              <InputText value={this.state.createSecureUserInitial} placeholder={"N/A"} onChange={(e) => this.setState({ createSecureUserInitial: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Enter Recovery Email</label>
              <InputText value={this.state.createSecureUserEmail} placeholder={"N/A"} onChange={(e) => this.setState({ createSecureUserEmail: e.target.value })} className="p-d-block createUserInput" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Permission</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createSecureUserPermissions} placeholder={"N/A"} options={this.state.permissions} onChange={(e) => this.setState({ createSecureUserPermissions: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Hierarchy</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createSecureUserHierarchy} placeholder={"N/A"} options={this.state.secureUserHierarchy} onChange={(e) => this.setState({ createSecureUserHierarchy: e.value })} optionLabel="name" />
            </div>
            <div className="field" style={{ display: "grid" }}>
              <label>Connect User</label>
              <Dropdown className="primereactfix-large-font-size-dropdown createUserInput" value={this.state.createSecureUserConnectedUser} placeholder={"N/A"} options={this.state.unconnectedUsers} onChange={(e) => this.setState({ createSecureUserConnectedUser: e.value })} optionLabel="name" />
            </div>
          </div>
          <div>
            <Button label="Back" className="menuButton" style={{ height: "5vh", fontSize: "1.5em", width: "5vw" }} onClick={() => this.setState({ createSecureUserModal: false })} />
            <Button label="Create" className="menuButton" style={{ height: "5vh", fontSize: "1.5em", width: "5vw", float: "right" }} onClick={() => this.createSecureUserCall()} />
          </div>
        </Dialog>

        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          {this.state.selectSecureUser ? (
            <div style={{ float: "left", width: "80%" }}>
              <div style={{ fontSize: "3vh", height: "auto", padding: ".5em", margin: ".5em", backgroundColor: "#efefef", borderRadius: ".5em" }}>
                <h1 style={{ fontSize: "8vh" }}>{this.state.selectSecureUser ? this.state.selectSecureUser.username + (this.state.selectSecureUser.active ? "" : "(inactive)") : "Select a User"}</h1>
                <Form ref={this.formRef} name="user-form" layout="vertical" labelCol={{ span: 25 }} wrapperCol={{ span: 16 }} initialValues={this.state.selectSecureUser} onFinish={(data) => this.updateSecureUserInfo(data)} disabled={this.state.formDisabled}>
                  <Form.Item hidden label="Id" name="id" rules={[{ required: true, message: "Please input your username!" }]}>
                    <Input />
                  </Form.Item>
                  <Row justify="start">
                    <Col span={6}>
                      <Form.Item label="Username" name="username" rules={[{ required: true, message: "Please input the username!" }]}>
                        <Input disabled={this.state.formDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Initials" name="initials" rules={[{ required: true, message: "Please input the initial!" }]}>
                        <Input disabled={this.state.formDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item label="Connect User" name="connected_user">
                        <Select disabled={this.state.formDisabled} showSearch allowClear placeholder="Select a user" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                          {this.state.unconnectedUsers.map((item) => (
                            <Select.Option key={item.ID} label={item.name} value={item.ID}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/*NOte: don't think they can change division <Form.Item label="Division" name="division" rules={[{ required: true, message: "Please input your username!" }]}>
                    <Select showSearch allowClear placeholder="Select a division" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {this.state.divisions.map((item) => (
                        <Select.Option key={item}>{item}</Select.Option>
                      ))}
                    </Select>
                      </Form.Item>*/}
                  <Form.Item label="Recover Email" name="recovery_email" rules={[{ required: true, message: "" }, { validator: (_, value) => this.checkEmailFormat(value) }]}>
                    <Input disabled={this.state.formDisabled} />
                  </Form.Item>

                  <Row>
                    <Col span={6}>
                      <Form.Item label="Permissions" name="permissions" rules={[{ required: true, message: "Please select the permissions!" }]}>
                        <Select disabled={this.state.formDisabled} placeholder="Select a permission">
                          {this.state.permissions.map((item) => (
                            <Select.Option key={item.name}>{item.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item label="Hierarchy" name="secureUserHierarchy" rules={[{ required: true, message: "Please select the hierarchy!" }]}>
                        <Select disabled={this.state.formDisabled} placeholder="Select a level">
                          {this.state.secureUserHierarchy.map((item) => (
                            <Select.Option key={item.id} label={item.name} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6}>
                      <Form.Item label="Status" name="Status" valuePropName="checked">
                        <Switch style={{ marginLeft: "1vw" }} disabled={true} checkedChildren="Online" unCheckedChildren="Offline" />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item label="Transfer to Supervisor" name="TransferId">
                        <Select disabled={this.state.formDisabled || this.state.selectSecureUser.Status} showSearch allowClear placeholder="Select a user" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                          {this.state.transferMembers.map((item) => (
                            <Select.Option key={item.id} label={item.username} value={item.id}>
                              {item.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="Active" name="active" valuePropName="checked">
                    <Switch style={{ marginLeft: "0.5vw" }} disabled={this.state.formDisabled} />
                  </Form.Item>
                </Form>

                <div className="button-container">
                  {this.state.formDisabled ? (
                    <Button onClick={() => this.setState({ formDisabled: false })} className="secondary-button" size="large">
                      Edit
                    </Button>
                  ) : (
                    <React.Fragment>
                      <Button style={{ backgroundColor: "red" }} onClick={() => this.setState({ formDisabled: true })} className="secondary-light-button" size="large">
                        Cancel
                      </Button>
                      <Button style={{ marginLeft: "2vw", backgroundColor: "green" }} onClick={() => this.formRef.current.submit()} className="secondary-button" size="large">
                        Save
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ float: "left", width: "80%" }}>
              <div style={{ fontSize: "3vh", height: "auto", padding: ".5em", margin: ".5em", backgroundColor: "#efefef", borderRadius: ".5em" }}>
                <h1 style={{ fontSize: "8vh" }}>{"Select a User"}</h1>
              </div>
            </div>
          )}
          <DataTable ref={(e) => (this.dt = e)} sortField="username" sortOrder={1} filters={{ nameAndPin: { value: null, matchMode: FilterMatchMode.CONTAINS } }} filterDisplay="row" scrollable scrollHeight="flex" style={{ width: "20%", float: "left" }} selection={this.state.selectSecureUser} value={this.state.secureUsers} editMode="cell" selectionMode="single" onSelectionChange={(e) => this.getSecureUserInfo(e.value)}>
            <Column
              filter
              filterMatchMode="custom"
              filterField="nameAndPin"
              footer={
                this.props.user.permissions === "ALL" ? (
                  <div id="ColumnFooter" style={{ backgroundColor: "#e6e6e6", fontSize: "2vw",textAlign:"center" }} onClick={() => this.callCreateNewSecureUser()}>
                    Create User
                  </div>
                ) : null
              }
              showFilterMenu={false}
              filterHeaderStyle={{ textAlign: "center", padding: ".5em" }}
              className="primereactfix-center-column-header primereactfix-squished-table"
              header={<Dropdown className="primereactfix-large-font-size-dropdown" style={{ width: "100%", padding: ".12em", fontSize: "1em" }} value={this.state.active} placeholder={"N/A"} options={this.state.secureUserDropdown} onChange={(e) => this.setState({ active: e.value }, () => this.getSecureUsers())} optionLabel="name" />}
              headerStyle={{ fontSize: "2vw", textAlign: "center", padding: ".12em", backgroundColor: "#e6e6e6" }}
              style={{ fontSize: "1.5vw", textAlign: "left", display: "block", backgroundColor: "#f2f2f2" }}
              body={(rowData) => {
                return (
                  <span style={{ width: "100%",marginLeft:"2vw", }}>
                    <i className="pi pi-circle-fill" style={{ color: rowData.status === "ONLINE" ? "green" : "red", fontSize: "1.5rem", marginRight: "0.5vw" }}></i>
                    {rowData.username}
                  </span>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AdminManagementPage);
