import React from "react";
import "../boxes/terminal.styles.css";
import axios from "axios";
import address from "../address.component";

import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";

class Page extends React.Component {
  // top class,

  constructor(props) {
    super();
    this.state = {
      holidays: [],
      currentYearHolidays: [],
      currentYear: new Date().getFullYear(),
      date: new Date(),
      isDelete: false,
      addHolidayModal: false,
      name: null,
    };
  }

  async componentDidMount() {
    this.getHoliday();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentYear !== this.state.currentYear) {
      let currentHoliday = this.state.holidays.filter((data) => Number(data.year) === this.state.currentYear);
      currentHoliday.sort((a, b) => new Date(a.date) - new Date(b.date));
      let selectDate = new Date((this.state.date || new Date()).setFullYear(this.state.currentYear));
      await this.calendar.onClearButtonClick();
      this.setState({ currentYearHolidays: currentHoliday, date: selectDate });
    }
    if (prevState.date !== this.state.date) {
      let inHoliday = this.state.holidays.find((data) => this.state.date?.toISOString().slice(0, 10) === data.date);
      this.setState({ isDelete: inHoliday !== undefined, name: inHoliday?.name || "" });
    }
  }

  async getHoliday() {
    await axios.post(address + "/getHoliday").then((res) => {
      const data = res.data;
      console.log(data);
      let currentHoliday = data.filter((data) => Number(data.year) === this.state.currentYear);
      currentHoliday.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.setState({ holidays: data, currentYearHolidays: currentHoliday });
    });
  }
  absencesCalendar = (date) => {
    for (let i = 0; i < this.state.holidays.length; i++) {
      let holiday = this.state.holidays[i];
      if (Number(holiday.month) === date.month) {
        if (Number(holiday.day) === date.day) {
          if (Number(holiday.year) === date.year) {
            return <div className={"dateDayMimic approved"}>{date.day}</div>;
          }
        }
      }
    }
    return <div className="dateDayMimic">{date.day}</div>;
  };

  async selectDate(date) {
    this.setState({ date: date });
  }

  async deleteHoliday() {
    if (this.state.date === undefined || this.state.date === null) {
      this.toast.show({ severity: "error", summary: "Please select date first", life: 3000 });
      return;
    }

    axios
      .post(address + "/deleteHoliday", {
        date: this.state.date.toISOString().slice(0, 10),
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (data === "Delete Holiday Fail") {
          this.toast.show({ severity: "error", summary: "Delete Holiday Fail", life: 3000 });
          return;
        } else {
          let currentHoliday = data.filter((data) => Number(data.year) === this.state.currentYear);
          currentHoliday.sort((a, b) => new Date(a.date) - new Date(b.date));
          this.setState({ holidays: data, currentYearHolidays: currentHoliday, date: null });
          this.toast.show({ severity: "success", summary: "Delete Holiday Successful", life: 3000 });
          return;
        }
      });
  }

  async addHoliday() {
    if (this.state.date === undefined || this.state.date === null) {
      this.toast.show({ severity: "error", summary: "Please select date first", life: 3000 });
      return;
    }

    if (this.state.name === "" || this.state.name === null || this.state.name === undefined) {
      this.toast.show({ severity: "error", summary: "Cant add holiday without name", life: 3000 });
      return;
    } else {
      axios
        .post(address + "/insertHoliday", {
          name: this.state.name,
          date: this.state.date.toISOString().slice(0, 10),
          year: this.state.date.getFullYear(),
          month: this.state.date.getMonth(),
          day: this.state.date.getDate(),
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data === "Insert Holiday Fail") {
            this.toast.show({ severity: "error", summary: "Insert Holiday Fail", life: 3000 });
            return;
          } else {
            let currentHoliday = data.filter((data) => Number(data.year) === this.state.currentYear);
            currentHoliday.sort((a, b) => new Date(a.date) - new Date(b.date));
            this.setState({ holidays: data, currentYearHolidays: currentHoliday });
            this.toast.show({ severity: "success", summary: "Insert Holiday Successful", life: 3000 });
            return;
          }
        });
    }
  }

  render() {
    let renderHeader = <div></div>;
    renderHeader = (
      <ColumnGroup>
        <Row>
          <Column alignHeader="center" header={this.state.currentYear} colSpan={3} headerStyle={{ textAlign: "center", width: "5vw", padding: "2em" }} />
        </Row>
        <Row>
          <Column alignHeader="center" header="Holiday Name" colSpan={1} headerStyle={{ textAlign: "center", padding: "2em" }} />
          <Column alignHeader="center" header="Date" colSpan={1} headerStyle={{ textAlign: "center", padding: 0 }} />
          <Column alignHeader="center" header="Delete" colSpan={1} headerStyle={{ textAlign: "center", padding: 0 }} />
        </Row>
      </ColumnGroup>
    );
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />

        <div style={{ display: "grid", gridTemplateColumns: "85%  auto", marginLeft: "2.5vw", marginRight: "2.5vw" }}>
          <Button label="Previous Year" className="hideMe adminPageButtons red" style={{ marginLeft: "2px", width: "15%", float: "left" }} onClick={() => this.setState({ currentYear: this.state.currentYear - 1 })} />
          <Button label="Next Year" className="hideMe adminPageButtons red" style={{ marginRight: "2px", float: "right" }} onClick={() => this.setState({ currentYear: this.state.currentYear + 1 })} />
        </div>
        <div style={{ width: "50%", height: "58vh", float: "left", textAlign: "center", paddingTop: "1vh" }}>
          <Calendar viewDate={this.state.date} value={this.state.date} id="PTOCalendar" dateTemplate={this.absencesCalendar} inline panelStyle={{ height: "80%", width: "auto", fontSize: "1.8vw", borderStyle: "solid", borderWidth: "5px", borderColor: "red", borderRadius: "8px", boxShadow: "5px 15px 20px #808080" }} placeholder={"Select a range of dates"} ref={(e) => (this.calendar = e)} onChange={(e) => this.selectDate(e.value)} />
          <h1 style={{ fontSize: "2.0vw", marginTop: "1vh" }}>{this.state.date !== null ? this.state.date.toDateString() : "Select Date"}</h1>
          {this.state.date === null || this.state.date === undefined ? null : (
            <React.Fragment>
              <InputText disabled={this.state.isDelete} style={{ fontSize: "2.0vw", padding: "0vw 1vw", marginTop: "2vh", width: "90%" }} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
              <Button label={this.state.isDelete ? "Delete" : "Add"} style={{ fontSize: "1.5vw", width: "20%", padding: "0vw 1vw", marginTop: "2vh", backgroundColor: this.state.isDelete ? "red" : "green" }} onClick={() => (this.state.isDelete ? this.deleteHoliday() : this.addHoliday())} />
            </React.Fragment>
          )}
        </div>
        <div style={{ width: "50%", height: " 47vh", float: "right" }}>
          <DataTable value={this.state.currentYearHolidays} headerColumnGroup={renderHeader} scrollable scrollHeight="85vh">
            <Column field="name" style={{ textAlign: "center" }} />
            <Column field="date" style={{ textAlign: "center" }} />
            <Column
              align="center"
              style={{ flexBasis: "6em", flexShrink: 0, flexGrow: 0, textAlign: "center" }}
              body={(rowData, b) => {
                return <i className="pi pi-trash" style={{ fontSize: "1.1vw" }} onClick={() => this.setState({ date: new Date(rowData.date) }, () => this.deleteHoliday())} />;
              }}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}

export default Page;
