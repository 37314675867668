import React from "react";
import axios from "axios";
import address from "../address.component";
// import logo from "../../assets/haven_steel_logo.png";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import ReportSetting from "../reportSetting/reportSetting.component";
import LoadingScreen from "../loading/loading.component.jsx";
import { Toast } from "primereact/toast";

import ModalHeader from "../modalHeader/modalHeader.component.jsx";

import calcTotalHours from "../global_functions/calcTotalHours.function";
import getHighestAttendance from "../global_functions/getHighestAttendance.function";

function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}

class Page extends React.Component {
  // top class,

  constructor(props) {
    super(props);
    this.state = {
      pages: 0,
      currentPage: 0,
      popSeen: false,
      buttonHide: true,
      active: true,
      workerType: "ALL",
      attendanceCode: [],
      reports: [],
      // shifts: [],
      defaultDate: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      date: [new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0)), new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0))],
      breaks: [],
      newBreaks:[],
      dates: [],
      shift: this.props.user.shift || "ALL",
      department: this.props.user.department || "ALL",
      user: null,
    };
  }

  getAttendanceCodes() {
    axios.get(address + "/getAttendanceCodes").then((res) => {
      const data = res.data;
      this.setState({ attendanceCodes: data });
    });
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  setDate(data) {
    this.setState({ date: data }, () => {
      if (data[1] !== null || undefined) {
        this.calendar.updateInputfield(this.state.date);
      }
    });
  }

  runReport = async (data) => {
    await this.setState(data);
    this.closeDialogAndRunReport();
  };

  callCreateTimesheet(date) {
    this.setState({ readyToShow: false });
    if (this.state.department === null) {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Department", life: 3000 });
      return;
    }
    if (this.state.shift === null) {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Shift", life: 3000 });
      return;
    }
    if (date[1] !== null && date[1] !== undefined) {
      this.setState({ shifts: [], loading: true });
      let startDate = date[0].toISOString();
      let endDate = new Date(new Date(date[1]).setDate(date[1].getDate() + 1)).toISOString();
      console.log("CALL API", startDate, endDate);
      let userId = this.state.user === null || this.state.user === undefined ? null : this.state.user.pin;
      axios
        .post(address + "/createTimesheet", {
          start_time: startDate,
          end_time: endDate,
          department: this.state.department,
          shift: this.state.shift,
          user_id: userId,
          division: this.props.user.division,
          active: this.state.active,
          workerType: this.state.workerType,
          attendanceCode: this.state.attendanceCode,
        })
        .then(async (res) => {
          const data = res.data;

          console.log(data);
          this.setState({ breaks: data.breaks,
            newBreaks:data.newBreakInfo }, async () => {
            await this.getDates();
            let shiftData = data.dataByUser;
            shiftData.sort((prev, next) => prev.user?.shift - next.user?.shift);
            await this.getReport(shiftData);
          });
          this.setState({ loading: false });
        });
    } else {
      this.toast.show({ severity: "error", summary: "Missing Data", detail: "Must select Start Date & End Date", life: 3000 });
      return "error invalid date";
    }
  }

  async getDates() {
    let dates = [];
    let startDate = new Date(this.state.date[0]);
    let endDate = new Date(this.state.date[1]);
    let current = startDate;
    while (current <= endDate) {
      dates.push(current.toISOString().slice(0, 10));
      current.setDate(current.getDate() + 1);
    }
    console.log(dates);
    this.setState({ dates: dates });
  }

  fixDate(rowData) {
    const {start_time,adj_start_time,end_time,adj_end_time} = rowData
    let start = adj_start_time || start_time
    let end = adj_end_time || end_time
    if (end === null) {
      end = new Date(start).getTime() + 8*60*60*1000
    }
    return new Date(new Date(new Date(start).getTime() + (new Date(end) - new Date(start)) / 2).toLocaleString().split(",")[0]).toISOString().slice(0, 10)    
  }

  async getReport(rows) {
    console.log(rows);
    let report = [];
    for (let i = 0; i < rows.length; i++) {
      let found = false;
      let row = rows[i];
      let reportRow = row.user;
      let shifts = row.originalShifts;
      if (shifts.length > 0) {
        reportRow = shifts[0].user;
      }
      for (let k = 0; k < this.state.dates.length; k++) {
        let checkDate = this.state.dates[k];
        reportRow[checkDate] = "";
        for (let j = 0; j < shifts.length; j++) {
          let s = shifts[j];
          let shiftDate = this.fixDate(s);
          let attendanceCode = "";
          if (shiftDate === checkDate) {
            if (s.late === 1 || s.late === true) {
              attendanceCode = "Late" + calcTotalHours({ start: s.adj_start_time, end: s.adj_end_time, userInfo: s.user, breaks: this.state.breaks, shift: s.user.shift, attendance: s.attendance === null ? "DIRECT" : getHighestAttendance(s.attendance, this.state.attendanceCodes), approved: s.approved , shiftHour:s.hours, newBreaks:this.state.newBreaks,breakShift:s.break_shift});
            } else {
              attendanceCode = s.attendance === null ? "DIRECT " + calcTotalHours({ start: s.adj_start_time, end: s.adj_end_time, userInfo: s.user, breaks: this.state.breaks, shift: s.user.shift, attendance: s.attendance === null ? "DIRECT" : getHighestAttendance(s.attendance, this.state.attendanceCodes), approved: s.approved, shiftHour:s.hours, newBreaks:this.state.newBreaks,breakShift:s.break_shift }) : s.attendance + " " + calcTotalHours({ start: s.adj_start_time, end: s.adj_end_time, userInfo: s.user, breaks: this.state.breaks, shift: s.user.shift, attendance: s.attendance === null ? "DIRECT" : getHighestAttendance(s.attendance, this.state.attendanceCodes), approved: s.approved, shiftHour:s.hours, newBreaks:this.state.newBreaks,breakShift:s.break_shift});
            }
            if (reportRow[checkDate] === "") {
              reportRow[checkDate] += attendanceCode;
            } else {
              reportRow[checkDate] += "/" + attendanceCode;
            }
            found = true;
          }
        }
      }
      if (found) {
        report.push(reportRow);
      }
    }
    console.log(report);
    this.setState({ reports: report });
  }
  getShifts(dataByUser) {
    let allShifts = [];
    console.log(this.state.pages);
    for (let i = 0; i < this.state.pages; i++) {
      let newShiftByWeek = this.getShiftsByWeek(i, dataByUser);
      allShifts.push(newShiftByWeek);
    }
    console.log("this is important", allShifts, this.state);
    this.setState({ reports: allShifts }, () => this.setState({ readyToShow: true }));
  }

  closeDialogAndRunReport() {
    this.togglePop();
    this.callCreateTimesheet(this.state.date);
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    var curr = new Date();
    let firstDay = new Date(new Date(curr.setDate(curr.getDate() - curr.getDay())).setHours(0));
    let lastDay = new Date(new Date(curr.setDate(curr.getDate() - curr.getDay() + 6)).setHours(0));
    let day = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0));
    this.getAttendanceCodes();

    if (this.state.department !== null) {
      this.setState({ date: [firstDay, lastDay] });
      this.callCreateTimesheet([firstDay, lastDay]);
    }
  }

  perviousPage() {
    let current = this.state.currentPage;
    if (current > 0) {
      this.setState({ currentPage: current - 1 });
    }
    console.log(this.state.currentPage);
  }

  nextPage() {
    let current = this.state.currentPage;
    let total = this.state.pages;
    console.log(total);
    if (current < total - 1) {
      this.setState({ currentPage: current + 1 });
    }
    console.log(this.state.currentPage);
  }

  download(csv, filename) {
    const a = document.createElement("a");
    a.href = encodeURI(csv);
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  GenerateCSV() {
    let csv = "data:text/csv;charset=utf-8,";
    let headers = this.state.header;
    let data = this.state.reports;
    csv += "Department,Shift,Emp Number,Emp Name,";
    this.state.dates.forEach((element) => {
      csv += element + ",";
    });
    csv += "\n";

    this.state.reports.forEach((row) => {
      csv += row.department ? row.department + "," : ",";
      csv += row.shift ? row.shift + "," : ",";
      csv += row.pin ? row.pin + "," : ",";
      csv += row.name ? row.name + "," : ",";
      this.state.dates.forEach((element) => {
        csv += row[element] ? row[element] + "," : ",";
      });
      csv += "\n";
    });
    csv += "\n";
    this.download(csv, "Attendance Sheet");
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <ReportSetting popSeen={this.state.popSeen} attendanceCode={this.state.attendanceCode} workerType={this.state.workerType} active user department={this.props.user.department || "ALL"} date={this.state.date} defaultDate={this.state.defaultDate} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <LoadingScreen show={this.state.loading} />
        <div style={{ display: "grid", gridTemplateColumns: "20vw auto", marginLeft: "2.5vw", marginRight: "2.5vw", columnGap: "2.5vw" }}>
          <Button label="Generate Report" className="hideMe adminPageButtons red" style={{ width: "100%" }} onClick={this.togglePop} />
          <Button label="Export Data" className="adminPageButtons red" onClick={() => this.GenerateCSV()} />
        </div>
        <DataTable scrollable scrollHeight="80vh" value={this.state.reports}>
          <Column field="department" header="Department" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column field="shift" header="Shift" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column field="pin" header="Emp #" style={{ textAlign: "center", width: "150px" }} frozen />
          <Column field="name" header="Emp Name" style={{ textAlign: "center", width: "150px" }} frozen />
          {this.state.dates.map((date) => {
            return <Column field={date} header={date} style={{ textAlign: "center", width: "150px" }} />;
          })}
        </DataTable>
        {true ? null : (
          <div style={{ textAlign: "right" }}>
            <button onClick={() => this.perviousPage()} class="btn" style={{ color: this.state.currentPage === 0 ? "gray" : "green" }}>
              <i class="fa-solid fa-circle-chevron-left"></i> Previous
            </button>
            <button onClick={() => this.nextPage()} class="btn" style={{ color: this.state.currentPage < this.state.pages - 1 ? "green" : "gray" }}>
              Next <i class="fa-solid fa-circle-chevron-right"></i>
            </button>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
