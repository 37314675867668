import React from "react";
import axios from "axios";
import address from "../address.component";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import ReportSetting from "../reportSetting/reportSetting.component";
import LoadingScreen from "../loading/loading.component.jsx";
import "./timesheet.styles.css";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
function mapStateToProps(state) {
  return {
    user: state.TTUser,
  };
}
class Page extends React.Component {
  // top class,

  constructor(props) {
    super(props);
    this.state = {
      defaultDate: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
      date: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
      user: {},
      popSeen: false,
    };
  }

  componentDidMount() {}

  callAttendanceReport() {
    console.log("Click!!!");
    if (this.state.user === null || this.state.user === undefined) {
      this.toast.show({ severity: "error", summary: "Please select a user", detail: "Please select a user", life: 3000 });
      return;
    }
    if (this.state.date[1] === null || this.state.date[1] === undefined) {
      this.toast.show({ severity: "error", summary: "Please select The right date", detail: "Please select The right date", life: 3000 });
      return;
    }
    this.setState({ loading: true });

    axios.post(address + "/AttendanceReport", { start_time: this.state.date[0].toISOString(), end_time: new Date(new Date(this.state.date[1]).setDate(this.state.date[1].getDate() + 1)).toISOString(), userId: this.state.user.ID }).then((res) => {
      const data = res.data;
      this.setState({ loading: false });
      this.setState({ attendanceReportData: data.data });
      //todo remove L (Late)
      this.setState({ attendanceReportLegend: data.legend.concat([{ id: 0, code: "Total Offenses", description: "", count: data.total }]) });
      this.setState({ totalPoints: data.total });
      console.log(data);
    });
  }

  togglePop = () => {
    this.setState({
      popSeen: !this.state.popSeen,
    });
  };

  runReport = async (data) => {
    await this.setState(data);
    this.togglePop();
    this.callAttendanceReport();
  };

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <LoadingScreen show={this.state.loading} />
        <ReportSetting popSeen={this.state.popSeen} active user department={this.props.user.department || "ALL"} date={this.state.date} defaultDate={this.state.defaultDate} shift={this.props.user.shift || "ALL"} division={this.props.user.division} closeSetting={() => this.setState({ popSeen: false })} runReport={this.runReport} />
        <div className="hideMe" style={{ display: "grid", gridTemplateColumns: "20vw auto", width: "40vw", columnGap: "2vw", marginLeft: "2.5vw", marginRight: "2.5vw" }}>
          <Button label="Generate Report" className="adminPageButtons red" style={{ width: "15vw", height: "5vh", fontSize: "1.5em" }} onClick={this.togglePop} />
          <Button label="Print" className="adminPageButtons red" style={{ width: "10vw", height: "5vh", fontSize: "1.5em" }} onClick={() => window.print()} />
        </div>
        <div className="showMeGrid" style={{ gridTemplateColumns: "40vw 25vw 25vw", width: "80vw", marginLeft: "5vw" }}>
          <h3>
            {this.state.date[0] ? this.state.date[0].toISOString().slice(0, 10) : ""} to {this.state.date[1] ? this.state.date[1].toISOString().slice(0, 10) : ""}
          </h3>
          <h3 style={{ textAlign: "center" }}>{!this.state.user ? "Test User" : this.state.user.first_name + " " + this.state.user.last_name}</h3>
          <h3 style={{ textAlign: "right" }}>Total Offenses : {<span>{this.state.totalPoints}</span>}</h3>
        </div>
        <div style={{ display: "flex" }}>
          <div className="GrayPadding" style={{ width: "55%", padding: ".5em" }}>
            <DataTable id="PrimeReactDataTableOddRow" value={this.state.attendanceReportData}>
              <Column
                body={(rowData) => {
                  return rowData.attendance + " " + rowData.description;
                }}
                header="Attendance Code"
              ></Column>
              <Column
                field="start_time"
                body={(rowData) => {
                  return new Date(rowData.start_time).toDateString();
                }}
                header="Date"
              ></Column>
              <Column field="amount" header="Value"></Column>
            </DataTable>
          </div>
          <div className="GrayPadding" style={{ width: "45%", padding: ".5em", display: "flex", flexDirection: "column" }}>
            <DataTable id="PrimeReactDataTableOddRow" size="small" value={this.state.attendanceReportLegend}>
              <Column
                body={(rowData) => {
                  return rowData.code + " " + rowData.description;
                }}
                header="Attendance Code"
              ></Column>
              <Column field="count" header="Total" headerStyle={{ width: "5em" }}></Column>
              <Column
                field="caPerfect_Attendance"
                header="Counts Against Attendance"
                headerStyle={{ width: "10em" }}
                body={(rowData) => {
                  return rowData.caPerfect_Attendance === "1" ? "X" : "";
                }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(Page);
